// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Tooltip, Select, Form, Spin } from 'antd';

import './general-home.scss';

import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
} from 'recharts';
import { useQuery } from '@tanstack/react-query';
import { Opportunity, get } from '../../../api';
import { formatMoney } from '../../../utils/general';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import moment from 'moment';

const GeneralHome = () => {
  //   const { user } = useUser();
  const [totalQuotes, setTotalQuotes] = useState(0);
  const [lostQuotes, setLostQuotes] = useState(0);
  const [wonQuotes, setWonQuotes] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [width, setWidth] = useState('100%');
  const [maxY, setMaxY] = useState(0);
  const colors = scaleOrdinal(schemeCategory10).range();

  const { isLoading: isLoadingQuote, data: quotes } = useQuery<Opportunity[]>(
    ['/quotes'],
    () => get<Opportunity[]>('/quotes').then((e) => e.data)
  );

  useEffect(() => {
    if (quotes) {
      const calculateTotal = () => {
        const totalAmount = quotes.reduce((sum, item) => {
          if (item.currency === 'MXN') {
            return sum + item.totalGrossPrice;
          } else if (item.currency === 'USD') {
            return sum + item.totalGrossPrice / item.exchangeRate;
          }
          return sum;
        }, 0);
        setTotalQuotes(totalAmount);

        const closedTotal = quotes.reduce((sum, item) => {
          if (item.stage === 'Cerrada perdida') {
            if (item.currency === 'MXN') {
              return sum + item.totalGrossPrice;
            } else if (item.currency === 'USD') {
              return sum + item.totalGrossPrice / item.exchangeRate;
            }
          }
          return sum;
        }, 0);
        setLostQuotes(closedTotal);

        const wonTotal = quotes.reduce((sum, item) => {
          if (item.stage === 'Cerrada ganada') {
            if (item.currency === 'MXN') {
              return sum + item.totalGrossPrice;
            } else if (item.currency === 'USD') {
              return sum + item.totalGrossPrice / item.exchangeRate;
            }
          }
          return sum;
        }, 0);
        setWonQuotes(wonTotal);

        const maxTotal = quotes.reduce((sum, item) => {
          if (item.stage === 'Cerrada ganada') {
            if (item.currency === 'MXN') {
              return sum + item.totalGrossPrice;
            } else if (item.currency === 'USD') {
              return sum + item.totalGrossPrice / item.exchangeRate;
            }
          }
          return sum;
        }, 0);
        setMaxY(maxTotal);

        const filteredQuotes = quotes.filter(
          (element: any) =>
            element.stage === 'Cerrada ganada' ||
            element.stage === 'Cerrada perdida'
        );

        filteredQuotes.forEach((element: any, index: any) => {
          element['key'] = index;

          if (element.stage === 'Cerrada perdida') {
            element.totalGrossPrice = Math.abs(element.totalGrossPrice) * -1;
          }

          element['Total'] =
            element.totalGrossPrice >= 0
              ? element.totalGrossPrice.toFixed(2)
              : (element.totalGrossPrice * -1).toFixed(2);

          element['DispTotal'] =
            element.totalGrossPrice >= 0
              ? '$' +
                new Intl.NumberFormat('en', {
                  minimumFractionDigits: 2,
                }).format(element.totalGrossPrice)
              : '$-' +
                new Intl.NumberFormat('en', {
                  minimumFractionDigits: 2,
                }).format(Math.abs(element.totalGrossPrice));

          element['DispDate'] =
            element.endDate !== null
              ? moment(element.endDate).format('DD/MM/YYYY')
              : '';
        });

        setDataTable(filteredQuotes);
      };

      calculateTotal();

      if (quotes.length < 7) {
        setWidth('50%');
      } else if (quotes.length >= 7 && quotes.length < 12) {
        setWidth('75%');
      } else if (quotes.length >= 12) {
        setWidth('100%');
      }
    }
  }, [quotes]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="label">{`Total: ${payload[0].payload.DispTotal}`}</p>
        </div>
      );
    }

    return null;
  };
  const COLORS = {
    positive: '#00C49F',
    negative: '#FF0000',
  };
  return (
    <Layout className="admin">
      <p className="title-table">Valor de oportunidades</p>
      {/* <Row justify={'end'}>
        <Col xs={12} md={6}>
          <Form.Item label="Moneda:" name="currency">
            <Select
              defaultValue={'MXN'}
              onChange={(e) => setCurrencySelected(e)}
              placeholder="Selecciona una opción"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              options={[
                { value: 'MXN', label: 'MXN' },
                { value: 'USD', label: 'USD' },
              ]}
            />
          </Form.Item>
        </Col>
      </Row> */}
      <Row>
        <Col xs={24} md={8}>
          <div className="admin__container" style={{ width: '90%' }}>
            <p className="title-cards-home">Ganadas</p>
            <p className="subtitle-cards-home">${formatMoney(wonQuotes)} MXN</p>
          </div>
        </Col>
        <Col xs={24} md={8}>
          <div className="admin__container" style={{ width: '90%' }}>
            <p className="title-cards-home">Total</p>
            <p className="subtitle-cards-home">
              ${formatMoney(totalQuotes)} MXN
            </p>
          </div>
        </Col>
        <Col xs={24} md={8}>
          <div className="admin__container" style={{ width: '90%' }}>
            <p className="title-cards-home">Perdidas</p>
            <p className="subtitle-cards-home">
              ${formatMoney(lostQuotes)} MXN
            </p>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <p className="title-table">Tabla de oportunidades ganadas/perdidas</p>
      </Row> */}
      <Col span={24}>
        <Spin tip={<p>Cargando...</p>} spinning={isLoadingQuote}>
          <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
            <ResponsiveContainer width={width} height={350}>
              <BarChart
                data={dataTable}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="DispDate" />
                <YAxis
                  padding={{ top: 30 }}
                  width={100}
                  domain={[0, maxY]}
                  tickFormatter={(value) =>
                    '$' +
                    new Intl.NumberFormat('en', {
                      minimumFractionDigits: 2,
                    }).format(value)
                  }
                />
                <Tooltip content={<CustomTooltip />} />

                <Bar
                  dataKey="Total"
                  fill="#000000"
                  label={{
                    // position: (v) => (v > 0 ? 'top' : 'bottom'),
                    position: 'top',
                    // formatter: (v) => '$' + parseFloat(v).toFixed(2),
                    dataKey: 'DispTotal',
                  }}
                >
                  {dataTable.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      // fill={colors[index % 20] as string}
                      fill={
                        entry.totalGrossPrice >= 0
                          ? COLORS.positive
                          : COLORS.negative
                      }
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Spin>
      </Col>
      {/* <div className="admin__container">
        <Row>
          <PieChart width={400} height={400}>
            <Pie
              data={data01}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={60}
              fill="#0D2F41"
            />
            <Pie
              data={data02}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={90}
              fill="#7b96ac"
              label
            />
          </PieChart>
          <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" scale="band" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Area
              type="monotone"
              dataKey="amt"
              fill="#7b96ac"
              stroke="#7b96ac"
            />
            <Bar dataKey="pv" barSize={20} fill="#0D2F41" />
            <Line type="monotone" dataKey="uv" stroke="#ff7300" />
            <Scatter dataKey="cnt" fill="red" />
          </ComposedChart>
        </Row>
      </div>
      <div className="admin__container">
        <Row>
          {/* <RadialBarChart
            cx="50%"
            cy="50%"
            innerRadius="10%"
            outerRadius="80%"
            barSize={10}
            data={data}
          >
            <RadialBar
              minAngle={15}
              label={{ position: 'insideStart', fill: '#fff' }}
              background
              clockWise
              dataKey="uv"
            />
            <Legend
              iconSize={10}
              layout="vertical"
              verticalAlign="middle"
              wrapperStyle={style}
            />
          </RadialBarChart> */}
      {/* <LineChart width={500} height={400} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="uv" stroke="#7b96ac" />
        <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
      </LineChart> */}
      {/* <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <defs>
          <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset={off} stopColor="#7b96ac" stopOpacity={1} />
            <stop offset={off} stopColor="red" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="uv"
          stroke="#000"
          fill="url(#splitColor)"
        />
      </AreaChart> */}
      {/* </Row>
      </div> */}
    </Layout>
  );
};

export default GeneralHome;
