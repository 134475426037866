import { Button, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { User } from '../../../../api';
import { ExportExcelProps } from '../../../../helpers/excel';
import UserForm from '../detail';
import { ExportPdfProps } from '../../../../helpers/pdf';
import moment from 'moment';
import TablePaginationComponent from '../../../../components/general/table/table-pagination/tablepagination';
import TableComponent from '../../../../components/general/table';

const label = 'posts';
const route = '/posts';
const titlePdf = 'Reporte de entradas al blog';
const scrollTable = 500;

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<User>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const BlogPostsList = () => {
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<User> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      // {
      //   title: 'Título',
      //   width: 100,
      //   ellipsis: true,
      //   dataIndex: 'subject',
      //   key: 'subject',
      //   ...getColumnSearchProps('subject'),
      //   render: (subject) => (
      //     <>
      //       {subject && (
      //         <Text>{subject.charAt(0).toUpperCase() + subject.slice(1)}</Text>
      //       )}
      //     </>
      //   ),
      // },
      {
        title: 'Creado por',
        width: 100,
        ellipsis: true,
        dataIndex: 'createdByUserName',
        key: 'Id',
        ...getColumnSearchProps('createdByUserName'),
        render: (createdByUserName) => (
          <>
            {createdByUserName && (
              <Text>
                {createdByUserName.charAt(0).toUpperCase() +
                  createdByUserName.slice(1)}
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Fecha de creación',
        key: 'createdDate',
        width: 150,
        dataIndex: 'createdDate',
        render: (createdDate) => (
          <>{moment(createdDate).format('DD/MM/YYYY')}</>
        ),
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 100,
        align: 'center',
        render: (user) => (
          <>
            {/* <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="circle"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', user.id)}
              />
            </Tooltip> */}
            <Tooltip title="Editar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#537896',
                  borderColor: '#537896',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<EditOutlined />}
                onClick={() => showModal('edit', user.id)}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#e63241',
                  borderColor: '#cf1322',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(user.id)}
              />
            </Tooltip>
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_post_creados',
    sheetName: 'Posts creados',
    tableName: 'Tabla de posts creados',
    columnNames: [
      { label: 'Creado por', key: 'createdByUserName' },
      {
        label: 'Fecha de creación',
        key: 'createdDate',
        format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
      },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'Creado por', key: 'createdByUserName' },
    {
      header: 'Fecha de creación',
      key: 'createdDate',
      format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
    },
  ];
  const postTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: UserForm,
    pdfColumns,
    titlePdf,
    scrollTable,
  };
  const PostsTable = TableComponent<User>(postTableProps);

  return PostsTable;
};

export default BlogPostsList;
