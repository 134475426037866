// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Tabs,
  Typography,
  Upload,
} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UploadOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { get } from '../../../../api';
import postFormData from '../../../../api/requests/postFormData';
import putFormData from '../../../../api/requests/putFormData';
import { queryClient } from '../../../../config';

type PostFormProps = {
  type: string;
  id?: number | null;
  idCustomer?: number | null;
  add: (v: CreateContact) => void;
  update: (v: Contact) => void;
};

const PostForm: React.FC<PostFormProps> = (props) => {
  const { type, id, setModalIsVisible, add } = props;
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [postFiles, setPostFiles] = useState([]);

  //ENDPOINTS
  const { isLoading: isLoadingPosts, data } = useQuery<Contact>(
    ['/posts/' + id],
    () => (id ? get<Contact>('/posts/' + id).then((e) => e.data) : '')
  );

  useEffect(() => {
    if (type !== 'add' && data) {
      form.setFieldsValue({
        subjectEs: data.postBodies[0].title,
        principalBodyEs: data.postBodies[0].principalBody,
        secondaryBodyEs: data.postBodies[0].secondaryBody,

        subjectEn: data.postBodies[1].title,
        principalBodyEn: data.postBodies[1].principalBody,
        secondaryBodyEn: data.postBodies[1].secondaryBody,
      });

      const transformedFiles = data.postFiles.map((file, index) => ({
        uid: file.id,
        name: file.fileName,
        url: file.fileLocation,
        status: 'done',
      }));

      setFileList(transformedFiles);
      setPostFiles(transformedFiles);
    }
  }, [form, data, type, id]);

  const beforeUpload = (file) => {
    const isImage =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg';
    if (!isImage) {
      message.error('Solo puedes subir archivos de imagen (JPEG, PNG, JPG)');
    }
    return isImage || Upload.LIST_IGNORE;
  };

  const handleAttachmentsChange = ({ fileList }) => {
    if (fileList.length > 3) {
      message.error('Solo puedes subir exactamente 3 imágenes');
      return;
    }
    console.log(fileList, 'fileList');

    const files = fileList.map((file) => ({
      fileName: file.originFileObj ? file.originFileObj.name : file.name,
      file: file.originFileObj || file.url,
      type: file.originFileObj ? 'newFile' : 'originFile',
    }));
    setPostFiles(files);
    setFileList(fileList);
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [{ align: [] }],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'align',
  ];

  async function urlToFile(url, filename) {
    const response = await fetch(url, { mode: 'no-cors' });
    const blob = await response.blob();
    return new File([blob], filename, { type: blob.type });
  }
  //REFRESH MANUAL A LA TABLA
  const handleRefresh = () => {
    queryClient.invalidateQueries(`/posts`);
  };

  const addPostFunction = async (values) => {
    setIsSubmitting(true);
    let formData = new FormData();

    formData.append('PostBodies[0].Title', values.subjectEs);
    formData.append('PostBodies[0].PrincipalBody', values.principalBodyEs);
    formData.append('PostBodies[0].SecondaryBody', values.secondaryBodyEs);
    formData.append('PostBodies[0].Lang', 'es');

    formData.append('PostBodies[1].Title', values.subjectEn);
    formData.append('PostBodies[1].PrincipalBody', values.principalBodyEn);
    formData.append('PostBodies[1].SecondaryBody', values.secondaryBodyEn);
    formData.append('PostBodies[1].Lang', 'en');

    if (type === 'edit' && data?.postBodies) {
      formData.append(`Id`, id);
      data.postBodies.forEach((body, index) => {
        formData.append(`PostBodies[${index}].Id`, body.id);
      });
    }

    const fileFieldName = type === 'edit' ? 'Files' : 'PostFiles';

    for (let index = 0; index < postFiles.length; index++) {
      const file = postFiles[index];
      if (file.type === 'newFile') {
        formData.append(`${fileFieldName}[${index}].File`, file.file);
        formData.append(`${fileFieldName}[${index}].FileName`, file.fileName);
      } else if (file.type === 'originFile') {
        const convertedFile = await urlToFile(file.file, file.fileName);
        formData.append(`${fileFieldName}[${index}].File`, convertedFile);
        formData.append(
          `${fileFieldName}[${index}].Id`,
          data.postFiles[index].id
        );
        formData.append(
          `${fileFieldName}[${index}].PostId`,
          data.postFiles[index].postId
        );
      }
    }

    try {
      if (type === 'add') {
        add(formData);
      } else {
        await putFormData<T>(`/posts/${id}`, formData, true);
      }
    } catch (error) {
    } finally {
      setIsSubmitting(false);
      setModalIsVisible(false);
      handleRefresh();
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={addPostFunction}>
      <Divider />
      <Tabs
        defaultActiveKey="1"
        type="card"
        size={'small'}
        style={{ marginBottom: 0 }}
        items={[
          {
            label: 'Post en español',
            key: '1',
            children: (
              <>
                <Row
                  gutter={16}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 30,
                  }}
                >
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Título en español:"
                      name="subjectEs"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese el título',
                        },
                      ]}
                    >
                      <Input placeholder="Ingresa el título" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Contenido del cuerpo en español:"
                      name="principalBodyEs"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese el contenido',
                        },
                      ]}
                    >
                      <ReactQuill
                        // value={emailContent}
                        modules={modules}
                        formats={formats}
                        placeholder="Escribe el contenido del post..."
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Contenido del cuerpo 2 en español:"
                      name="secondaryBodyEs"
                    >
                      <ReactQuill
                        // value={emailContent}
                        modules={modules}
                        formats={formats}
                        placeholder="Escribe el contenido del post..."
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ),
          },
          {
            label: 'Post en ingles',
            key: '2',
            forceRender: true,
            children: (
              <>
                <Row
                  gutter={16}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 30,
                  }}
                >
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Título en ingles:"
                      name="subjectEn"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese el título',
                        },
                      ]}
                    >
                      <Input placeholder="Ingresa el título" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Contenido del cuerpo en ingles:"
                      name="principalBodyEn"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese el contenido',
                        },
                      ]}
                    >
                      <ReactQuill
                        // value={emailContent}
                        modules={modules}
                        formats={formats}
                        placeholder="Escribe el contenido del post..."
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Contenido del cuerpo 2 en ingles:"
                      name="secondaryBodyEn"
                    >
                      <ReactQuill
                        // value={emailContent}
                        modules={modules}
                        formats={formats}
                        placeholder="Escribe el contenido del post..."
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ),
          },
          {
            label: 'Imágenes',
            key: '3',
            forceRender: true,
            children: (
              <Row gutter={16} style={{ marginTop: 30 }}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Imágenes:"
                    name="attachmentsEs"
                    rules={[
                      {
                        validator: (_, value) =>
                          fileList.length === 3
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error('Debes subir exactamente 3 imágenes')
                              ),
                      },
                    ]}
                  >
                    <Upload
                      multiple
                      listType="text"
                      fileList={fileList}
                      beforeUpload={beforeUpload}
                      accept=".jpg,.jpeg,.png"
                      onChange={handleAttachmentsChange}
                    >
                      <Button icon={<UploadOutlined />}>
                        Agregar archivos
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            ),
          },
        ]}
      />

      <Row gutter={16}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Enviar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PostForm;
