// @ts-nocheck
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import Password from '../../../../components/general/password';
import { useQuery } from '@tanstack/react-query';
import { CreateUser, Customer, User, get } from '../../../../api';
import { QuestionCircleOutlined } from '@ant-design/icons';

type UserFormProps = {
  type: string;
  id?: number | null;
  add: (v: CreateUser) => void;
  update: (v: User) => void;
};

const UserForm: React.FC<UserFormProps> = (props) => {
  const { type, id, add, update } = props;
  const [form] = Form.useForm();
  const [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const roleOptions = [
    { label: 'ADMIN', value: 'ADMIN' },
    { label: 'DIRECCIÓN', value: 'DIRECTION' },
    { label: 'GERENCIA', value: 'MANAGEMENT' },
    { label: 'VENTAS', value: 'SALES' },
  ];
  //ENDPOINTS
  const { isLoading: isLoadingUser, data } = useQuery<User>([type], () =>
    id ? get<User>('/users/' + id).then((e) => e.data) : ''
  );
  // const { isLoading: isLoadingCustomers, data: customersget } = useQuery<
  //   Customer[]
  // >(['cliente'], () => get<Customer[]>('/customers/').then((e) => e.data));

  // useEffect(() => {
  //   if (customersget) {
  //     const customersOrdenados = [...customersget];
  //     // let cust = customersOrdenados.filter((s) => s.customer_PortalCustomer_c);
  //     customersOrdenados.sort((a, b) =>
  //       a.customer_Name.localeCompare(b.customer_Name)
  //     );
  //     setCustomers(customersOrdenados);
  //   }
  // }, [customersget]);

  useEffect(() => {
    if (data && type !== 'add' && id && !isLoadingUser) {
      form.setFieldsValue({
        email: data?.email,
        userName: data?.userName,
        role: data?.roles[0],
        active: data?.active,
        custID: data?.custID !== 0 ? data?.custID?.toString() : null,
        firstName: data?.firstName,
        lastName: data?.lastName,
        // mothersLastName: data?.mothersLastName,
        phoneNumber: data?.phoneNumber,
      });
    }
  }, [form, data, id, type, isLoadingUser]);

  //OCULTAR SELECT DE CLIENTES
  useEffect(() => {
    if (data && data?.custID !== null && data?.custID !== 0) {
      setCustomerSelected(true);
    } else {
      setCustomerSelected(false);
    }
  }, [data]);

  const addUserFunction = async (values: any) => {
    setIsSubmitting(true);
    values['roles'] = [values.role];

    if (type === 'add') {
      // AGREGAR USUARIO
      let userRequest: CreateUser = {
        email: values.email,
        password: values.password,
        roles: values.roles,
        username: values.userName,
        firstName: values.firstName,
        lastName: values.lastName,
        // mothersLastName: values.mothersLastName,
        phoneNumber: values.phoneNumber,
        custID: values.custID ? values.custID : 0,
      };
      add(userRequest);
    } else {
      // EDITAR USUARIO
      values['id'] = id;
      update(values);
    }
  };

  const validateNoSpaces = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || !/\s/.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject('El nombre de usuario no debe contener espacios');
    },
  });

  return (
    <Spin spinning={isLoadingUser && type !== 'add'} tip={<p>Cargando...</p>}>
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addUserFunction}
      >
        <Divider />
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={20}>
            <Form.Item
              label="Usuario:"
              name="userName"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un nombre',
                },
                validateNoSpaces,
              ]}
            >
              <Input
                placeholder="Ingresa tu información"
                disabled={type === 'watch'}
              />
            </Form.Item>
            {type !== 'add' && (
              <Form.Item
                label="Usuario estatus:"
                name="active"
                valuePropName="checked"
              >
                <Checkbox disabled={type === 'watch'}>Activo</Checkbox>
              </Form.Item>
            )}

            {type === 'add' && <Password type="adduser" />}

            <Form.Item
              label="Nombre(s):"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un nombre',
                },
              ]}
            >
              <Input
                disabled={type === 'watch'}
                placeholder="Ingresa tu información"
              />
            </Form.Item>
            <Form.Item
              label="Apellido:"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese apellido',
                },
              ]}
            >
              <Input
                disabled={type === 'watch'}
                placeholder="Ingresa tu información"
              />
            </Form.Item>
            {/* <Form.Item label="Apellido materno:" name="mothersLastName">
              <Input
                disabled={type === 'watch'}
                placeholder="Ingresa tu información"
              />
            </Form.Item> */}
            <Form.Item
              label={
                <span>
                  Teléfono:&nbsp;
                  <Tooltip title="El número telefónico debe contener 10 dígitos">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa número telefónico',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || value.toString().length === 10) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      'Por favor ingresa número telefónico a 10 dígitos'
                    );
                  },
                }),
              ]}
            >
              <Input
                disabled={type === 'watch'}
                placeholder="Ingresa tu información"
              />
            </Form.Item>

            <Form.Item
              label="Correo electrónico:"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un email',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Ingresa tu información"
                disabled={type !== 'add'}
              />
            </Form.Item>
            <Form.Item
              label="Cliente:"
              name="custID"
              hidden={customerSelected === false}
              rules={[
                {
                  required: customerSelected,
                  message: 'Por favor seleccione un cliente',
                },
              ]}
            >
              <Select
                disabled={type === 'watch'}
                placeholder="Selecciona una opción"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {customers?.map((s: any) => (
                  <Select.Option
                    key={s.customer_CustID}
                    value={s.customer_CustID}
                  >
                    {s.customer_Name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Rol de usuario:"
              name="role"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un rol',
                },
              ]}
            >
              <Radio.Group
                disabled={type === 'watch'}
                options={roleOptions}
                // initialValues={[1]}
                onChange={(e) =>
                  e.target.value === 'CUSTOMER'
                    ? setCustomerSelected(true)
                    : setCustomerSelected(false)
                }
              ></Radio.Group>
            </Form.Item>
            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={type === 'watch' || isLoadingUser || isSubmitting}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default UserForm;
