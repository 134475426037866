// @ts-nocheck
import { Col, Layout, Row, Tag, Typography } from 'antd';
import GreetingsTop from '../../../components/menu/greetings-top';
import OpportunityQuotesList from './list';
import { useEffect, useState } from 'react';
import { formatMoney } from '../../../utils/general';

export default function OpportunityQuotes() {
  const { Title } = Typography;
  const [total, setTotal] = useState(0);
  const [currencySelected, setCurrencySelected] = useState('MXN');
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data) {
      const calculateTotal = () => {
        const totalAmount = data
          .filter((item) => item.currency === currencySelected)
          .reduce((sum, item) => sum + item.totalGrossPrice, 0);
        setTotal(totalAmount);
      };
      calculateTotal();
    }
  }, [currencySelected, data]);

  const handleCurrencyChange = (currency) => {
    setCurrencySelected(currency);
  };

  const onTableLoaded = (table: Opportunity[]) => {
    setData(table);
  };

  return (
    <Layout>
      <GreetingsTop />
      <div className="catalog__container">
        <Row className="header-top" justify={'space-between'}>
          <Col xs={24} md={16} className="header-top__left">
            <Title
              level={3}
              style={{ top: 0, bottom: 0, height: '78%', margin: 'auto' }}
            >
              Oportunidades - Cotizaciones
            </Title>
          </Col>
          <Col xs={24} md={8}>
            <Row justify={'end'}>
              <Col>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p
                    style={{
                      marginRight: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    Total:{' '}
                    <span style={{ fontWeight: 'normal' }}>
                      ${formatMoney(total)}
                    </span>
                  </p>
                  <Tag
                    color={currencySelected === 'USD' ? 'lightgrey' : 'green'}
                    onClick={() => handleCurrencyChange('MXN')}
                  >
                    MXN
                  </Tag>
                  <Tag
                    color={currencySelected === 'MXN' ? 'lightgrey' : 'blue'}
                    onClick={() => handleCurrencyChange('USD')}
                  >
                    USD
                  </Tag>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="catalog__container">
        <OpportunityQuotesList onTableLoaded={onTableLoaded} />
      </div>
    </Layout>
  );
}
