import { basePath } from '../config';
import { notification } from 'antd';
import ApiResponse from '../models/response';

const postFormWithQueryString = async (
  url: string,
  formData: FormData
): Promise<ApiResponse<any>> => {
  let path = basePath + url;
  let keyToken = process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY || '';

  let fetchParams: RequestInit = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(keyToken)}`,
    },
    method: 'POST',
    body: formData,
  };

  try {
    let response = await fetch(path, fetchParams);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    let jsonText = await response.text();

    if (jsonText) {
      let respObj = JSON.parse(jsonText) as ApiResponse<any>;
      let notifBody = {
        message: respObj.message,
      };
      if (respObj.statusCode >= 200 && respObj.statusCode < 300) {
        notification['success'](notifBody);
      } else if (respObj.statusCode >= 400 && respObj.statusCode < 500) {
        notification['warning'](notifBody);
      } else if (respObj.statusCode >= 500 && respObj.statusCode < 600) {
        notification['error'](notifBody);
      }
      return respObj;
    } else {
      throw new Error('Empty response');
    }
  } catch (error) {
    console.error('Error occurred during POST:', error);
    throw error;
  }
};

export default postFormWithQueryString;
