import { notification } from 'antd';

import { basePath } from '../config';
import ApiResponse from '../models/response';

const postFile = async <T>(
  url: string,
  body: any,
  formName: string,
  file?: File | null,
  notif?: boolean
): Promise<ApiResponse<T>> => {
  let path = basePath + url;
  let keyToken = process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY || '';

  let formData = new FormData();
  formData.append('data', JSON.stringify(body));

  if (file) {
    formData.append(formName, file);
  }

  let fetchParams: RequestInit = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(keyToken)}`,
    },
    method: 'POST',
    body: formData,
  };

  try {
    let response = await fetch(path, fetchParams);
    let jsonText = await response.text();
    let respObj = JSON.parse(jsonText) as ApiResponse<T>;

    if (notif === true) {
      let notifBody = {
        message: respObj.message,
      };
      if (respObj.statusCode >= 200 && respObj.statusCode < 300) {
        notification['success'](notifBody);
      } else if (respObj.statusCode >= 400 && respObj.statusCode < 500) {
        notification['warning'](notifBody);
      } else if (respObj.statusCode >= 500 && respObj.statusCode < 600) {
        notification['error'](notifBody);
      }
    }

    return respObj;
  } catch (error) {
    console.error('Error occurred during POST:', error);
    throw error;
  }
};

export default postFile;
