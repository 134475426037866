import { Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import { ExportPdfProps } from '../../../../helpers/pdf';
import moment from 'moment';
import { Opportunity } from '../../../../api';
import OpportunityAddForm from '../add';
import { formatMoney } from '../../../../utils/general';
import TableComponent from '../../../../components/general/table';

const titlePdf = 'Reporte de oportunidades - cotizaciones';
const invisibleButton = true;
const scrollTable = 500;
const label = 'oportunidad-cliente';

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Opportunity>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const CustomerQuoteList = (props: any) => {
  let { id } = props;
  const { Text } = Typography;
  const route = `/customers/${id}/quotes`;

  const generateCustomerTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Opportunity> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Cotización',
        width: 300,
        ellipsis: true,
        dataIndex: 'id',
        key: 'id',
        render: (id, quote) => <>#{quote.id + ' - ' + quote.header}</>,
      },
      {
        title: 'Fecha vencimiento',
        key: 'effectiveEndDate',
        dataIndex: 'effectiveEndDate',
        render: (effectiveEndDate) => (
          <>{moment(effectiveEndDate).format('DD/MM/YYYY')}</>
        ),
      },
      {
        title: 'Estatus',
        ellipsis: true,
        dataIndex: 'stage',
        key: 'stage',
        ...getColumnSearchProps('stage'),
      },
      {
        title: 'Precio',
        ellipsis: true,
        dataIndex: 'total',
        key: 'total',
        ...getColumnSearchProps('total'),
        render: (total) => <Text>$ {formatMoney(total)}</Text>,
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Oportunidades - Cotizaciones',
    sheetName: 'Oportunidades - cotizaciones',
    tableName: 'Tabla de oportunidades - cotizaciones',
    columnNames: [
      { label: 'ID Cotización', key: 'id' },
      { label: 'Cliente', key: 'customerName' },
      {
        label: 'Fecha creación',
        key: 'unitPrice',
        format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
      },
      {
        label: 'Fecha cierre',
        key: 'endDate',
        format: (val) =>
          `${val !== null ? moment(val).format('DD/MM/YYYY') : ''}`,
      },
      { label: 'Estatus', key: 'stage' },
      { label: 'Agente', key: 'agentFirstName' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'ID Cotización', key: 'id' },
    { header: 'Cliente', key: 'customerName' },
    {
      header: 'Fecha creación',
      key: 'unitPrice',
      format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
    },
    {
      header: 'Fecha cierre',
      key: 'endDate',
      format: (val) =>
        `${val !== null ? moment(val).format('DD/MM/YYYY') : ''}`,
    },
    { header: 'Estatus', key: 'stage' },
    { header: 'Agente', key: 'agentFirstName' },
  ];
  const opportunityTableProps = {
    label,
    route,
    generateTableColumns: generateCustomerTableColumns,
    generateExportExcelProps,
    detailForm: OpportunityAddForm,
    pdfColumns,
    titlePdf,
    scrollTable,
    invisibleButton,
  };
  const OpportunityTable = TableComponent<Opportunity>(opportunityTableProps);

  return OpportunityTable;
};

export default CustomerQuoteList;
