import React from 'react';
import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { ExportExcelProps } from '../../../../helpers/excel';
import { ExportPdfProps } from '../../../../helpers/pdf';
import { Task } from '../../../../api';
import TablePaginationComponent from '../../../../components/general/table/table-pagination/tablepagination';
import TaskForm from '../detail';
import moment from 'moment';
import { useUser } from '../../../../hooks';
import { useNavigate } from 'react-router-dom';

const label = 'tarea';
const route = '/tasks';
const titlePdf = 'Reporte de tareas';
const scrollTable = 1700;

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Task>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const TasksList = () => {
  const { Text } = Typography;
  const { user } = useUser();
  let navigate = useNavigate();
  // const invisibleButton = user?.role === 'SALES' ? true : false;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Task> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;
    if (user?.role === 'SALES') {
      return [
        {
          title: 'Asunto',
          width: 130,
          ellipsis: true,
          dataIndex: 'subject',
          key: 'subject',
          ...getColumnSearchProps('subject'),
          render: (subject) => (
            <>
              {subject && (
                <Text>
                  {subject.charAt(0).toUpperCase() + subject.slice(1)}
                </Text>
              )}
            </>
          ),
        },
        {
          title: 'Relacionado con',
          width: 120,
          ellipsis: true,
          dataIndex: 'relatedTo',
          key: 'relatedTo',
          ...getColumnSearchProps('relatedTo'),
          render: (relatedTo) => (
            <>
              {relatedTo && (
                <Text>
                  {relatedTo.charAt(0).toUpperCase() + relatedTo.slice(1)}
                </Text>
              )}
            </>
          ),
        },
        {
          title: 'Prioridad',
          width: 100,
          ellipsis: true,
          dataIndex: 'priority',
          key: 'priority',
          filters: [
            { text: 'Baja', value: 0 },
            { text: 'Media', value: 1 },
            { text: 'Alta', value: 2 },
          ],
          render: (stage) => (
            <Row gutter={16} style={{ width: 10 }}>
              <Tag
                color={
                  stage === 'Baja'
                    ? 'yellow'
                    : stage === 'Media'
                    ? 'orange'
                    : stage === 'Alta'
                    ? 'red'
                    : 'lightgray'
                }
                key={stage}
                style={{ marginBottom: 10 }}
              >
                {stage}
              </Tag>
            </Row>
          ),
        },
        {
          title: 'Estatus',
          width: 100,
          ellipsis: true,
          dataIndex: 'status',
          key: 'status',
          filters: [
            { text: 'Abierta', value: 0 },
            { text: 'Completada', value: 1 },
            { text: 'Expirada', value: 2 },
          ],
          render: (stage) => (
            <Row gutter={16} style={{ width: 10 }}>
              <Tag
                color={
                  stage === 'Abierta'
                    ? 'yellow'
                    : stage === 'Completa'
                    ? 'green'
                    : stage === 'Expirada'
                    ? 'red'
                    : 'lightgray'
                }
                key={stage}
                style={{ marginBottom: 10 }}
              >
                {stage}
              </Tag>
            </Row>
          ),
        },
        {
          title: 'Creado por',
          width: 100,
          ellipsis: true,
          dataIndex: 'createdBy',
          key: 'createdBy',
          ...getColumnSearchProps('createdBy'),
        },
        {
          title: 'Cliente',
          width: 150,
          ellipsis: true,
          dataIndex: 'customerName',
          key: 'customerName',
          ...getColumnSearchProps('customerName'),
        },
        {
          title: 'Cotización',
          width: 100,
          ellipsis: true,
          dataIndex: 'quoteId',
          key: 'quoteId',
          ...getColumnSearchProps('quoteId'),
        },
        {
          title: 'Fecha creación',
          width: 100,
          key: 'createdDate',
          sorter: true,
          dataIndex: 'createdDate',
          render: (createdDate) => (
            <>
              {createdDate !== null
                ? moment(createdDate).format('DD/MM/YYYY')
                : ''}
            </>
          ),
        },
        {
          title: 'Fecha expiración',
          width: 100,
          key: 'expirationDate',
          sorter: true,
          dataIndex: 'expirationDate',
          render: (expirationDate) => (
            <>
              {expirationDate !== null
                ? moment(expirationDate).format('DD/MM/YYYY')
                : ''}
            </>
          ),
        },

        {
          title: 'Acciones',
          key: 'sup',
          fixed: 'right',
          width: 60,
          align: 'center',
          render: (task) => (
            <>
              <Tooltip title="Ver más información">
                <Button
                  type="primary"
                  ghost
                  shape="circle"
                  size="small"
                  icon={<EyeOutlined />}
                  onClick={() => showModal('watch', task.id)}
                  // onClick={() => navigate(`/task/detail/${contact.id}`)}
                />
              </Tooltip>
            </>
          ),
        },
      ];
    } else {
      return [
        {
          title: 'Asunto',
          width: 130,
          ellipsis: true,
          dataIndex: 'subject',
          key: 'subject',
          ...getColumnSearchProps('subject'),
          render: (subject) => (
            <>
              {subject && (
                <Text>
                  {subject.charAt(0).toUpperCase() + subject.slice(1)}
                </Text>
              )}
            </>
          ),
        },
        {
          title: 'Relacionado con',
          width: 120,
          ellipsis: true,
          dataIndex: 'relatedTo',
          key: 'relatedTo',
          ...getColumnSearchProps('relatedTo'),
          render: (relatedTo) => (
            <>
              {relatedTo && (
                <Text>
                  {relatedTo.charAt(0).toUpperCase() + relatedTo.slice(1)}
                </Text>
              )}
            </>
          ),
        },
        {
          title: 'Agente',
          width: 120,
          ellipsis: true,
          dataIndex: 'agent',
          key: 'agent',
          ...getColumnSearchProps('agent'),
          render: (agent) => (
            <>
              {agent && (
                <Text>{agent.charAt(0).toUpperCase() + agent.slice(1)}</Text>
              )}
            </>
          ),
        },
        {
          title: 'Prioridad',
          width: 100,
          ellipsis: true,
          dataIndex: 'priority',
          key: 'priority',
          filters: [
            { text: 'Baja', value: 0 },
            { text: 'Media', value: 1 },
            { text: 'Alta', value: 2 },
          ],
          render: (stage) => (
            <Row gutter={16} style={{ width: 10 }}>
              <Tag
                color={
                  stage === 'Baja'
                    ? 'yellow'
                    : stage === 'Media'
                    ? 'orange'
                    : stage === 'Alta'
                    ? 'red'
                    : 'lightgray'
                }
                key={stage}
                style={{ marginBottom: 10 }}
              >
                {stage}
              </Tag>
            </Row>
          ),
        },
        {
          title: 'Estatus',
          width: 100,
          ellipsis: true,
          dataIndex: 'status',
          key: 'status',
          filters: [
            { text: 'Abierta', value: 0 },
            { text: 'Completada', value: 1 },
            { text: 'Expirada', value: 2 },
          ],
          render: (stage) => (
            <Row gutter={16} style={{ width: 10 }}>
              <Tag
                color={
                  stage === 'Abierta'
                    ? 'yellow'
                    : stage === 'Completa'
                    ? 'green'
                    : stage === 'Expirada'
                    ? 'red'
                    : 'lightgray'
                }
                key={stage}
                style={{ marginBottom: 10 }}
              >
                {stage}
              </Tag>
            </Row>
          ),
        },

        {
          title: 'Creado por',
          width: 100,
          ellipsis: true,
          dataIndex: 'createdBy',
          key: 'createdBy',
          ...getColumnSearchProps('createdBy'),
        },
        {
          title: 'Cliente',
          width: 100,
          ellipsis: true,
          dataIndex: 'customerName',
          key: 'customerName',
          ...getColumnSearchProps('customerName'),
        },
        {
          title: 'Cotización',
          width: 100,
          ellipsis: true,
          dataIndex: 'quoteId',
          key: 'quoteId',
          ...getColumnSearchProps('quoteId'),
        },
        {
          title: 'Fecha creación',
          width: 100,
          key: 'createdDate',
          sorter: true,
          dataIndex: 'createdDate',
          render: (createdDate) => (
            <>
              {createdDate !== null
                ? moment(createdDate).format('DD/MM/YYYY')
                : ''}
            </>
          ),
        },
        {
          title: 'Fecha expiración',
          width: 100,
          key: 'expirationDate',
          sorter: true,
          dataIndex: 'expirationDate',
          render: (expirationDate) => (
            <>
              {expirationDate !== null
                ? moment(expirationDate).format('DD/MM/YYYY')
                : ''}
            </>
          ),
        },

        {
          title: 'Acciones',
          key: 'sup',
          fixed: 'right',
          width: 80,
          align: 'center',
          render: (contact) => (
            <>
              {user?.role === 'SALES' && (
                <Tooltip title="Ver más información">
                  <Button
                    type="primary"
                    ghost
                    shape="circle"
                    size="small"
                    icon={<EyeOutlined />}
                    onClick={() => showModal('watch', contact.id)}
                  />
                </Tooltip>
              )}
              {user?.role !== 'SALES' && (
                <>
                  <Tooltip title="Editar">
                    <Button
                      style={{
                        marginLeft: 10,
                        backgroundColor: '#537896',
                        borderColor: '#537896',
                      }}
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<EditOutlined />}
                      onClick={() => showModal('edit', contact.id)}
                    />
                  </Tooltip>
                  {/* <Tooltip title="Eliminar">
                    <Button
                      style={{
                        marginLeft: 10,
                        backgroundColor: '#e63241',
                        borderColor: '#cf1322',
                      }}
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete(contact.id)}
                    />
                  </Tooltip> */}
                </>
              )}
            </>
          ),
        },
      ];
    }
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Tareas',
    sheetName: 'Tareas',
    tableName: 'Tabla de tareas',
    columnNames: [
      {
        label: 'Asunto',
        key: 'subject',
        format: (val) => val.charAt(0).toUpperCase() + val.slice(1),
      },
      {
        label: 'Agente',
        key: 'agent',
        format: (val) => val.charAt(0).toUpperCase() + val.slice(1),
      },
      { label: 'Prioridad', key: 'priority' },
      { label: 'Estatus', key: 'status' },
      {
        label: 'Creado por',
        key: 'createdBy',
        format: (val) => val.charAt(0).toUpperCase() + val.slice(1),
      },
      { label: 'Cotización', key: 'quoteId' },
      {
        label: 'Fecha creación',
        key: 'unitPrice',
        format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
      },
      {
        label: 'Fecha creación',
        key: 'expirationDate',
        format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
      },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    {
      header: 'Asunto',
      key: 'subject',
      format: (val) => val.charAt(0).toUpperCase() + val.slice(1),
    },
    {
      header: 'Agente',
      key: 'agent',
      format: (val) => val.charAt(0).toUpperCase() + val.slice(1),
    },
    { header: 'Prioridad', key: 'priority' },
    { header: 'Estatus', key: 'status' },
    {
      header: 'Creado por',
      key: 'createdBy',
      format: (val) => val.charAt(0).toUpperCase() + val.slice(1),
    },
    { header: 'Cotización', key: 'quoteId' },
    {
      header: 'Fecha creación',
      key: 'unitPrice',
      format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
    },
    {
      header: 'Fecha creación',
      key: 'expirationDate',
      format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
    },
  ];

  const tasksTableProps = {
    label,
    route,
    detailForm: TaskForm,
    generateTableColumns,
    generateExportExcelProps,
    pdfColumns,
    titlePdf,
    scrollTable,
    // invisibleButton,
  };
  const ContactsTable = TablePaginationComponent<Task>(tasksTableProps);

  return ContactsTable;
};

export default TasksList;
