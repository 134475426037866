import { Button, Col, Layout, Row, Tooltip, Typography } from 'antd';
import GreetingsTop from '../../../components/menu/greetings-top';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import ServicesList from './list';

export default function Services() {
  const { Title } = Typography;
  const navigate = useNavigate();

  return (
    <Layout>
      <GreetingsTop />
      <div className="catalog__container">
        <Row className="header-top">
          <Col xs={19} className="header-top__left">
            <Title
              level={3}
              style={{ top: 0, bottom: 0, height: '78%', margin: 'auto' }}
            >
              Parte/Servicios
            </Title>
          </Col>
          <Col xs={5}>
            <Row style={{ marginBottom: 30, justifyContent: 'right' }}>
              <Tooltip title="Atrás">
                <Button
                  style={{ color: 'gray', fontSize: 20, height: 0, width: 0 }}
                  type="link"
                  onClick={() => navigate('/catalogs')}
                >
                  <LeftOutlined />
                </Button>
              </Tooltip>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="catalog__container">
        <ServicesList />
      </div>
    </Layout>
  );
}
