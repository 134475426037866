// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  Tooltip,
  Select,
  Divider,
  Space,
} from 'antd';
import { QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CreateCustomer, Customer, User, get, post } from '../../../../api';
import { useQuery } from '@tanstack/react-query';
import { useUser } from '../../../../hooks';
import type { InputRef } from 'antd';

type UserProps = {
  type: string;
  id?: number | null;
  add: (v: CreateCustomer) => void;
  update: (v: Customer) => void;
};
const CustomerForm = (props: UserProps) => {
  const { type, id, add, update } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [customerTypeSelected, setCustomerTypeSelected] = useState(1);
  const [form] = Form.useForm();
  const { user } = useUser();
  const [tagName, setTagName] = useState('');
  const [tagsItems, setTagsItems] = useState([]);
  const inputRef = useRef<InputRef>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading: isLoadingData, data } = useQuery<Customer>([type], () =>
    id ? get<Customer>('/customers/' + id).then((e) => e.data) : ''
  );
  const { isLoading: isloadingtypes, data: customertypes } = useQuery<any>(
    ['types'],
    () => get<any>('/customertypes').then((e) => e.data)
  );
  const { isLoading: isloadingtags, data: tags } = useQuery<any>(['tags'], () =>
    get<any>('/tags').then((e) => e.data)
  );
  const { isLoading: isLoadingUsers, data: userssget } = useQuery<User[]>(
    ['usuarios'],
    () => get<User[]>('/users').then((e) => e.data)
  );
  const { isLoading: isloadingScores, data: customerScores } = useQuery<any>(
    ['customerScores'],
    () => get<any>('/customerScores').then((e) => e.data)
  );
  useEffect(() => {
    if (userssget) {
      const usersOrdenados = [...userssget];
      usersOrdenados.sort((a, b) => a.firstName.localeCompare(b.firstName));
      setUsers(usersOrdenados);
    }
  }, [userssget]);
  useEffect(() => {
    if (tags) {
      setTagsItems(tags);
    }
  }, [tags]);

  useEffect(() => {
    if (type !== 'add' && id) {
      form.setFieldsValue({
        address: data?.address,
        city: data?.city,
        email: data?.email,
        name: data?.name,
        phone: data?.phone,
        phoneExtension: data?.phoneExtension,
        customerScoreId: data?.customerScoreId,
        rfc: data?.rfc,
        typeId: data?.typeId,
        state: data?.state,
        sector: data?.sector,
        postalCode: data?.postalCode,
        website: data?.website,
        country: data?.country,
        territory: data?.territory,
        region: data?.region,
        agentId: data?.agentId,
        tagIds: data?.tagIds,
      });
      setCustomerTypeSelected(data?.typeId);
    } else {
      form.setFieldsValue({
        country: 'México',
        agentId: user === null ? '' : user.id.toString(),
        typeId: 1,
      });
    }
    setIsLoading(false);
  }, [form, data, id, type, user]);

  const addCustomerFunction = async (values: any) => {
    setIsLoading(true);
    if (type === 'add') {
      let requestBody: CreateCustomer = {
        name: values.name,
        rfc: values.rfc,
        phone: values.phone,
        phoneExtension: values.phoneExtension,
        customerScoreId: values.customerScoreId,
        address: values.address,
        region: values.region,
        city: values.city,
        state: values.state,
        country: values.country,
        postalCode: values.postalCode,
        territory: values.territory,
        sector: values.sector,
        email: values.email,
        website: values.website,
        typeId: values.typeId,
        agentId: values.agentId,
        tagIds: values.tagIds,
      };
      add(requestBody);
    } else {
      if (id === undefined || id === null) return;
      let requestBody: Customer = {
        name: values.name,
        rfc: values.rfc,
        phone: values.phone,
        phoneExtension: values.phoneExtension,
        customerScoreId:
          values.customerScoreId !== undefined ? values.customerScoreId : null,
        address: values.address,
        region: values.region,
        city: values.city,
        state: values.state,
        country: values.country,
        postalCode: values.postalCode,
        territory: values.territory,
        sector: values.sector,
        email: values.email,
        website: values.website,
        typeId: values.typeId,
        agentId: values.agentId,
        tagIds: values.tagIds,
        id: id,
      };
      update(requestBody);
    }
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };
  const addItem = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setIsSubmitting(true);
    let resp = await post('/tags', { description: tagName });
    if (resp.statusCode === 200) {
      let resp2 = await get('/tags');
      if (resp2.statusCode === 200) {
        setTagsItems([...resp2.data]);
        setTagName('');
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }
    setIsSubmitting(false);
  };
  return (
    <Spin spinning={isLoading || isLoadingData} tip={<p>Cargando...</p>}>
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addCustomerFunction}
      >
        <div>
          <Divider />
          <div>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Nombre de cliente:"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un nombre',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type !== 'add'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Sitio web:" name="website">
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Etiqueta:" name="tagIds">
                  <Select
                    placeholder="Seleccione etiqueta"
                    mode="multiple"
                    showSearch={false}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                          <Input
                            placeholder="Nueva etiqueta"
                            ref={inputRef}
                            value={tagName}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={addItem}
                            disabled={isSubmitting}
                          >
                            Agregar
                          </Button>
                        </Space>
                      </>
                    )}
                    // options={tagsItems.map((e) => ({
                    //   label: e.description,
                    //   value: e.id,
                    // }))}
                  >
                    {tagsItems?.map((e: any) => (
                      <Select.Option
                        key={e.id}
                        value={e.id}
                        disabled={
                          user.role === 'SALES' && e.description === 'Cliente'
                        }
                      >
                        {e.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Correo electrónico:"
                  name="email"
                  rules={[
                    {
                      required: customerTypeSelected !== 1,
                      message: 'Por favor ingrese un email',
                    },
                  ]}
                >
                  <Input
                    type={'email'}
                    placeholder="Ingresa tu información"
                    disabled={data?.email !== null && data?.email !== undefined}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Sector:" name="sector">
                  <Input
                    placeholder="Ingresa tu información"
                    // disabled={type !== 'add'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Tipo:"
                  name="typeId"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese tipo',
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecciona una opción"
                    disabled={type === 'watch'}
                    onChange={(e) => setCustomerTypeSelected(e)}
                  >
                    {customertypes?.map((e: any) => (
                      <Select.Option
                        key={e.id}
                        value={e.id}
                        disabled={
                          user.role === 'SALES' && e.description === 'Cliente'
                        }
                      >
                        {e.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label="RFC:"
                  name="rfc"
                  rules={[
                    {
                      required: customerTypeSelected === 3,
                      message: 'Por favor ingrese RFC',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={5}>
                <Form.Item
                  label={
                    <span>
                      Teléfono:&nbsp;
                      <Tooltip title="El número telefónico debe contener 10 dígitos">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa número telefónico',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || value.toString().length === 10) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          'Por favor ingresa número telefónico a 10 dígitos'
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                    // maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={3}>
                <Form.Item label="Extensión:" name="phoneExtension">
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                    // maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Agente:" name="agentId">
                  <Select
                    disabled={type === 'watch' || user?.role === 'SALES'}
                    style={{ width: '100%' }}
                    placeholder="Selecciona una opción"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {users?.map((s: any) => (
                      <Select.Option key={s.id} value={s.id}>
                        {s.firstName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Calificación:" name="customerScoreId">
                  <Select
                    placeholder="Selecciona una opción"
                    disabled={type === 'watch'}
                    allowClear
                  >
                    {customerScores?.map((e: any) => (
                      <Select.Option
                        key={e.id}
                        value={e.id}
                        disabled={
                          user.role === 'SALES' && e.description === 'Cliente'
                        }
                      >
                        {e.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Divider>Dirección</Divider>
          <div>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label="País:"
                  name="country"
                  rules={[
                    {
                      required: customerTypeSelected !== 1,
                      message: 'Por favor ingrese País',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Estado:"
                  name="state"
                  rules={[
                    {
                      required: customerTypeSelected !== 1,
                      message: 'Por favor ingrese Estado',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Ciudad:"
                  name="city"
                  rules={[
                    {
                      required: customerTypeSelected !== 1,
                      message: 'Por favor ingrese Ciudad',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label={
                    <span>
                      CP:&nbsp;
                      <Tooltip title="El código postal debe contener 5 dígitos">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="postalCode"
                  rules={[
                    {
                      required: customerTypeSelected !== 1,
                      message: 'Por favor ingrese un código postal',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    maxLength={5}
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Territorio:" name="territory">
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Colonia:" name="region">
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={24}>
                <Form.Item
                  label="Dirección:"
                  name="address"
                  rules={[
                    {
                      required: customerTypeSelected !== 1,
                      message: 'Por favor ingrese Dirección',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa tu información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Col span={24}>
            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={type === 'watch' || isLoading}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </div>
      </Form>
    </Spin>
  );
};

export default CustomerForm;
