// @ts-nocheck
import {
  Button,
  Col,
  ColorPicker,
  Divider,
  Form,
  Input,
  Row,
  Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CreateService, Service, get } from '../../../../api';
import { Contact, CreateContact } from '../../../../api/models/contacts';

type ScoresFormProps = {
  type: string;
  id?: number | null;
  add: (v: CreateContact) => void;
  update: (v: Contact) => void;
};

const ScoreForm: React.FC<ScoresFormProps> = (props) => {
  const { type, id, add, update } = props;
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  //ENDPOINTS
  const { isLoading: isLoadingContact, data } = useQuery<Service>([type], () =>
    id ? get<Service>('/customerScores/' + id).then((e) => e.data) : ''
  );

  useEffect(() => {
    if (data && type !== 'add' && id && !isLoadingContact) {
      form.setFieldsValue({
        description: data?.description,
        name: data?.name,
        color: data?.color,
      });
    }
  }, [form, data, id, type, isLoadingContact]);

  const addServiceFunction = async (values: any) => {
    setIsSubmitting(true);
    const colorHex = values.color.toHexString();

    if (type === 'add') {
      // AGREGAR SCORE
      let contactRequest: CreateService = {
        description: values.description,
        color: colorHex,
      };
      add(contactRequest);
    } else {
      // EDITAR SCORE
      values['id'] = id;
      values['color'] = colorHex;
      update(values);
    }
  };

  return (
    <Spin
      spinning={isLoadingContact && type !== 'add'}
      tip={<p>Cargando...</p>}
    >
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addServiceFunction}
      >
        <Divider />
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={20}>
            <Row gutter={16}>
              <Col xs={12} md={24}>
                <Form.Item
                  label="Descripción:"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese una descripción.',
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingresa información"
                    disabled={type === 'watch'}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={24}>
                <Form.Item
                  label="Color:"
                  name="color"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un color.',
                    },
                  ]}
                >
                  <ColorPicker
                    size="large"
                    format="hex"
                    defaultValue="#1677ff"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={
                    type === 'watch' || isLoadingContact || isSubmitting
                  }
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ScoreForm;
