import React, { useState } from 'react';
import { Form, Input, Button, Spin, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { PasswordRecoveryModel } from '../../../api/models/login';
import { post } from '../../../api';

export default function PasswordRecoveryForm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values: PasswordRecoveryModel) => {
    setLoading(true);
    let passwordRecoveryResponse = await post(
      '/auth/new-password-request',
      values
    );
    if (passwordRecoveryResponse.success) {
      notification['success']({
        message: passwordRecoveryResponse.message,
        description:
          'Recibirás un correo con un enlace para realizar el proceso de recuperación de contraseña. Por favor revisa tu correo.',
        duration: 10,
      });
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } else {
      notification['error']({
        message: passwordRecoveryResponse.message,
      });
    }
    setLoading(false);
  };

  return (
    <Spin tip={<p>Cargando...</p>} spinning={loading}>
      <Form
        className="login-form"
        name="normal_login"
        initialValues={{ rememberMe: true }}
        onFinish={onFinish}
        requiredMark={false}
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Correo de recuperación"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'El correo electrónico no es válido',
            },
            {
              required: true,
              message: 'Por favor ingresa un correo electrónico',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Correo de recuperación"
          />
        </Form.Item>

        <Form.Item>
          <Button
            danger
            type="primary"
            htmlType="submit"
            size="large"
            shape="round"
            className="login-form-login"
          >
            ENVIAR
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            danger
            type="primary"
            htmlType="submit"
            size="large"
            shape="round"
            className="login-form-regresar"
            onClick={() => navigate('/login')}
          >
            REGRESAR
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}
