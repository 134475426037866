// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Menu,
  Avatar,
  Image,
  Badge,
  Col,
  Row,
  Divider,
} from 'antd';
import {
  PoweroffOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DownOutlined,
  UserOutlined,
  CloseOutlined,
  NotificationFilled,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import planbLogo from '../../../assets/img/png/logo-planb2.png';
import planbIcon from '../../../assets/img/png/planb-icon.png';
import useUser from '../../../hooks/useUser';

import './menu-top.scss';
import { useAuth } from '../../../hooks';
import { getFilteredDataFromLocalStorage } from '../../../helpers/saveFilterData';
import { useQuery } from '@tanstack/react-query';
import { Customer, User, del, get, put } from '../../../api';
import { queryClient } from '../../../config';

const MenuTop = (props: { menuCollapsed: any; setMenuCollapsed: any }) => {
  const { menuCollapsed, setMenuCollapsed } = props;
  const { user } = useUser();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [profilePicture, setProfilePicture] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [numberAlerts, setNumberAlerts] = useState([]);
  const filter = user.roles[0] === 'SALES' ? `?agentId=${user.userName}` : '';

  const { isLoadingNotifications, data: notifications } = useQuery<User>(
    ['notifications'],
    () => get<User>('/users/' + user.id + '/notifications').then((e) => e.data)
  );

  const menuItems = (
    <Menu>
      <Menu.Item key="pictureUser">
        <Image
          src={profilePicture ? profilePicture : planbIcon}
          style={{ width: 100 }}
        />
      </Menu.Item>
      <Menu.Item key="/profile">
        <Link to="/profile">
          <UserOutlined />
          <span className="nav-text">Perfil</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        key="nameUser"
        danger
        icon={<PoweroffOutlined />}
        onClick={() => logoutUser()}
      >
        Salir
      </Menu.Item>
    </Menu>
  );
  const logoutUser = () => {
    logout();
    navigate('/login');
    // window.location.reload();
  };

  useEffect(() => {
    if (notifications?.length > 0) {
      let numeroDeAlertas = 0;

      const updatedNotifications = notifications.map((element, index) => {
        const [title, description] = element.description.split(': ');

        if (element.read === false) {
          numeroDeAlertas++;
        }

        return {
          ...element,
          title: title || 'Sin Título',
          description: description || 'Sin Descripción',
          key: index,
        };
      });

      setNumberAlerts(numeroDeAlertas);

      setAlerts(updatedNotifications);
    }
  }, [notifications]);

  const handleItemClick = (item) => {
    navigate(`/task/detail/${item.entityId}`);
    if (item.read === false) {
      showNotifications(item);
    }
  };

  const handleRefresh = () => {
    queryClient.invalidateQueries('notifications');
  };
  const handleDeleteItemClick = async (notification) => {
    const result = await del(
      `/users/${user.id}/notifications/${notification.id}`
    );
    if (result) {
      if (result.statusCode === 200) {
        handleRefresh();
      }
    }
  };
  const showNotifications = async (notification) => {
    let requestBody = {
      id: notification.id,
      notifierId: notification.notifierId,
      read: true,
    };
    const result = await put(
      `/users/${user.id}/notifications/${notification.id}`,
      requestBody,
      false
    );
    if (result) {
      if (result.statusCode === 200) {
        handleRefresh();
      }
    }
  };

  return (
    <div className="menu-top">
      <div className="menu-top__left">
        <img
          src={planbLogo}
          alt="apLogo"
          style={{ width: 60, marginLeft: 20 }}
        />
        <Button
          style={{ marginTop: 10 }}
          icon={menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          type="link"
          size="large"
          onClick={() => setMenuCollapsed(!menuCollapsed)}
        ></Button>
      </div>

      <div className="menu-top__right">
        {/* <Dropdown
          trigger={['click']}
          overlay={menuNotifications}
          onClick={() => obtenerDatos()}
          // style={{ marginRight: 30 }}
        >
          <Badge style={{ marginRight: 20 }}>
            <UserOutlined
              style={{
                fontSize: 20,
                color: 'white',
                marginRight: 30,
              }}
            />
          </Badge>
        </Dropdown> */}
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu
              // onClick={handleMenuClick}
              className={notifications?.length > 5 && 'active-scroll'}
            >
              {notifications?.length === 0 && (
                <Menu.Item>No hay alertas activas</Menu.Item>
              )}
              {alerts?.map((alert, index) => (
                <Menu.Item
                  key={index}
                  // icon={<p style={{ color: alert.pinColor }}>&#9679;</p>}
                >
                  <Row>
                    <Col xs={21} onClick={() => handleItemClick(alert)}>
                      <span>
                        {alert.read === false && (
                          <span style={{ fontSize: 10, marginRight: 5 }}>
                            &#128308;
                          </span>
                        )}
                        <strong>{alert.title}</strong>: {alert.description}
                      </span>
                      <Divider style={{ margin: '10px 0px 0px 0px' }} />
                    </Col>
                    <Col xs={3}>
                      <Row justify="end">
                        <Button
                          type="link"
                          icon={
                            <CloseOutlined
                              style={{
                                height: 24,
                                color: 'gray',
                                fontSize: 14,
                              }}
                            />
                          }
                          danger
                          onClick={() => handleDeleteItemClick(alert)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Menu.Item>
              ))}
            </Menu>
          }
          // onClick={() => getNotifications()}
          style={{ marginRight: 30 }}
        >
          <Badge count={numberAlerts} style={{ marginRight: 20 }}>
            <NotificationFilled
              style={{
                fontSize: 20,
                color: 'white',
                marginRight: 30,
              }}
            />
          </Badge>
        </Dropdown>
        <Dropdown overlay={menuItems}>
          <a href="javascript: void(0)" className="ant-dropdown-link">
            <Avatar
              shape="circle"
              src={profilePicture ? profilePicture : planbIcon}
              style={{ marginRight: 5 }}
            />{' '}
            {/* {person?.name} */}
            <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default MenuTop;
