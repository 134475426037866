// @ts-nocheck
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  CreateOpportunity,
  Opportunity,
  Service,
  Tax,
  get,
} from '../../../../api';
import { useParams } from 'react-router-dom';
import { formatMoney } from '../../../../utils/general';

type OpportunityFormProps = {
  type: string;
  id?: number | null;
  add: (v: CreateOpportunity) => void;
  update: (v: Opportunity) => void;
};

const calculateTaxAmount = (key: Tax, netPrice: number): number => {
  let amount = (netPrice * key.percent) / 100;
  return amount;
};

const OpportunityLineForm: React.FC<OpportunityFormProps> = (props) => {
  const { type, id, add, update } = props;
  const [formDetail] = Form.useForm();
  const [partSelected, setPartSelected] = useState('');
  const [currencyQuote, setCurrencyQuote] = useState('');
  const [exchangeRateQuote, setExchangeRateQuote] = useState(0);
  const [quantitySelected, setQuantitySelected] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [percentDiscount, setPercentDiscount] = useState(0);
  const [taxesIdsSelected, setTaxesIdsSelected] = useState([]);
  const [taxesList, setTaxesList] = useState([]);
  let { id: quoteId } = useParams();
  const { Text } = Typography;

  //ENDPOINTS
  const { isLoading: isLoadingQuoteLine, data: quoteline } =
    useQuery<Opportunity>(['/quotelines/' + id], () =>
      id ? get<Opportunity>('/quotelines/' + id).then((e) => e.data) : null
    );
  const { isLoading: isLoadingQuote, data: quote } = useQuery<Opportunity>(
    ['/quote/' + quoteId],
    () =>
      quoteId
        ? get<Opportunity>('/quotes/' + quoteId).then((e) => e.data)
        : null
  );
  const { isLoading: isLoadingServices, data: services } = useQuery<Service[]>(
    ['parte'],
    () => get<Service[]>('/parts').then((e) => e.data)
  );

  const { isLoading: isLoadingTaxes, data: taxes } = useQuery<Service[]>(
    ['taxes'],
    () => get<Tax[]>('/taxes').then((e) => e.data)
  );

  useEffect(() => {
    if (quote) {
      setCurrencyQuote(quote?.currency);
      setExchangeRateQuote(quote?.exchangeRate);
      formDetail.setFieldsValue({
        exchangeRate: quote?.exchangeRate,
      });
    }
  }, [quote, formDetail]);

  useEffect(() => {
    if (quoteline && type !== 'add' && !isLoadingQuoteLine) {
      formDetail.setFieldsValue({
        description: quoteline?.partDescription,
        // unitPrice: quoteline?.unitPrice,
        currency: quoteline?.currency,
        quantity: quoteline?.quantity,
        partId: quoteline?.partId,
        taxIds: quoteline?.taxIds,
        netPrice: quoteline?.netPrice,
        total: quoteline?.total,
        discount: quoteline?.discount,
        percentDiscount: quoteline?.percentDiscount,
      });
      setPartSelected(quoteline?.partId);
      setDiscount(quoteline?.discount);
      setPercentDiscount(quoteline?.percentDiscount);
      setQuantitySelected(quoteline?.quantity);
      setTaxesIdsSelected(quoteline?.taxIds);
    }
  }, [formDetail, quoteline, type, isLoadingQuoteLine]);

  useEffect(() => {
    if (partSelected !== '' && services && partSelected !== undefined) {
      let part = services?.find((item) => item.id === partSelected);

      formDetail.setFieldsValue({
        description: part.description,
        unitPrice: part.unitPrice,
        currency: part.currency,
      });
    }
  }, [formDetail, partSelected, services, exchangeRateQuote, currencyQuote]);

  useEffect(() => {
    if (quantitySelected === 0) return;

    const part = services?.find((item) => item.id === partSelected);
    const selectedTaxes =
      taxes?.filter((item) => taxesIdsSelected.includes(item.id)) || [];
    const totalUnitPrice = unitPrice > 0 ? unitPrice : part?.unitPrice || 0;
    let descuento = discount > 0 ? discount : 0;

    if (percentDiscount > 0) {
      descuento = ((totalUnitPrice * percentDiscount) / 100) * quantitySelected;
      formDetail.setFieldsValue({ discount: descuento });
    }
    let grossPrice = totalUnitPrice * quantitySelected - descuento;

    if (part?.currency !== currencyQuote) {
      if (currencyQuote === 'MXN') {
        grossPrice =
          totalUnitPrice * exchangeRateQuote * quantitySelected - descuento;
      } else if (currencyQuote === 'USD') {
        grossPrice =
          parseFloat((totalUnitPrice / exchangeRateQuote) * quantitySelected) -
          descuento;
      }
    }

    const selectedTaxesMapped = selectedTaxes.map((tax) => ({
      ...tax,
      taxAmount: calculateTaxAmount(tax, grossPrice),
    }));

    setTaxesList(selectedTaxesMapped);

    const totalTaxAmount = selectedTaxesMapped.reduce(
      (total, { taxAmount }) => total + taxAmount,
      0
    );
    const total = parseFloat(totalTaxAmount + grossPrice);

    formDetail.setFieldsValue({
      grossPrice: grossPrice.toFixed(2),
      netPrice: total.toFixed(2),
      total: total.toFixed(2),
    });
  }, [
    formDetail,
    quantitySelected,
    partSelected,
    services,
    taxesIdsSelected,
    taxes,
    currencyQuote,
    exchangeRateQuote,
    unitPrice,
    discount,
    percentDiscount,
  ]);

  useEffect(() => {
    if (percentDiscount > 0 && quantitySelected !== 0) {
      const part = services?.find((item) => item.id === partSelected);
      const totalUnitPrice = unitPrice > 0 ? unitPrice : part?.unitPrice || 0;
      const descuento =
        ((totalUnitPrice * percentDiscount) / 100) * quantitySelected;
      formDetail.setFieldsValue({ discount: descuento });
      setDiscount(descuento);
    }
    if (percentDiscount === null) {
      formDetail.setFieldsValue({ discount: 0 });
      setDiscount(0);
    }
  }, [
    percentDiscount,
    quantitySelected,
    partSelected,
    services,
    unitPrice,
    formDetail,
  ]);

  const columns = [
    {
      title: 'Descripción',
      width: 100,
      ellipsis: true,
      dataIndex: 'description',
      key: 'description',
      render: (description) => (
        <>
          {description && (
            <Text>
              {description.charAt(0).toUpperCase() + description.slice(1)}
            </Text>
          )}
        </>
      ),
    },
    {
      title: 'Porcentaje',
      width: 50,
      ellipsis: true,
      dataIndex: 'percent',
      key: 'percent',
      render: (percent) => <>{<Text>{percent}%</Text>}</>,
    },
    {
      title: 'Cantidad de impuesto',
      width: 50,
      ellipsis: true,
      dataIndex: 'taxAmount',
      key: 'taxAmount',
      render: (taxAmount) => <>{<Text>${formatMoney(taxAmount)}</Text>}</>,
    },
  ];

  const addOpportunityLineFunction = async (values: any) => {
    if (type === 'add') {
      // AGREGAR LINEA
      let opportunityRequest: CreateOpportunityLine = {
        quantity: values.quantity,
        taxIds: taxesIdsSelected,
        partId: values.partId,
        unitPrice: values.unitPrice,
        quoteId: quoteId,
        discount: values.discount,
        percentDiscount: values.percentDiscount ? values.percentDiscount : 0,
      };
      add(opportunityRequest);
    } else {
      // EDITAR QUOTE
      values['id'] = id;
      values['percentDiscount'] = values.percentDiscount
        ? values.percentDiscount
        : 0;
      values['discount'] = values.discount ? values.discount : 0;
      update(values);
    }
  };

  return (
    <Spin
      spinning={isLoadingQuoteLine && type !== 'add'}
      tip={<p>Cargando...</p>}
    >
      <Form
        name="add-promo"
        layout="vertical"
        form={formDetail}
        onFinish={addOpportunityLineFunction}
      >
        <Divider />
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Row gutter={16}>
              <Col xs={24}>
                {services && (
                  <Form.Item
                    label="Parte/Servicio:"
                    name="partId"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor seleccione parte',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      disabled={type === 'watch'}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      onChange={(val) => setPartSelected(val)}
                    >
                      {services.map((e: any) => (
                        <Select.Option key={e.id} value={e.id}>
                          {e.id + '-' + e.description}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col xs={24}>
                <Form.Item label="Descripción:" name="description">
                  <Input placeholder="Ingresa información" disabled />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Cantidad:"
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese cantidad',
                    },
                  ]}
                >
                  <InputNumber
                    onChange={(val) => setQuantitySelected(val)}
                    style={{ width: '100%' }}
                    disabled={type === 'watch' || partSelected === ''}
                    placeholder="Ingresa información"
                    // defaultValue={0}
                    min={1}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={12}>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item label="Precio unitario:" name="unitPrice">
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={partSelected === '' || quantitySelected <= 0}
                    placeholder="Ingresa información"
                    onChange={(val) => setUnitPrice(val)}
                    // defaultValue={0}
                    min={0}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item label="Moneda:" name="currency">
                  <Select
                    disabled
                    placeholder="Selecciona una opción"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    options={[
                      { value: 'MXN', label: 'MXN' },
                      { value: 'USD', label: 'USD' },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={4}>
                <Form.Item label="Cambio:" name="exchangeRate">
                  <InputNumber disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={6}>
                <Form.Item label="% Descuento:" name="percentDiscount">
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={partSelected === '' || quantitySelected <= 0}
                    placeholder="Ingresa información"
                    onChange={(val) => setPercentDiscount(val)}
                    // defaultValue={0}
                    min={0}
                    formatter={(value) =>
                      ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item label="Descuento:" name="discount">
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={
                      partSelected === '' ||
                      quantitySelected <= 0 ||
                      percentDiscount > 0
                    }
                    placeholder="Ingresa información"
                    onChange={(val) => setDiscount(val)}
                    // defaultValue={0}
                    min={0}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  label="Impuestos:"
                  name="taxIds"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese impuesto.',
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecciona una opción"
                    disabled={type === 'watch'}
                    allowClear
                    showSearch
                    mode="multiple"
                    optionFilterProp="children"
                    onChange={(e) => setTaxesIdsSelected(e)}
                    // onChange={(e)=> onTaxesChange(e)}
                  >
                    {taxes?.map((e: any) => (
                      <Select.Option key={e.id} value={e.id}>
                        {e.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item label="Precio bruto:" name="grossPrice">
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled
                    placeholder="Ingresa información"
                    // defaultValue={0}
                    min={0}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Total:"
                  name="total"
                  style={{ width: '100%' }}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled
                    placeholder="Ingresa información"
                    // defaultValue={0}
                    min={0}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {taxesList.length > 0 && (
            <Table columns={columns} dataSource={taxesList} />
          )}
        </Row>
        <Row justify="end">
          <Form.Item>
            <Button
              htmlType="submit"
              className="btn-add"
              disabled={type === 'watch' || isLoadingQuoteLine}
            >
              Guardar
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  );
};

export default OpportunityLineForm;
