import { Link } from 'react-router-dom';
import { Layout, Menu, Typography } from 'antd';
import {
  HomeOutlined,
  ToolOutlined,
  TeamOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import './menu-sider.scss';
import CanShow from '../../can-show';
import { useUser } from '../../../hooks';

const MenuSider = ({
  menuCollapsed,
  roles = [],
}: {
  menuCollapsed: boolean | undefined;
  roles: string[];
}) => {
  // const { customer } = useEpicorCustomer();
  const { Sider } = Layout;
  const location = useLocation();
  const { Paragraph } = Typography;
  const { user } = useUser();

  return (
    <Sider
      className="admin-sider"
      collapsed={menuCollapsed}
      collapsible
      breakpoint="md"
      collapsedWidth="0"
      width={220}
      style={{ position: 'fixed' }}
    >
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/">
          <Link to="/">
            <HomeOutlined />
            <span className="nav-text">Inicio</span>
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="/facturacion">
          <Link to="/facturacion">
            <FileProtectOutlined />
            <span className="nav-text">Facturación</span>
          </Link>
        </Menu.Item> */}
        {user?.role === 'SALES' && (
          <Menu.Item key="/tasks">
            <CanShow roles={['SALES']}>
              <Link to="/tasks">
                <UnorderedListOutlined />
                <span className="nav-text">Tareas</span>
              </Link>
            </CanShow>
          </Menu.Item>
        )}

        <Menu.Item key="/customers">
          <Link to="/customers">
            <TeamOutlined />
            <span className="nav-text">Clientes</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/opportunity-quotes">
          <Link to="/opportunity-quotes">
            <ContainerOutlined />
            <span className="nav-text">Oportunidades</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/callLogs">
          <Link to="/callLogs">
            <PhoneOutlined />
            <span className="nav-text">Seguimientos</span>
          </Link>
        </Menu.Item>
        {/* <CanShow roles={['Customer']}>
          <Menu.Item key="/suppliers" style={{ marginLeft: 28 }}>
            <Link to="/suppliers">
              <UserOutlined />
              <span className="nav-text">Proveedores</span>
            </Link>
          </Menu.Item>
        </CanShow> */}
        {/* <CanShow roles={['SALES']}>
          <Menu.Item key="/customers" style={{ marginLeft: 28 }}>
            <Link to="/customers">
              <UserOutlined />
              <span className="nav-text">Clientes</span>
            </Link>
          </Menu.Item>
        </CanShow> */}
        <Menu.Item key="/administration">
          <CanShow roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}>
            <Link to="/administration">
              <ToolOutlined />
              <span className="nav-text">Administración</span>
            </Link>
          </CanShow>
        </Menu.Item>
        <Menu.Item key="/catalogs">
          <CanShow roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}>
            <Link to="/catalogs">
              <UnorderedListOutlined />
              <span className="nav-text">Catálogos</span>
            </Link>
          </CanShow>
        </Menu.Item>
        {/* <CanShow roles={['CUSTOMER']}>
          <>
            <Space direction="vertical" size={10} className="space-complete">
              <Card
                headStyle={{ color: 'rgba(255, 255, 255, 0.65)' }}
                size="small"
                title="Ejecutivo de cobranza"
                className="agent-card"
              >
                <p>Nombre: {customer?.salesRep_BillingAgent_Name}</p>
                <p>
                  Teléfono: {customer?.salesRep_BillingAgent_OfficePhoneNum}
                </p>
                <p>Correo: {customer?.salesRep_BillingAgent_EMailAddress}</p>
              </Card>
              <Card
                headStyle={{ color: 'rgba(255, 255, 255, 0.65)' }}
                size="small"
                title="Agente de ventas"
                className="agent-card"
              >
                <p>Nombre: {customer?.salesRep_Name}</p>
                <p>Teléfono: {customer?.salesRep_CellPhoneNum}</p>
                <p>Correo: {customer?.salesRep_EMailAddress}</p>
              </Card>
            </Space>
            {/* <div className="menu-extra">
              <div className="menu-info">
                <Row style={{ justifyContent: 'center' }}>
                  <Space size={5} align="baseline">
                    <PhoneOutlined />
                    <Paragraph>
                      Tel. {''}
                      {customer &&
                        formatPhoneNumber(
                          parseInt(customer.salesRep_CellPhoneNum)
                        )}
                    </Paragraph>
                  </Space>
                </Row>
              </div>
              <div className="menu-info">
                <Col style={{}}>
                  <Row style={{ justifyContent: 'center' }}>
                    <MailOutlined style={{ marginTop: 3 }} />
                    <Paragraph style={{ marginLeft: 5 }}>
                      Agente de ventas
                    </Paragraph>
                  </Row>
                  <Col>
                    <Paragraph>{customer?.salesRep_Name}</Paragraph>
                  </Col>
                </Col>
                <Row style={{ justifyContent: 'center' }}>
                  <div style={{ width: 190 }}>
                    <Paragraph>{customer?.salesRep_EMailAddress}</Paragraph>
                  </div>
                </Row>
              </div>
            </div> 
          </>
        </CanShow> */}
      </Menu>
    </Sider>
  );
};

export default MenuSider;
