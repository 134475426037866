import { Row, Col, Typography, Button } from 'antd';
import ClientIcon from '../../../assets/img/png/customer-icon.png';
import UserIcon from '../../../assets/img/png/user-icon.png';
import ContactIcon from '../../../assets/img/png/contact-icon.png';
import MailingIcon from '../../../assets/img/png/mailing-icon.png';
import TaskIcon from '../../../assets/img/png/task.png';
import NotificationIcon from '../../../assets/img/png/notification.png';

import './modules.scss';
import { useNavigate } from 'react-router';

export default function Modules() {
  // const { person } = useUser();
  const catalog = [
    {
      name: 'clients',
      isAdmin: true,
    },
    {
      name: 'users',
      isAdmin: true,
    },
    {
      name: 'contacts',
      isAdmin: true,
    },
    {
      name: 'mailing',
      isAdmin: true,
    },
    {
      name: 'task',
      isAdmin: true,
    },
    {
      name: 'posts',
      isAdmin: true,
    },
  ];

  return (
    <div className="modules">
      <Row gutter={[0, 15]}>
        {/* {[ROL_ADMIN].some((r) => person?.role.includes(r)) && ( */}
        <>
          {catalog.map((cat, index) => {
            if (cat.isAdmin) {
              return (
                <Col
                  span={12}
                  key={index}
                  className="modules__container animate__animated  animate__bounceInLeft"
                >
                  <ButtonAction type={cat.name} />
                </Col>
              );
            }
          })}
        </>
        {/* )} */}
        {catalog.map((cat, index) => {
          if (!cat.isAdmin) {
            return (
              <Col
                key={index}
                span={12}
                className="modules__container animate__animated  animate__bounceInLeft"
              >
                <ButtonAction type={cat.name} />
              </Col>
            );
          }
        })}
      </Row>
    </div>
  );
}

function ButtonAction(props: { type: string }) {
  const { type } = props;
  let urlImage = '';
  let title = '';
  if (type === 'contacts') {
    urlImage = ContactIcon;
    title = 'Contactos';
  }
  if (type === 'users') {
    urlImage = UserIcon;
    title = 'Usuarios';
  }
  if (type === 'clients') {
    urlImage = ClientIcon;
    title = 'Clientes';
  }
  if (type === 'mailing') {
    urlImage = MailingIcon;
    title = 'Correos';
  }
  if (type === 'task') {
    urlImage = TaskIcon;
    title = 'Tareas';
  }
  if (type === 'posts') {
    urlImage = NotificationIcon;
    title = 'Entradas al blog';
  }

  const { Title } = Typography;
  const navigate = useNavigate();

  const handleRedirect = () => {
    // if (type === 'supplier') {
    //   navigate('/administration/suppliers');
    // } else
    if (type === 'users') {
      navigate('/administration/users');
    } else if (type === 'clients') {
      navigate('/administration/customers');
    } else if (type === 'contacts') {
      navigate('/administration/contacts');
    } else if (type === 'mailing') {
      navigate('/administration/mailing');
    } else if (type === 'task') {
      navigate('/administration/tasks');
    } else if (type === 'posts') {
      navigate('/administration/posts');
    }
  };

  return (
    <Button
      className="modules__button"
      style={{ padding: 10 }}
      onClick={() => handleRedirect()}
    >
      <img width={60} src={urlImage} />
      <Title style={{ color: 'white', marginTop: 10 }} level={5}>
        {title}
      </Title>
    </Button>
  );
}
