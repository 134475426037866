// @ts-nocheck
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CreateService, Service, get } from '../../../../api';
import { Contact, CreateContact } from '../../../../api/models/contacts';

type ServiceFormProps = {
  type: string;
  id?: number | null;
  add: (v: CreateContact) => void;
  update: (v: Contact) => void;
};

const ServiceForm: React.FC<ServiceFormProps> = (props) => {
  const { type, id, add, update } = props;
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  //ENDPOINTS
  const { isLoading: isLoadingContact, data } = useQuery<Service>([type], () =>
    id ? get<Service>('/parts/' + id).then((e) => e.data) : ''
  );

  useEffect(() => {
    if (data && type !== 'add' && id && !isLoadingContact) {
      form.setFieldsValue({
        description: data?.description,
        unitPrice: data?.unitPrice,
        currency: data?.currency,
      });
    }
  }, [form, data, id, type, isLoadingContact]);

  const addServiceFunction = async (values: any) => {
    setIsSubmitting(true);
    if (type === 'add') {
      // AGREGAR SERVICIO
      let contactRequest: CreateService = {
        description: values.description,
        unitPrice: values.unitPrice,
        currency: values.currency,
      };
      add(contactRequest);
    } else {
      // EDITAR SERVICIO
      values['id'] = id;
      update(values);
    }
  };

  return (
    <Spin
      spinning={isLoadingContact && type !== 'add'}
      tip={<p>Cargando...</p>}
    >
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addServiceFunction}
      >
        <Divider />
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={20}>
            <Form.Item
              label="Descripción:"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese una descripción.',
                },
              ]}
            >
              <Input
                placeholder="Ingresa información"
                disabled={type === 'watch'}
              />
            </Form.Item>
            <Row gutter={16}>
              <Col xs={12} md={12}>
                <Form.Item
                  label="Precio unitario:"
                  name="unitPrice"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese precio unitario',
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled={type === 'watch'}
                    placeholder="Ingresa información"
                    defaultValue={0}
                    min={1}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={12}>
                <Form.Item
                  label="Moneda:"
                  name="currency"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione moneda',
                    },
                  ]}
                >
                  <Select
                    disabled={type === 'watch'}
                    placeholder="Selecciona una opción"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    options={[
                      { value: 'MXN', label: 'MXN' },
                      { value: 'USD', label: 'USD' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={
                    type === 'watch' || isLoadingContact || isSubmitting
                  }
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ServiceForm;
