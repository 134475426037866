import {
  Button,
  Col,
  Layout,
  Row,
  Tooltip,
  Typography,
  Modal as ModalAntd,
} from 'antd';

import './customers.scss';
import GreetingsTop from '../../../components/menu/greetings-top';
import { LeftOutlined, UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import CustomersList from './list';
import { useLocation } from 'react-router-dom';
import { ReactElement, useState } from 'react';
import LoadFromFile from '../../../components/general/load-from-file';
import Modal from '../../../components/general/modal';

export default function Customers() {
  const { Title } = Typography;
  const navigate = useNavigate();
  const location = useLocation();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [contextModal, setContextModal] = useState<ReactElement | null>(null);

  const showModal = () => {
    setModalIsVisible(true);
    setTitleModal('Carga masiva de clientes');
    setContextModal(() => (
      <LoadFromFile setModalIsVisible={setModalIsVisible} />
    ));
  };
  return (
    <Layout>
      <GreetingsTop />
      <div className="catalog__container">
        <Row className="header-top">
          <Col xs={18} className="header-top__left">
            <Title
              level={3}
              style={{ top: 0, bottom: 0, height: '78%', margin: 'auto' }}
            >
              Clientes
            </Title>
          </Col>
          {location.pathname === '/administration/customers' && (
            <>
              <Col xs={5}>
                <Button
                  type="primary"
                  style={{ marginTop: 5 }}
                  icon={<UploadOutlined />}
                  onClick={() => showModal()}
                >
                  Cargar plantilla
                </Button>
              </Col>
              <Col xs={1}>
                <Row style={{ marginBottom: 30, justifyContent: 'right' }}>
                  <Tooltip title="Atrás">
                    <Button
                      style={{
                        color: 'gray',
                        fontSize: 20,
                        height: 0,
                        width: 0,
                      }}
                      type="link"
                      onClick={() => navigate('/administration')}
                    >
                      <LeftOutlined />
                    </Button>
                  </Tooltip>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </div>
      <div className="catalog__container">
        <CustomersList />
      </div>
      <Modal
        title={titleModal}
        isVisible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </Layout>
  );
}
