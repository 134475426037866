import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Spin,
  notification,
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import './login-form.scss';
import { LoginRequest } from '../../../api';
import useAuth from '../../../hooks/useAuth';
import { useUser } from '../../../hooks';

export default function LoginForm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    if (user !== null) navigate('/');
  }, [user, navigate]);

  const onFinish = async (values: LoginRequest) => {
    localStorage.clear();
    setLoading(true);
    let loginres = await login(values);
    if (loginres.success) {
      notification['success']({
        message: `${loginres.message}`,
      });
    } else {
      notification['error']({
        message: `${loginres.message}`,
      });
    }
    setLoading(false);
  };

  return (
    <Spin tip={<p>Cargando...</p>} spinning={loading}>
      <Form
        className="login-form"
        name="normal_login"
        initialValues={{ rememberMe: true }}
        onFinish={onFinish}
        requiredMark={false}
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Correo electrónico"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'El correo electrónico no es válido',
            },
            {
              required: true,
              message: 'Por favor ingresa un correo electrónico',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Correo electrónico"
          />
        </Form.Item>
        <Form.Item
          label="Contraseña"
          name="password"
          rules={[
            {
              required: true,
              message: 'Por favor ingresa una contraseña',
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Contraseña"
          />
        </Form.Item>
        <Divider></Divider>
        <Form.Item className="login-form__content">
          <Row gutter={2}>
            <Col span={12}>
              {/* <Form.Item name="rememberMe" valuePropName="checked" noStyle>
                <Checkbox>Recuérdame</Checkbox>
              </Form.Item> */}
            </Col>
            <Col span={12} className="login-form__content-right">
              <Button type="link" onClick={() => navigate('/forgot-password')}>
                ¿Olvidó su contraseña?
              </Button>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <Button
            danger
            type="primary"
            htmlType="submit"
            size="large"
            shape="round"
            className="login-form-login"
          >
            Ingresar
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}
