// @ts-nocheck
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Line,
  Svg,
} from '@react-pdf/renderer';
import Logo from '../../../../assets/img/png/logo_planb.png';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { useEffect, useState } from 'react';
import { Opportunity } from '../../../../api';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { formatMoney } from '../../../../utils/general';
type OpportunityFormProps = {
  type?: string;
  id?: number | null;
  // add: (v: CreateOpportunity) => void;
  update?: (v: Opportunity) => void;
};

const OpportunityQuotePdf: React.FC<OpportunityFormProps> = (props) => {
  const { data } = props;
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const currentDay = new Date().getDate();
  const dateString = `${currentDay}/${currentMonth}/${currentYear}`;
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (data?.quoteLines) {
      const total = data.quoteLines.reduce((accumulator, line) => {
        return accumulator + line.total;
      }, 0);
      const tax = data.quoteLines.reduce((accumulator, line) => {
        return accumulator + line.taxes;
      }, 0);

      setTotalAmount(total + tax);
    }
  }, [data]);
  const styles = StyleSheet.create({
    page: {
      padding: 40,
    },
    section: {
      margin: 10,
      flexGrow: 1,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      // alignItems: 'center',
      width: '100%',
      marginBottom: 20,
    },
    columnLeft: {
      width: '48%',
      textAlign: 'left',
      // justifySelf: 'end',
    },
    columnRight: {
      width: '48%',
      textAlign: 'right',
      // justifySelf: 'end',
    },
    paragraph: {
      marginBottom: 10,
      fontSize: 11,
    },
    listItem: {
      flexDirection: 'row',
      marginBottom: 5,
      marginLeft: 10,
      fontSize: 12,
    },
    listItemText: {
      marginLeft: 5,
    },
    blockquote: {
      // borderLeftWidth: 2,
      // borderLeftColor: '#CCCCCC',
      paddingLeft: 40,
      fontSize: 12,
      marginBottom: 10,
    },
    blockquoteText: {
      fontStyle: 'italic',
    },
    bold: {
      // fontFamily: 'source-code-pro',
      fontWeight: '600',
    },
    italic: {
      fontStyle: 'italic',
    },
    underline: {
      textDecoration: 'underline',
    },
    note: {
      fontStyle: 'italic',
      fontSize: 12,
      marginTop: 5,
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 10,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    boldText: {
      fontWeight: 'bold',
      fontSize: 12,
    },
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 20,
    },
    logo: {
      width: 120,
      height: 100,
      justifySelf: 'start',
    },
  });
  const renderBlocks = (blocks) => {
    return blocks.map((block) => {
      const { type, text, inlineStyleRanges } = block;

      switch (type) {
        case 'unstyled':
          return (
            <Text key={block.key} style={styles.paragraph}>
              {applyInlineStyles(text, inlineStyleRanges)}
            </Text>
          );
        case 'unordered-list-item':
          return (
            <View key={block.key} style={styles.listItem}>
              <Text>{'\u2022'}</Text>
              <Text style={styles.listItemText}>
                {applyInlineStyles(text, inlineStyleRanges)}
              </Text>
            </View>
          );
        case 'ordered-list-item':
          return (
            <View key={block.key} style={styles.listItem}>
              <Text>{`${block.depth + 1}.`}</Text>
              <Text style={styles.listItemText}>
                {applyInlineStyles(text, inlineStyleRanges)}
              </Text>
            </View>
          );
        case 'blockquote':
          return (
            <View key={block.key} style={styles.blockquote}>
              <Text style={styles.blockquoteText}>
                {applyInlineStyles(text, inlineStyleRanges)}
              </Text>
            </View>
          );
        default:
          return null;
      }
    });
  };

  // Función para aplicar estilos en línea al texto
  const applyInlineStyles = (text, inlineStyleRanges) => {
    let styledText = text;

    inlineStyleRanges.forEach((range) => {
      switch (range.style) {
        case 'BOLD':
          styledText = <Text style={styles.bold}>{styledText}</Text>;
          break;
        case 'ITALIC':
          styledText = <Text style={styles.italic}>{styledText}</Text>;
          break;
        case 'UNDERLINE':
          styledText = <Text style={styles.underline}>{styledText}</Text>;
          break;
        // Agregar más casos según sea necesario para otros estilos
        default:
          break;
      }
    });

    return styledText;
  };
  return (
    <Document>
      <Page size="LETTER" style={styles.page} wrap>
        <div style={styles.row}>
          <div style={styles.columnLeft}>
            <Image src={Logo} style={styles.logo} />
          </div>
          <div style={styles.columnRight}>
            <View style={styles.containerRight}>
              <Text
                style={{
                  textAlign: 'right',
                  fontSize: 22.4,
                  marginTop: 5,
                  fontWeight: 600,
                }}
              >
                COTIZACIÓN
              </Text>
              <Text
                style={{
                  textAlign: 'right',
                  fontSize: 15.4,
                  marginTop: 5,
                  fontWeight: 600,
                }}
              >
                {`N° ${data.id}`}
              </Text>
              <Text style={{ textAlign: 'right', fontSize: 10, marginTop: 5 }}>
                {`Fecha: ${dateString}`}
              </Text>
              <Text style={{ textAlign: 'right', fontSize: 10, marginTop: 5 }}>
                {`Vencimiento: ${dayjs(data.effectiveEndDate).format(
                  'DD/MM/YYYY'
                )}`}
              </Text>
            </View>
          </div>
        </div>
        <div style={styles.row}>
          <div style={styles.columnLeft}>
            <Text
              style={{
                // textAlign: 'right',
                fontSize: 11,
                marginTop: 5,
                fontWeight: 600,
                color: '#E5A100',
              }}
            >
              CLIENTE
            </Text>
            <Text
              style={{
                fontSize: 19,
                marginTop: 5,
                fontWeight: 600,
                textTransform: 'uppercase',
              }}
            >
              {data.customer.name}
            </Text>
            <Text
              style={{ fontSize: 10, marginTop: 5, textTransform: 'uppercase' }}
            >
              {data.customer.address}
            </Text>
            <Text
              style={{ fontSize: 10, marginTop: 5, textTransform: 'uppercase' }}
            >
              {data.customer.region}
            </Text>
            <Text
              style={{ fontSize: 10, marginTop: 5, textTransform: 'uppercase' }}
            >
              {data.customer.city ||
              data.customer.state ||
              data.customer.country
                ? [
                    data.customer.city,
                    data.customer.state,
                    data.customer.country,
                  ]
                    .filter(Boolean)
                    .join(', ')
                : null}
            </Text>
            <Text style={{ fontSize: 10, marginTop: 5 }}>
              {data.customer.postalCode}
            </Text>
          </div>
          {data?.contact && (
            <div style={styles.columnRight}>
              <View style={styles.containerRight}>
                <Text
                  style={{
                    textAlign: 'right',
                    fontSize: 11,
                    marginTop: 30,
                    fontWeight: 600,
                    color: '#E5A100',
                  }}
                >
                  DATOS DEL CONTACTO
                </Text>
                <Text
                  style={{
                    textAlign: 'right',
                    fontSize: 10,
                    marginTop: 5,
                    fontWeight: 600,
                  }}
                >
                  {data.contact.name + ' ' + data.contact.lastName}
                </Text>
                <Text
                  style={{ textAlign: 'right', fontSize: 10, marginTop: 5 }}
                >
                  {data.contact.rol}
                </Text>
                <Text
                  style={{
                    textAlign: 'right',
                    fontSize: 10,
                    marginTop: 5,
                    fontWeight: 600,
                  }}
                >
                  {data.contact.phone.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    '$1-$2-$3'
                  )}
                </Text>
                <Text
                  style={{
                    textAlign: 'right',
                    fontSize: 10,
                    marginTop: 5,
                    fontWeight: 600,
                  }}
                >
                  {data.contact.email}
                </Text>
              </View>
            </div>
          )}
        </div>
        {data.header && (
          <div style={styles.row}>
            <Text
              style={{
                // textAlign: 'right',
                fontSize: 15,
                marginTop: 5,
                fontWeight: 600,
                backgroundColor: '#424141',
                color: 'white',
                width: '100%',
                padding: 10,
              }}
            >
              {data.header}
            </Text>
          </div>
        )}

        {data.serviceDetail && (
          <>
            <div style={styles.row}>
              <div>
                <Text
                  style={{
                    // textAlign: 'right',
                    fontSize: 11,
                    marginTop: 5,
                    fontWeight: 600,
                    color: '#E5A100',
                  }}
                >
                  DETALLES DEL SERVICIO
                </Text>
              </div>
            </div>

            <View style={{ margin: 10, flexGrow: 1 }}>
              {renderBlocks(
                JSON.parse(
                  data?.serviceDetail !== ''
                    ? data.serviceDetail
                    : '{"blocks":[]}'
                ).blocks
              )}
            </View>
          </>
        )}

        {data?.activities.length > 0 && (
          <div style={styles.row}>
            <div>
              <Text
                style={{
                  // textAlign: 'right',
                  fontSize: 11,
                  marginTop: 5,
                  fontWeight: 600,
                  color: '#E5A100',
                  marginBottom: 20,
                }}
              >
                TIEMPO ESTIMADO DE ENTREGA
              </Text>
              <div
                style={{
                  backgroundColor: '#424141',
                  color: 'white',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 5,
                  padding: 10,
                }}
              >
                <div style={styles.columnLeft}>
                  <Text style={{ fontSize: 9, marginTop: 5, color: 'white' }}>
                    ACTIVIDAD
                  </Text>
                </div>
                <div style={styles.columnRight}>
                  <Text
                    style={{
                      fontSize: 9,
                      marginTop: 5,
                      color: 'white',
                      marginRight: 10,
                    }}
                  >
                    ETA
                  </Text>
                </div>
              </div>
              {data.activities.map((e, index) => (
                <div
                  style={{
                    backgroundColor: '#F1F1F0',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                    padding: 10,
                  }}
                >
                  <div style={styles.columnLeft}>
                    <Text style={{ fontSize: 9, marginTop: 5 }}>
                      {e.description}
                    </Text>
                  </div>
                  <div style={styles.columnRight}>
                    <Text
                      style={{ fontSize: 9, marginTop: 5, marginRight: 10 }}
                    >
                      {e.eta}
                    </Text>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {data?.quoteLines.length > 0 && (
          <div style={styles.row}>
            <div>
              <Text
                style={{
                  // textAlign: 'right',
                  fontSize: 11,
                  marginTop: 5,
                  fontWeight: 600,
                  color: '#E5A100',
                  marginBottom: 20,
                }}
              >
                COSTOS
              </Text>
              <div
                style={{
                  backgroundColor: '#424141',
                  color: 'white',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 5,
                  padding: 10,
                }}
              >
                <div style={styles.columnLeft}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{ width: '10%', textAlign: 'left' }}>
                      <Text
                        style={{ fontSize: 9, marginTop: 5, color: 'white' }}
                      >
                        #
                      </Text>
                    </div>
                    <div style={{ width: '90%', textAlign: 'left' }}>
                      <Text
                        style={{ fontSize: 9, marginTop: 5, color: 'white' }}
                      >
                        DESCRIPCIÓN
                      </Text>
                    </div>
                  </div>
                </div>
                <div style={styles.columnRight}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{ width: '33%', textAlign: 'left' }}>
                      <Text
                        style={{ fontSize: 9, marginTop: 5, color: 'white' }}
                      >
                        PRECIO
                      </Text>
                    </div>
                    <div style={{ width: '33%', textAlign: 'left' }}>
                      <Text
                        style={{ fontSize: 9, marginTop: 5, color: 'white' }}
                      >
                        CANTIDAD
                      </Text>
                    </div>
                    <div style={{ width: '33%', textAlign: 'left' }}>
                      <Text
                        style={{ fontSize: 9, marginTop: 5, color: 'white' }}
                      >
                        TOTAL
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              {data.quoteLines.map((e, index) => (
                <>
                  <div
                    style={{
                      backgroundColor: '#F1F1F0',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 5,
                      padding: 10,
                    }}
                  >
                    <div style={styles.columnLeft}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div style={{ width: '10%', textAlign: 'left' }}>
                          <Text style={{ fontSize: 9, marginTop: 5 }}>
                            {index + 1}
                          </Text>
                        </div>
                        <div style={{ width: '90%', textAlign: 'left' }}>
                          <Text style={{ fontSize: 9, marginTop: 5 }}>
                            {e.partDescription}
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div style={styles.columnRight}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div style={{ width: '33%', textAlign: 'left' }}>
                          <Text style={{ fontSize: 9, marginTop: 5 }}>
                            ${formatMoney(e.unitPrice)}
                          </Text>
                        </div>
                        <div style={{ width: '33%', textAlign: 'left' }}>
                          <Text style={{ fontSize: 9, marginTop: 5 }}>
                            {e.quantity}
                          </Text>
                        </div>
                        <div style={{ width: '33%', textAlign: 'left' }}>
                          <Text style={{ fontSize: 9, marginTop: 5 }}>
                            ${formatMoney(e.total)}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  // padding: 10,
                }}
              >
                <div style={styles.columnLeft}></div>
                <div style={styles.columnRight}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#F1F1F0',
                      marginBottom: 5,
                      padding: 10,
                    }}
                  >
                    <div style={{ width: '33%', textAlign: 'left' }}>
                      <Text style={{ fontSize: 9 }}>Impuesto</Text>
                    </div>
                    <div style={{ width: '33%', textAlign: 'left' }}>
                      <Text style={{ fontSize: 9 }}>
                        {data.totalTaxesPercent}%
                      </Text>
                    </div>
                    <div style={{ width: '33%', textAlign: 'left' }}>
                      <Text style={{ fontSize: 9 }}>
                        ${formatMoney(data.totalTaxes)}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                }}
              >
                <div style={styles.columnLeft}></div>
                <div style={styles.columnRight}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#424141',
                      padding: 10,
                    }}
                  >
                    <div
                      style={{
                        width: '33%',
                        textAlign: 'left',
                        color: 'white',
                      }}
                    >
                      <Text style={{ fontSize: 9, marginTop: 5 }}>TOTAL</Text>
                    </div>
                    <div style={{ width: '33%', textAlign: 'left' }}></div>
                    <div style={{ width: '33%', textAlign: 'left' }}>
                      <Text
                        style={{ fontSize: 9, marginTop: 5, color: 'white' }}
                      >
                        ${formatMoney(totalAmount)}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <View style={styles.containerRight}>
                <Text
                  style={{
                    fontSize: 11,
                    marginTop: 5,
                    fontWeight: 600,
                    color: '#E5A100',
                    marginBottom: 20,
                  }}
                >
                  CONSIDERACIONES
                </Text>
                <Text
                  style={{ textAlign: 'right', fontSize: 10, marginTop: 5 }}
                >
                  **Costos pueden variar según Precios ofrecidos por el
                  Proveedor al momento de la Contratación.
                </Text>
                <Text
                  style={{ textAlign: 'right', fontSize: 10, marginTop: 5 }}
                >
                  **Tipo de cambio al del pago del servicio
                </Text>
                <Text
                  style={{ textAlign: 'right', fontSize: 10, marginTop: 5 }}
                >
                  **Precio aproximado
                </Text>
              </View>
            </div>
          </div>
        )}
        {data?.quoteNotes.length > 0 && (
          <>
            <Text
              style={{
                fontSize: 11,
                marginTop: 5,
                fontWeight: 600,
                color: '#E5A100',
                marginBottom: 20,
              }}
            >
              NOTAS GENERALES
            </Text>
            {data.quoteNotes.map((e, index) => (
              <Text style={{ fontSize: 10, marginTop: 5 }}>
                {index + 1 + '-' + e.description}
              </Text>
            ))}
          </>
        )}
        {data?.quoteClauses.length > 0 && (
          <>
            <Text
              style={{
                fontSize: 11,
                marginTop: 20,
                fontWeight: 600,
                color: '#E5A100',
                marginBottom: 20,
              }}
            >
              CLÁUSULAS
            </Text>
            {data.quoteClauses.map((e, index) => (
              <Text style={{ fontSize: 10, marginTop: 5 }}>
                {index + 1 + '-' + e.description}
              </Text>
            ))}
          </>
        )}
        <View style={styles.container}>
          <View style={styles.columnLeft}>
            <Text
              style={{
                fontSize: 13,
                marginBottom: 10,
                textAlign: 'center',
              }}
            >
              "PLAN B"
            </Text>
            <Svg height="10" style={{ marginTop: 30 }}>
              <Line
                x1="0"
                y1="5"
                x2="250"
                y2="5"
                strokeWidth={1}
                stroke="rgb(0,0,0)"
              />
              <Line
                x1="0"
                y1="5"
                x2="250"
                y2="5"
                strokeWidth={1}
                stroke="rgb(0,0,0)"
              />
            </Svg>
            <Text
              style={{
                fontSize: 13,
                marginBottom: 10,
                textAlign: 'center',
              }}
            >
              PLANBSYS SC
            </Text>
            {data.ownerAgent && (
              <>
                <Text style={{ fontSize: 13, textAlign: 'center' }}>
                  PREPRESENTADA POR
                </Text>
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                  }}
                >
                  {data.ownerAgent}
                </Text>
                <Text style={{ fontSize: 12, textAlign: 'center' }}>
                  {data.ownerAgentPosition}
                </Text>
              </>
            )}
          </View>
          <View style={styles.columnRight}>
            <Text
              style={{
                fontSize: 13,
                marginBottom: 10,
                textAlign: 'center',
              }}
            >
              "EL CLIENTE"
            </Text>
            <Svg height="10" style={{ marginTop: 30 }}>
              <Line
                x1="0"
                y1="5"
                x2="250"
                y2="5"
                strokeWidth={1}
                stroke="rgb(0,0,0)"
              />
              <Line
                x1="0"
                y1="5"
                x2="250"
                y2="5"
                strokeWidth={1}
                stroke="rgb(0,0,0)"
              />
            </Svg>
            <Text
              style={{
                fontSize: 13,
                marginBottom: 10,
                textAlign: 'center',
                textTransform: 'uppercase',
              }}
            >
              {data?.customer?.name}
            </Text>
            {data?.customerAgent && (
              <>
                <Text style={{ fontSize: 13, textAlign: 'center' }}>
                  PREPRESENTADA POR
                </Text>
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                  }}
                >
                  {data.customerAgent}
                </Text>
                <Text style={{ fontSize: 12, textAlign: 'center' }}>
                  {data.customerAgentPosition}
                </Text>
              </>
            )}
          </View>
        </View>
        <View style={styles.containerBottom} fixed />

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        {/* <View style={styles.containerTop} fixed /> */}
      </Page>
    </Document>
  );
};

export default OpportunityQuotePdf;
