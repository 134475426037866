import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import {
  FilePdfOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { ExportExcelProps } from '../../../../helpers/excel';
import { ExportPdfProps } from '../../../../helpers/pdf';
import { CanShow } from '../../../../components';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Opportunity } from '../../../../api';
import OpportunityAddForm from '../add';
import { formatMoney } from '../../../../utils/general';
import TablePaginationComponent from '../../../../components/general/table/table-pagination/tablepagination';

const label = 'oportunidad';
const titlePdf = 'Reporte de oportunidades - cotizaciones';
const route = '/quotes';
const invisibleButton = false;
const scrollTable = 1700;
type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Opportunity>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const OpportunityQuotesList = ({
  onTableLoaded,
}: {
  onTableLoaded: (d: any[]) => void;
}) => {
  let navigate = useNavigate();
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Opportunity> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'ID Cotización',
        width: 100,
        ellipsis: true,
        dataIndex: 'id',
        sorter: true,
        key: 'id',
        ...getColumnSearchProps('id'),
      },
      {
        title: 'Cliente',
        width: 100,
        ellipsis: true,
        sorter: true,
        dataIndex: 'customerName',
        key: 'customerName',
        ...getColumnSearchProps('customerName'),
      },
      {
        title: 'Proyecto',
        width: 150,
        ellipsis: true,
        sorter: true,
        dataIndex: 'header',
        key: 'header',
        ...getColumnSearchProps('header'),
      },
      {
        title: 'Fecha creación',
        width: 100,
        sorter: true,
        key: 'createdDate',
        dataIndex: 'createdDate',
        ...getColumnSearchProps('createdDate'),

        render: (createdDate) => (
          <>{moment(createdDate).format('DD/MM/YYYY')}</>
        ),
      },
      {
        title: 'Fecha cierre',
        width: 100,
        key: 'endDate',
        sorter: true,
        dataIndex: 'endDate',
        render: (endDate) => (
          <>{endDate !== null ? moment(endDate).format('DD/MM/YYYY') : ''}</>
        ),
      },
      {
        title: 'Estatus',
        width: 100,
        ellipsis: true,
        sorter: true,
        dataIndex: 'stage',
        key: 'stage',
        filters: [
          { text: 'Inicio', value: 'Inicio' },
          { text: 'Demo', value: 'Demo' },
          { text: 'Cotización', value: 'Cotización' },
          { text: 'Cerrada ganada', value: 'Cerrada ganada' },
          { text: 'Cerrada perdida', value: 'Cerrada perdida' },
        ],
        render: (stage) => (
          <Row gutter={16} style={{ width: 10 }}>
            <Tag
              color={
                stage === 'Inicio'
                  ? 'yellow'
                  : stage === 'Demo'
                  ? 'orange'
                  : stage === 'Cotización'
                  ? 'blue'
                  : stage === 'Cerrada ganada'
                  ? 'green'
                  : stage === 'Cerrada perdida'
                  ? 'red'
                  : 'lightgray'
              }
              key={stage}
              style={{ marginBottom: 10 }}
            >
              {stage}
            </Tag>
          </Row>
        ),
      },
      {
        title: 'Agente',
        width: 100,
        ellipsis: true,
        sorter: true,
        dataIndex: 'agentFirstName',
        key: 'agentFirstName',
        ...getColumnSearchProps('agentFirstName'),
      },
      {
        title: 'Precio',
        width: 120,
        ellipsis: true,
        dataIndex: 'totalGrossPrice',
        sorter: true,
        key: 'totalGrossPrice',
        ...getColumnSearchProps('totalGrossPrice'),
        render: (totalGrossPrice, quote) => (
          <Text>$ {formatMoney(totalGrossPrice) + ' ' + quote.currency}</Text>
        ),
      },
      {
        title: 'Precio + IVA',
        width: 120,
        ellipsis: true,
        dataIndex: 'total',
        sorter: true,
        key: 'total',
        ...getColumnSearchProps('total'),
        render: (total, quote) => (
          <Text>$ {formatMoney(total) + ' ' + quote.currency}</Text>
        ),
      },
      {
        title: 'Acciones',
        key: 'agentId',
        fixed: 'right',
        width: 100,
        align: 'center',
        render: (opportunity) => (
          <>
            {/* <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="circle"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', opportunity.id)}
              />
            </Tooltip> */}
            <Tooltip title="Editar">
              <Button
                type="primary"
                shape="circle"
                ghost
                size="small"
                icon={<EditOutlined />}
                onClick={() => navigate(`/opportunity/quote/${opportunity.id}`)}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#e63241',
                  borderColor: '#cf1322',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(opportunity.id)}
              />
            </Tooltip>
            <Tooltip title="Ver PDF">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#537896',
                  borderColor: '#537896',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<FilePdfOutlined />}
                onClick={() =>
                  navigate(`/table/opportunity-quote/${opportunity.id}/pdf`)
                }
              />
            </Tooltip>
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Oportunidades - Cotizaciones',
    sheetName: 'Oportunidades - cotizaciones',
    tableName: 'Tabla de oportunidades - cotizaciones',
    columnNames: [
      { label: 'ID Cotización', key: 'id' },
      { label: 'Cliente', key: 'customerName' },
      {
        label: 'Fecha creación',
        key: 'createdDate',
        format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
      },
      {
        label: 'Fecha cierre',
        key: 'endDate',
        format: (val) =>
          `${val !== null ? moment(val).format('DD/MM/YYYY') : ''}`,
      },
      { label: 'Estatus', key: 'stage' },
      { label: 'Agente', key: 'agentFirstName' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'ID Cotización', key: 'id' },
    { header: 'Cliente', key: 'customerName' },
    {
      header: 'Fecha creación',
      key: 'createdDate',
      format: (val) => `${moment(val).format('DD/MM/YYYY')}`,
    },
    {
      header: 'Fecha cierre',
      key: 'endDate',
      format: (val) =>
        `${val !== null ? moment(val).format('DD/MM/YYYY') : ''}`,
    },
    { header: 'Estatus', key: 'stage' },
    { header: 'Agente', key: 'agentFirstName' },
  ];
  const opportunityTableProps = {
    label,
    route,
    generateTableColumns: generateTableColumns,
    generateExportExcelProps,
    detailForm: OpportunityAddForm,
    pdfColumns,
    titlePdf,
    scrollTable,
    invisibleButton,
    onTableLoaded,
  };
  const OpportunityTable = TablePaginationComponent<Opportunity>(
    opportunityTableProps
  );

  return OpportunityTable;
};

export default OpportunityQuotesList;
