import { Col, Layout, Row, Typography } from 'antd';

const NotFound = () => {
  const { Content } = Layout;

  return (
    <Layout className="login">
      <Content className="login__notFound"></Content>
    </Layout>
  );
};

export default NotFound;
