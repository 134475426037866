import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import { ExportPdfProps } from '../../../../helpers/pdf';
import { CanShow } from '../../../../components';
import CallLogForm from '../detail';
import { CallLog } from '../../../../api';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const label = 'seguimiento';
const invisibleButton = true;
const titlePdf = 'Reporte de seguimiento';
const scrollTable = 800;
const route = `/callLog`;

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<CallLog>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const CallLogListDetail = (props: any) => {
  // let { id } = useParams();

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<CallLog> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Tipo',
        width: 50,
        ellipsis: true,
        dataIndex: 'callType',
        key: 'callType',
        filters: [
          { text: 'Mail', value: 'Mail' },
          { text: 'Llamada', value: 'Llamada' },
          { text: 'WhatsApp', value: 'WhatsApp' },
          { text: 'Video Llamada', value: 'Video Llamada' },
          { text: 'Visita', value: 'Visita' },
        ],
        render: (stage) => (
          <Row gutter={16} style={{ width: 10 }}>
            <Tag
              color={
                stage === 'Mail'
                  ? 'red'
                  : stage === 'Llamada'
                  ? 'blue'
                  : stage === 'WhatsApp'
                  ? 'green'
                  : stage === 'Video Llamada'
                  ? 'orange'
                  : stage === 'Visita'
                  ? 'purple'
                  : 'lightgray'
              }
              key={stage}
              style={{ marginBottom: 10 }}
            >
              {stage}
            </Tag>
          </Row>
        ),
      },
      {
        title: 'Estatus',
        width: 50,
        ellipsis: true,
        dataIndex: 'stage',
        key: 'stage',
        filters: [
          { text: 'Prospección', value: 'Prospección' },
          { text: 'Análisis', value: 'Análisis' },
          { text: 'Propuesta', value: 'Propuesta' },
          { text: 'Negociación', value: 'Negociación' },
          { text: 'Servicio Realizado', value: 'Servicio Realizado' },
          { text: 'Post-venta', value: 'Post-venta' },
        ],
        render: (stage) => (
          <Row gutter={16} style={{ width: 10 }}>
            <Tag
              color={
                stage === 'Prospección'
                  ? 'yellow'
                  : stage === 'Análisis'
                  ? 'orange'
                  : stage === 'Propuesta'
                  ? 'blue'
                  : stage === 'Negociación'
                  ? 'red'
                  : stage === 'Servicio Realizado'
                  ? 'green'
                  : stage === 'Post-venta'
                  ? 'purple'
                  : 'lightgray'
              }
              key={stage}
              style={{ marginBottom: 10 }}
            >
              {stage}
            </Tag>
          </Row>
        ),
      },

      {
        title: 'Fecha',
        width: 100,
        ellipsis: true,
        dataIndex: 'callDate',
        key: 'callDate',
        ...getColumnSearchProps('callDate'),
        render: (callDate) => (
          <>{dayjs(callDate).format('DD/MM/YYYY h:mm A')}</>
        ),
      },
      {
        title: 'Acciones',
        key: 'sup',
        // fixed: 'right',
        width: 60,
        align: 'center',
        render: (callLog) => (
          <>
            <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="circle"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', callLog.id)}
              />
            </Tooltip>
            <>
              <Tooltip title="Editar">
                <Button
                  style={{
                    marginLeft: 10,
                    backgroundColor: '#537896',
                    borderColor: '#537896',
                  }}
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => showModal('edit', callLog.id)}
                />
              </Tooltip>
              {/* <Tooltip title="Eliminar">
                <Button
                  style={{
                    marginLeft: 10,
                    backgroundColor: '#e63241',
                    borderColor: '#cf1322',
                  }}
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(callLog.id)}
                />
              </Tooltip> */}
            </>
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Seguimiento',
    sheetName: 'Seguimientos',
    tableName: 'Tabla de seguimiento',
    columnNames: [
      { label: 'Tipo', key: 'callType' },
      { label: 'Fecha', key: 'callDate' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'Tipo', key: 'callType' },
    { header: 'Fecha', key: 'callDate' },
  ];

  const callLogsTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: CallLogForm,
    pdfColumns,
    titlePdf,
    scrollTable,
    invisibleButton,
  };
  const CallLogTable = Table<CallLog>(callLogsTableProps);

  return CallLogTable;
};

export default CallLogListDetail;
