// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Upload,
  Select,
  Space,
} from 'antd';
import {
  PlusOutlined,
  UploadOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import { get, post } from '../../../api';
import { useUser } from '../../../hooks';
import type { InputRef } from 'antd';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import postFile from '../../../api/requests/postFile';
import { feedbackError } from '../../../helpers/feedback';

type LoadFromFileProps = {
  setModalIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function LoadFromFile(props: LoadFromFileProps) {
  const { setModalIsVisible } = props;
  const [form] = Form.useForm();
  const { user } = useUser();
  const [tagName, setTagName] = useState('');
  const [tagsItems, setTagsItems] = useState([]);
  const inputRef = useRef<InputRef>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading, data: tags } = useQuery<any>(['tags'], () =>
    get<any>('/tags').then((e) => e.data)
  );
  const queryClient = useQueryClient();

  const handleRefresh = () => {
    queryClient.invalidateQueries('cliente');
  };

  useEffect(() => {
    if (tags) {
      setTagsItems(tags);
    }
  }, [tags]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };
  const addItem = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setIsSubmitting(true);
    let resp = await post('/tags', { description: tagName });
    if (resp.statusCode === 200) {
      let resp2 = await get('/tags');
      if (resp2.statusCode === 200) {
        setTagsItems([...resp2.data]);
        setTagName('');
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }
    setIsSubmitting(false);
  };
  const beforeUpload = (file: any) => {
    return false;
  };
  const handleChange = (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileList(fileList);
  };

  const onFinish = async (values) => {
    setIsSubmitting(true);
    const file = fileList[0];
    if (!file) {
      feedbackError('Debe seleccionar un archivo');
      return;
    }
    const fileExt = file.name.split('.').pop()?.toLowerCase();
    if (fileExt !== 'xlsx') {
      feedbackError('El archivo debe ser de tipo .xlsx');
      return;
    }
    const bodyFile = {
      TagIds: values.tagIds,
    };
    const response = await postFile(
      `/Customers/BulkImport`,
      bodyFile,
      'File',
      file.originFileObj,
      true
    );
    if (response.statusCode === 200) {
      setModalIsVisible(false);
      handleRefresh();
    }
    setIsSubmitting(false);
  };

  return (
    <Form name="file-form" onFinish={onFinish} form={form} layout="vertical">
      <Divider />
      <Row justify={'end'}>
        <Form.Item name="filedoc">
          <Button
            shape="round"
            icon={<DownloadOutlined />}
            onClick={() =>
              window.open(
                'https://crm-planb.s3.amazonaws.com/static-files/Template+Customers.xlsx',
                '_blank'
              )
            }
          >
            Descargar plantilla
          </Button>
        </Form.Item>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name="file">
            <Upload
              //   name="template"
              beforeUpload={beforeUpload}
              //   listType="text"
              //   multiple={false}
              onChange={handleChange}
              fileList={fileList}
            >
              <Button
                type="primary"
                style={{ marginTop: 20 }}
                icon={<UploadOutlined />}
              >
                Click para cargar plantilla
              </Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label="Etiqueta:" name="tagIds">
            <Select
              placeholder="Seleccione etiqueta"
              mode="multiple"
              showSearch={false}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Input
                      placeholder="Nueva etiqueta"
                      ref={inputRef}
                      value={tagName}
                      onChange={onNameChange}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addItem}
                      disabled={isSubmitting}
                    >
                      Agregar
                    </Button>
                  </Space>
                </>
              )}
            >
              {tagsItems?.map((e: any) => (
                <Select.Option
                  key={e.id}
                  value={e.id}
                  disabled={
                    user.role === 'SALES' && e.description === 'Cliente'
                  }
                >
                  {e.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify={'end'}>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-add"
            disabled={isSubmitting}
          >
            Guardar
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
}
