// @ts-nocheck
import { Button, Col, Layout, Row, Tooltip, Typography } from 'antd';
import GreetingsTop from '../../../components/menu/greetings-top';
import OpportunityQuoteLinesList from './list';
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

export default function OpportunityQuotes() {
  const { Title } = Typography;
  let { id } = useParams();
  const navigate = useNavigate();

  return (
    <Layout>
      <GreetingsTop />
      <div className="catalog__container">
        <Row className="header-top">
          <Col xs={19} className="header-top__left">
            <Title
              level={3}
              style={{ top: 0, bottom: 0, height: '78%', margin: 'auto' }}
            >
              Lineas oportunidad - cotización {id}
            </Title>
          </Col>
          <Col xs={5}>
            <Row style={{ marginBottom: 30, justifyContent: 'right' }}>
              <Tooltip title="Atrás">
                <Button
                  style={{ color: 'gray', fontSize: 20, height: 0, width: 0 }}
                  type="link"
                  onClick={() => navigate('/opportunity-quotes')}
                >
                  <LeftOutlined />
                </Button>
              </Tooltip>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="catalog__container">
        <OpportunityQuoteLinesList />
      </div>
    </Layout>
  );
}
