import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
  Spin,
  Table,
  Modal as ModalAntd,
  Input,
  Space,
  Typography,
} from 'antd';
import {
  FileExcelFilled,
  FilePdfFilled,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
// import './table.scss';
import Modal from '../../modal';
import type {
  ColumnType,
  FilterConfirmProps,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import type { InputRef } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get, post, put, del } from '../../../../api';
import Highlighter from 'react-highlight-words';
import { ExportExcelProps, exportToExcel } from '../../../../helpers/excel';
import CanShow from '../../../can-show';
import ApiResponse from '../../../../api/models/response';
import { ExportPdfProps, exportToPdf } from '../../../../helpers/pdf';
import { useUser } from '../../../../hooks';
import postFormWithQueryString from '../../../../api/requests/postFormWithQueryString';
import CustomerTagsForm from '../../../../pages/administration/customers/tags-detail';

type TableParams<T> = {
  label: string;
  route: string;
  detailForm: React.FC<{
    type: string;
    id: number | null;
    add: (v: any) => void;
    update: (v: any) => void;
    setModalIsVisible: (v: any) => void;
  }>;
  generateTableColumns: (props: any) => ColumnType<T>[];
  generateExportExcelProps: () => ExportExcelProps;
  pdfColumns: ExportPdfProps[];
  titlePdf: string;
  scrollTable?: number;
  invisibleButton?: boolean;
  onTableLoaded?: (data: any[]) => void;
};

const TablePaginationComponent = <T extends object>(props: TableParams<T>) => {
  const { confirm } = ModalAntd;
  const { user } = useUser();
  const {
    label,
    route,
    detailForm: DetailForm,
    generateTableColumns,
    generateExportExcelProps,
    pdfColumns,
    titlePdf,
    scrollTable,
    invisibleButton,
    onTableLoaded,
  } = props;

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalCount: 0,
    hasNextPage: false,
    hasPreviusPage: false,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchColumns, setSearchColumns] = useState<string[]>([]);
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [sortColumn, setSortColumn] = useState<string | null>('id');
  const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | null>(
    'descend'
  );
  const [isLoading, setIsLoading] = useState(false);

  let getAction = React.useCallback(
    () =>
      get<T[]>(
        `${route}?page=${page}&pageSize=${pageSize}&searchColumns=${searchColumns.join(
          ','
        )}&searchTerms=${searchTerms.join(
          ','
        )}&sortColumn=${sortColumn}&sortOrder=${
          sortOrder === 'ascend'
            ? 'asc'
            : sortOrder === 'descend'
            ? 'desc'
            : 'desc'
        }`
      ).then((e) => {
        setPagination({
          page: e.page ?? 1,
          pageSize: e.pageSize ?? 10,
          totalCount: e.totalCount ?? 0,
          hasNextPage: e.hasNextPage ?? false,
          hasPreviusPage: e.hasPreviusPage ?? false,
        });
        if (onTableLoaded && e.data.length > 0) {
          onTableLoaded(e.data);
        }
        return e.data;
      }),
    [
      route,
      page,
      pageSize,
      searchColumns,
      searchTerms,
      sortColumn,
      sortOrder,
      onTableLoaded,
    ]
  );

  const query = useQuery<T[]>(
    [label, page, pageSize, searchColumns, searchTerms, sortColumn, sortOrder],
    getAction
  );

  const defaultMutationOpts = {
    onMutate: () => setIsLoading(true),
    onSuccess: async () => {
      await query.refetch();
    },
    onSettled: () => setIsLoading(false),
  };

  const addAction = async (data: any) => {
    try {
      if (route === '/BulkEmailsSent') {
        await postFormWithQueryString(`/Customers/MarketingCampaign`, data);
      } else {
        await post<T>(`${route}`, data, true);
      }
    } catch (error) {
      console.error('Error al agregar:', error);
    }
  };
  const addMutation = useMutation([label], addAction, defaultMutationOpts);

  const updateAction = async (data: any) => {
    try {
      return await put<T>(`${route}/${data.id}`, data, true);
    } catch (error) {
      console.error('Error en la operación de actualización:', error);
    }
  };
  const updateMutation = useMutation(
    [label],
    updateAction,
    defaultMutationOpts
  );

  const delAction = async (id: number) => {
    // let routeArray = route.split('?').join(',').split('=').join(',').split(',');
    // if (routeArray[0] === '/callLog' && routeArray[1] === 'quoteId') {
    //   return del(/callLog/${id}, undefined, true);
    // } else {

    try {
      return await del(`${route}/${id}`, undefined, true);
    } catch (error) {
      console.error('Error en la operación de eliminación:', error);
    }
  };

  const deleteMutation = useMutation([label], delAction, defaultMutationOpts);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [contextModal, setContextModal] = useState<ReactElement | null>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<InputRef>(null);
  const { Text } = Typography;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  type DataIndex = keyof T;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex as string);
  };

  const handleReset = (clearFilters: () => void, dataIndex: DataIndex) => {
    clearFilters();
    setSearchText('');
    setSearchColumns((prev) =>
      prev.filter((col) => col !== (dataIndex as string))
    );
    setSearchTerms((prev) => prev.filter((term) => term !== searchText));
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<T> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, dataIndex);
              handleSearch(selectedKeys as string[], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex];
      if (recordValue) {
        return (recordValue as unknown as string)
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      }
      return false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const onSuccessAction = useCallback(() => {
    setModalIsVisible(() => false);
  }, []);

  const addCallback = useCallback(
    (e: ApiResponse<T>) =>
      addMutation.mutate(e, {
        onSuccess: onSuccessAction,
      }),
    [addMutation, onSuccessAction]
  );

  const updateCallback = useCallback(
    (e: ApiResponse<T>) =>
      updateMutation.mutate(e, {
        onSuccess: onSuccessAction,
      }),
    [updateMutation, onSuccessAction]
  );

  const showModal = (type: string, id: number | null) => {
    setModalIsVisible(true);
    setTitleModal(
      `${
        type === 'add'
          ? 'Agregar'
          : type === 'edit'
          ? 'Editar'
          : type === 'tag'
          ? 'Etiquetado'
          : 'Detalle'
      }`
    );
    setContextModal(() =>
      type === 'tag' ? (
        <CustomerTagsForm
          selectedRowKeys={selectedRowKeys}
          setModalIsVisible={setModalIsVisible}
        />
      ) : (
        <DetailForm
          type={type}
          id={id}
          add={addCallback}
          update={updateCallback}
          setModalIsVisible={setModalIsVisible}
        />
      )
    );
  };

  const handleDelete = (id: number) => {
    confirm({
      title: 'Eliminar',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro de eliminar este elemento?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteMutation.mutate(id);
      },
    });
  };

  const onExcelExportClicked = useCallback(() => {
    if (query.data !== undefined) {
      const props = generateExportExcelProps();
      exportToExcel<T>(query.data, props);
    }
  }, [query.data, generateExportExcelProps]);

  const mapearArreglo = (
    arreglo1: any[],
    arreglo2: any[]
  ): { [key: string]: string | number }[] => {
    const resultado: { [key: string]: string | number }[] = [];

    for (let i = 0; i < arreglo1.length; i++) {
      const objeto1 = arreglo1[i];
      const objetoResultado: { [key: string]: string | number } = {};

      for (let j = 0; j < arreglo2.length; j++) {
        const columna = arreglo2[j];
        const { key } = columna;

        if (objeto1.hasOwnProperty(key)) {
          objetoResultado[key] = objeto1[key];
        }
      }

      resultado.push(objetoResultado);
    }

    return resultado;
  };

  const onPdfClicked = useCallback(() => {
    if (query.data !== undefined) {
      const resultadoMapeado = mapearArreglo(query.data, pdfColumns);
      exportToPdf(resultadoMapeado, pdfColumns, titlePdf);
    }
  }, [query.data, pdfColumns, titlePdf]);

  const modalMemo = useMemo(
    () => (
      <Modal
        title={titleModal}
        isVisible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    ),
    [contextModal, modalIsVisible, titleModal]
  );

  const columns = useMemo(() => {
    const tableColumns = generateTableColumns({
      getColumnSearchProps,
      showModal,
      handleDelete,
    });
    return tableColumns;
  }, [generateTableColumns, getColumnSearchProps, showModal, handleDelete]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: any
  ) => {
    setPage(pagination.current || 1);
    setPageSize(pagination.pageSize || 10);

    if (Array.isArray(sorter)) {
      if (sorter.length > 0) {
        setSortColumn(sorter[0].field as string);
        setSortOrder(sorter[0].order || null);
      } else {
        setSortColumn(null);
        setSortOrder(null);
      }
    } else {
      if (sorter.field) {
        setSortColumn(sorter.field as string);
        setSortOrder(sorter.order || null);
      } else {
        setSortColumn(null);
        setSortOrder(null);
      }
    }

    const searchCols: string[] = [];
    const searchTrms: string[] = [];

    Object.entries(filters).forEach(([column, terms]) => {
      if (terms) {
        searchCols.push(column);
        searchTrms.push(...(terms as string[]));
      }
    });

    setSearchColumns(searchCols);
    setSearchTerms(searchTrms);
  };

  return (
    <Spin spinning={query.isLoading} tip={<p>Cargando...</p>}>
      <>
        {invisibleButton !== true && (
          <>
            <Row
              justify={label === 'cliente' ? 'space-between' : 'end'}
              style={{ marginBottom: '20px' }}
            >
              {label === 'cliente' && (
                <Button
                  disabled={selectedRowKeys.length <= 0}
                  onClick={() => showModal('tag', null)}
                >
                  Etiquetar
                </Button>
              )}

              <Button
                className="btn-add"
                icon={<PlusCircleOutlined />}
                onClick={() => showModal('add', null)}
              >
                {/* {'Agregar ' + label} */}
                Agregar
              </Button>
            </Row>
          </>
        )}
      </>
      <Spin spinning={isLoading}>
        <>
          {query.error === null || !query.isFetching ? (
            <Table<T>
              rowSelection={label === 'cliente' ? rowSelection : undefined}
              columns={columns}
              dataSource={query.data}
              rowKey="id"
              pagination={{
                current: page,
                pageSize: pageSize,
                total: pagination?.totalCount,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
              }}
              onChange={handleTableChange}
              scroll={{ x: scrollTable ? scrollTable : 1500 }}
            />
          ) : null}
        </>
      </Spin>
      {invisibleButton !== true && label !== 'actividad' && (
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              icon={<FilePdfFilled style={{ fontSize: 16 }} />}
              className="btn-download"
              onClick={onPdfClicked}
              style={{ width: 150 }}
              disabled={
                query.data?.length === 0 ||
                query.data === undefined ||
                query.data === null
              }
            >
              Descargar Pdf
            </Button>
            <Button
              icon={<FileExcelFilled style={{ fontSize: 18 }} />}
              className="btn-download"
              onClick={onExcelExportClicked}
              style={{ width: 160 }}
              disabled={
                query.data?.length === 0 ||
                query.data === undefined ||
                query.data === null
              }
            >
              Descargar Excel
            </Button>
          </Col>
        </Row>
      )}

      {modalMemo}
    </Spin>
  );
};

export default TablePaginationComponent;
