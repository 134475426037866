import { Button, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import { ExportPdfProps } from '../../../../helpers/pdf';
import { CanShow } from '../../../../components';
import OpportunityForm from '../detail';
import { Opportunity } from '../../../../api';
import { useParams } from 'react-router-dom';
import { formatMoney } from '../../../../utils/general';

const label = 'linea';
const invisibleButton = false;
const titlePdf = 'Reporte de lineas oportunidad - cotizacion';
const scrollTable = 1500;
type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Opportunity>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const OpportunityQuoteLinesList = (props: any) => {
  let { id } = useParams();
  let { idQuote } = props;
  const route = `/quotes/${id ? id : idQuote}/lines`;
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Opportunity> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Parte',
        width: 150,
        ellipsis: true,
        dataIndex: 'partDescription',
        key: 'partDescription',
        ...getColumnSearchProps('partDescription'),
      },
      {
        title: 'Precio Unitario',
        width: 100,
        ellipsis: true,
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        ...getColumnSearchProps('unitPrice'),
        render: (unitPrice) => <Text>${formatMoney(unitPrice)}</Text>,
      },
      {
        title: 'Cantidad',
        width: 100,
        ellipsis: true,
        dataIndex: 'quantity',
        key: 'quantity',
        ...getColumnSearchProps('quantity'),
      },
      {
        title: 'Precio Bruto',
        width: 100,
        ellipsis: true,
        dataIndex: 'grossPrice',
        key: 'grossPrice',
        ...getColumnSearchProps('grossPrice'),
        render: (grossPrice) => <Text>${formatMoney(grossPrice)}</Text>,
      },
      {
        title: 'Precio Neto',
        width: 100,
        ellipsis: true,
        dataIndex: 'netPrice',
        key: 'netPrice',
        ...getColumnSearchProps('netPrice'),
        render: (netPrice) => <Text>${formatMoney(netPrice)}</Text>,
      },
      {
        title: 'Impuestos',
        width: 100,
        ellipsis: true,
        dataIndex: 'taxes',
        key: 'taxes',
        ...getColumnSearchProps('taxes'),
        render: (taxes) => <Text>${formatMoney(taxes)}</Text>,
      },
      {
        title: 'Total',
        width: 100,
        ellipsis: true,
        dataIndex: 'total',
        key: 'total',
        ...getColumnSearchProps('total'),
        render: (total) => <Text>${formatMoney(total)}</Text>,
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 80,
        align: 'center',
        render: (opportunity) => (
          <>
            <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="circle"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', opportunity.id)}
              />
            </Tooltip>
            <>
              <Tooltip title="Editar">
                <Button
                  style={{
                    marginLeft: 10,
                    backgroundColor: '#537896',
                    borderColor: '#537896',
                  }}
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => showModal('edit', opportunity.id)}
                />
              </Tooltip>
              <Tooltip title="Eliminar">
                <Button
                  style={{
                    marginLeft: 10,
                    backgroundColor: '#e63241',
                    borderColor: '#cf1322',
                  }}
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(opportunity.id)}
                />
              </Tooltip>
            </>
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Lineas_Oportunidades - Cotizaciones',
    sheetName: 'Lineas Oportunidades - cotizaciones',
    tableName: 'Tabla de lineas de oportunidades - cotizaciones',
    columnNames: [
      { label: 'ID linea', key: 'id' },
      { label: 'Parte', key: 'partId' },
      { label: 'Cantidad', key: 'quantity' },
      { label: 'Impuestos', key: 'taxes' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'ID linea', key: 'id' },
    { header: 'Parte', key: 'partId' },
    { header: 'Cantidad', key: 'quantity' },
    { header: 'Impuestos', key: 'taxes' },
  ];
  const opportunityLinesTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: OpportunityForm,
    pdfColumns,
    titlePdf,
    scrollTable,
    invisibleButton,
  };
  const OpportunityLinesTable = Table<Opportunity>(opportunityLinesTableProps);

  return OpportunityLinesTable;
};

export default OpportunityQuoteLinesList;
