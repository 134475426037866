import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ConfigProvider, theme } from 'antd';
import es_ES from 'antd/lib/locale/es_ES';
import 'moment/locale/es-mx';
import {
  UserProvider,
  AuthProvider,
  SettingsProvider,
  // EpicorCustomerProvider,
} from './providers';
import { queryClient } from './config';
import router from './router';

import './App.css';

function App() {
  return (
    <div className="app">
      <ConfigProvider
        locale={es_ES}
        theme={{
          components: {
            Button: {
              colorPrimary: '#7b96ac',
              algorithm: true, // Enable algorithm
            },
            Input: {
              colorPrimary: '#7b96ac',
              algorithm: true, // Enable algorithm
            },
            Table: {
              colorPrimary: '#7b96ac',
              colorLink: '#7b96ac',
            },
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <UserProvider>
              <SettingsProvider>
                <RouterProvider router={router} />
              </SettingsProvider>
            </UserProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
