// @ts-nocheck
import { Button, Col, DatePicker, Divider, Form, Input, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CreateOpportunity, Opportunity, get } from '../../../../api';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

type OpportunityFormProps = {
  type: string;
  id?: number | null;
  add: (v: CreateOpportunity) => void;
  update: (v: Opportunity) => void;
};

const OpportunityActivityForm: React.FC<OpportunityFormProps> = (props) => {
  const { type, id, add, update } = props;
  const [formDetail] = Form.useForm();
  let { id: quoteId } = useParams();

  //ENDPOINTS
  const { isLoading: isLoadingQuote, data: activity } = useQuery<Opportunity>(
    ['/quotelines/' + id],
    () =>
      id
        ? get<Opportunity>('/quotes/' + quoteId + '/activities/' + id).then(
            (e) => e.data
          )
        : null
  );

  useEffect(() => {
    if (activity && type !== 'add' && !isLoadingQuote) {
      formDetail.setFieldsValue({
        description: activity?.description,
        eta: dayjs(activity?.eta, 'YYYY/MM/DD'),
      });
    }
  }, [formDetail, activity, type, isLoadingQuote]);

  const addOpportunityLineFunction = async (values: any) => {
    if (type === 'add') {
      // AGREGAR ACTIVIDAD
      let opportunityRequest: CreateOpportunityLine = {
        description: values.description,
        eta: values.eta,
        quoteId: quoteId,
      };
      add(opportunityRequest);
    } else {
      // EDITAR ACTIVIDAD
      values['id'] = id;
      update(values);
    }
  };
  return (
    <Spin spinning={isLoadingQuote && type !== 'add'} tip={<p>Cargando...</p>}>
      <Form
        name="add-promo"
        layout="vertical"
        form={formDetail}
        onFinish={addOpportunityLineFunction}
      >
        <Divider />
        <Col xs={24} md={24}>
          <Row gutter={16}>
            <Col xs={12}>
              <Form.Item
                label="Descripción:"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese descripción',
                  },
                ]}
              >
                <Input
                  placeholder="Ingresa información"
                  disabled={type === 'watch'}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="ETA:"
                name="eta"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese fecha',
                  },
                ]}
              >
                <Input
                  placeholder="Ingresa información"
                  disabled={type === 'watch'}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Row justify="end">
          <Form.Item>
            <Button
              htmlType="submit"
              className="btn-add"
              disabled={type === 'watch' || isLoadingQuote}
            >
              Guardar
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  );
};

export default OpportunityActivityForm;
