import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Customer, get } from '../../../../api';
import { ExportExcelProps } from '../../../../helpers/excel';
import CustomerForm from '../detail';
import { ExportPdfProps } from '../../../../helpers/pdf';
import { useNavigate } from 'react-router-dom';
import TablePaginationComponent from '../../../../components/general/table/table-pagination/tablepagination';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import moment from 'moment';

const label = 'cliente';
const route = '/customers';
const titlePdf = 'Reporte de clientes';
const scrollTable = 2000;

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Customer>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const CustomersList = () => {
  const { Text } = Typography;
  let navigate = useNavigate();
  const [tagsFiltered, setTagsFiltered] = useState([]);
  const [scoresFiltered, setScoresFiltered] = useState([]);

  const { isLoading: isloadingtags, data: tags } = useQuery<any>(['tags'], () =>
    get<any>('/tags').then((e) => e.data)
  );
  const { isLoading: isloadingScores, data: customerScores } = useQuery<any>(
    ['customerScores'],
    () => get<any>('/customerScores').then((e) => e.data)
  );
  useEffect(() => {
    const transformedTags = tags?.map(
      (tag: { id: number; description: string }) => ({
        text: tag.description,
        value: tag.description,
      })
    );
    setTagsFiltered(transformedTags);
  }, [tags]);

  useEffect(() => {
    const transformedScores = customerScores?.map(
      (tag: { id: number; description: string }) => ({
        text: tag.description,
        value: tag.description,
      })
    );
    setScoresFiltered(transformedScores);
  }, [customerScores]);
  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Customer> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Nombre',
        width: 150,
        sorter: true,
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
        render: (name) => (
          <>
            {name && (
              <Text>{name.charAt(0).toUpperCase() + name.slice(1)}</Text>
            )}
          </>
        ),
      },
      {
        title: 'Teléfono',
        width: 120,
        sorter: true,
        dataIndex: 'phone',
        key: 'phone',
        ...getColumnSearchProps('phone'),
        render: (phone, record) => {
          const phoneWithExtension = record.phoneExtension
            ? `${phone} ${record.phoneExtension}`
            : phone;

          return (
            <Text>
              {phoneWithExtension.replace(
                /(\d{3})(\d{3})(\d{4})(?: *x(\d+))?/,
                (
                  _: string,
                  p1: string,
                  p2: string,
                  p3: string,
                  p4?: string
                ) => {
                  return p4 ? `${p1}-${p2}-${p3} x${p4}` : `${p1}-${p2}-${p3}`;
                }
              )}
            </Text>
          );
        },
      },
      {
        title: 'Correo electrónico',
        width: 200,
        sorter: true,
        dataIndex: 'email',
        key: 'email',
        ...getColumnSearchProps('email'),
        render: (email) => <Text>{email?.toLowerCase()}</Text>,
      },
      {
        title: 'Estado',
        width: 90,
        sorter: true,
        dataIndex: 'state',
        key: 'state',
        ...getColumnSearchProps('state'),
      },
      {
        title: 'Tipo',
        width: 60,
        // sorter: true,
        dataIndex: 'typeDescription',
        key: 'typeDescription',
        filters: [
          { text: 'Prospecto', value: 'Prospecto' },
          { text: 'Lead', value: 'Lead' },
          { text: 'Cliente', value: 'Cliente' },
        ],
        onFilter: (value, record) => record.typeDescription === value,
        render: (typeDescription) => (
          <Row gutter={16} style={{ width: 10 }}>
            <Tag
              color={
                typeDescription === 'Prospecto'
                  ? 'yellow'
                  : typeDescription === 'Lead'
                  ? 'blue'
                  : typeDescription === 'Cliente'
                  ? 'green'
                  : 'red'
              }
              key={typeDescription}
              style={{ marginBottom: 10 }}
            >
              {typeDescription}
            </Tag>
          </Row>
        ),
      },
      {
        title: 'Calificación',
        width: 90,
        dataIndex: 'customerScore',
        key: 'customerScore',
        align: 'center',
        filters: scoresFiltered,
        onFilter: (value, record) =>
          record.customerScore?.description === value,
        render: (customerScore) => (
          <>
            {customerScore !== null && (
              <Tag
                color={customerScore?.color || 'blue'}
                key={customerScore.id}
              >
                {customerScore.description}
              </Tag>
            )}
          </>
        ),
      },
      {
        title: 'Etiqueta',
        width: 120,
        sorter: true,
        dataIndex: 'tags',
        key: 'tags',
        filters: tagsFiltered,
        onFilter: (value, record) => {
          return record.tags.some(
            (tag: { description: string }) => tag.description === value
          );
        },
        render: (tags) => (
          <Row gutter={16} style={{ width: '100%' }}>
            {tags.map(
              (tag: { id: number; description: string; color: string }) => (
                <Tag
                  color={tag.color}
                  key={tag.id}
                  style={{ marginBottom: 10 }}
                >
                  {tag.description}
                </Tag>
              )
            )}
          </Row>
        ),
      },
      {
        title: 'Agente',
        width: 120,
        sorter: true,
        dataIndex: 'agent',
        key: 'agent',
        ...getColumnSearchProps('agent'),
      },
      {
        title: 'Sector',
        width: 90,
        sorter: true,
        dataIndex: 'sector',
        key: 'sector',
        ...getColumnSearchProps('sector'),
      },
      {
        title: 'Último seguimiento',
        width: 100,
        key: 'callLogLastDate',
        sorter: true,
        dataIndex: 'callLogLastDate',
        render: (callLogLastDate) => (
          <>
            {callLogLastDate !== null
              ? moment(callLogLastDate).format('DD/MM/YYYY')
              : ''}
          </>
        ),
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 90,
        align: 'center',
        render: (customer) => (
          <>
            <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="circle"
                size="small"
                icon={<EyeOutlined />}
                // onClick={() => showModal('watch', customer.id)}
                onClick={() => navigate(`/customer/detail/${customer.id}`)}
              />
            </Tooltip>
            {/* <CanShow roles={['ADMIN', 'MANAGEMENT']}> */}
            {/* <> */}
            <Tooltip title="Editar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#537896',
                  borderColor: '#537896',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<EditOutlined />}
                onClick={() => showModal('edit', customer.id)}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#e63241',
                  borderColor: '#cf1322',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(customer.id)}
              />
            </Tooltip>
            {/* </> */}
            {/* </CanShow> */}
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Clientes',
    sheetName: 'Clientes',
    tableName: 'Tabla de clientes',
    columnNames: [
      {
        label: 'ID',
        key: 'id',
      },
      {
        label: 'Nombre',
        key: 'name',
        format: (val) => val.charAt(0).toUpperCase() + val.slice(1),
      },
      {
        label: 'Teléfono',
        key: 'phone',
        format: (val) => val.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
      },
      {
        label: 'Tipo',
        key: 'typeDescription',
      },
      {
        label: 'Ciudad',
        key: 'city',
      },
      {
        label: 'Estado',
        key: 'state',
      },
      {
        label: 'Territorio',
        key: 'territory',
      },
      {
        label: 'Correo electrónico',
        key: 'email',
        format: (val) => val.toLowerCase(),
      },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    {
      header: 'ID',
      key: 'id',
    },
    {
      header: 'Nombre',
      key: 'name',
      format: (val) => val.charAt(0).toUpperCase() + val.slice(1),
    },
    {
      header: 'Teléfono',
      key: 'phone',
      format: (val) => val.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
    },
    {
      header: 'Tipo',
      key: 'typeDescription',
    },
    {
      header: 'Ciudad',
      key: 'city',
    },
    {
      header: 'Estado',
      key: 'state',
    },
    {
      header: 'Territorio',
      key: 'territory',
    },
    {
      header: 'Correo electrónico',
      key: 'email',
      format: (val) => val.toLowerCase(),
    },
  ];

  const customerTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: CustomerForm,
    pdfColumns,
    titlePdf,
    scrollTable,
  };
  const CustomerTable = TablePaginationComponent<Customer>(customerTableProps);

  return CustomerTable;
};

export default CustomersList;
