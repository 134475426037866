// @ts-nocheck
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  CallLog,
  CreateCallLog,
  Opportunity,
  User,
  get,
  post,
  put,
} from '../../../../api';
import { useParams } from 'react-router-dom';
import { Contact } from '../../../../api/models/contacts';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useUser } from '../../../../hooks';
import { queryClient } from '../../../../config';
let utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

type CallLogFormProps = {
  type: string;
  id?: number | null;
  add: (v: CreateOpportunity) => void;
  update: (v: CallLog) => void;
};

const CallLogForm: React.FC<CallLogFormProps> = (props) => {
  const { type, id, setModalIsVisible, add, update } = props;
  const [formDetail] = Form.useForm();
  const [contactSelected, setContactSelected] = useState(null);
  const [customerSelected, setCustomerSelected] = useState(null);
  let { type: typePage, id: idDetail } = useParams();
  const { user } = useUser();

  const callTypeOptions = [
    { label: 'Mail', value: 'Mail' },
    { label: 'Llamada', value: 'Llamada' },
    { label: 'WhatsApp', value: 'WhatsApp' },
    { label: 'Video Llamada', value: 'Video Llamada' },
    { label: 'Visita', value: 'Visita' },
  ];
  const stageOptions = [
    { label: 'Prospección', value: 'Prospección' },
    { label: 'Análisis', value: 'Análisis' },
    { label: 'Propuesta', value: 'Propuesta' },
    { label: 'Negociación', value: 'Negociación' },
    { label: 'Servicio Realizado', value: 'Servicio Realizado' },
    { label: 'Post-venta', value: 'Post-venta' },
  ];
  //ENDPOINTS
  const { isLoading: isLoadingQuote, data: quoteDetail } =
    useQuery<Opportunity>(
      ['/quotes/' + idDetail],
      () => get<Opportunity>(`/quotes/${idDetail}`).then((e) => e.data),
      {
        enabled: idDetail !== undefined,
      }
    );
  const { isLoading: isLoadingCall, data: callLog } = useQuery<CallLog>(
    ['/callLog/' + id],
    () => (id ? get<CallLog>('/callLog/' + id).then((e) => e.data) : null)
  );
  const { isLoading: isLoadingCustomers, data: customers } = useQuery<
    Customer[]
  >(['cliente'], () => get<Customer[]>('/customers').then((e) => e.data));

  const { isLoading: isLoadingUsers, data: users } = useQuery<User[]>(
    ['users'],
    () => get<User[]>('/users').then((e) => e.data)
  );

  const { isLoading: isLoadingCContacts, data: contacts } = useQuery<Contact[]>(
    ['contactsCustomer'],
    () =>
      get<Customer[]>(
        `/customers/${
          typePage === 'quote'
            ? quoteDetail?.customerId
            : typePage === 'detail'
            ? idDetail
            : customerSelected
        }/contacts`
      ).then((e) => e.data),
    {
      enabled: customerSelected !== null || idDetail !== undefined,
    }
  );

  useEffect(() => {
    if (customerSelected !== null) {
      queryClient.invalidateQueries(['contactsCustomer', customerSelected]);
    }
  }, [customerSelected]);

  useEffect(() => {
    if (callLog && type !== 'add' && !isLoadingCall) {
      let tmp = dayjs(callLog?.callDate);
      formDetail.setFieldsValue({
        comments: callLog?.comments,
        missed: callLog?.missed,
        duration: callLog?.duration,
        customerId: callLog?.customerId,
        quoteId: callLog?.quoteId,
        callDate: tmp.utc('YYYY/MM/DD HH:mm'),
        callType: callLog?.callType,
        contactId: callLog?.contactId,
        createdByUserId: callLog?.createdByUserId,
        stage: callLog?.stage,
      });
    }
  }, [formDetail, callLog, type, isLoadingCall]);

  useEffect(() => {
    if (type === 'add' && user) {
      const fecha = dayjs();
      formDetail.setFieldsValue({
        customerId:
          typePage === 'quote'
            ? quoteDetail?.customerId
            : typePage === 'detail'
            ? parseInt(idDetail)
            : null,
        callDate: dayjs(fecha, 'YYYY/MM/DD HH:mm'),
        createdByUserId: user.id,
      });
    }
  }, [formDetail, quoteDetail, type, user, idDetail, typePage]);

  const handleRefresh = () => {
    if (typePage === 'quote') {
      queryClient.invalidateQueries(
        `/callLog?searchColumns=quoteId&searchTerms=${idDetail}`
      );
    } else {
      queryClient.invalidateQueries(
        `/callLog?searchColumns=customerId&searchTerms=${idDetail}`
      );
    }
  };

  const addCallLogFunction = async (values: any) => {
    if (type === 'add') {
      // AGREGAR LLAMADA
      let callLogRequest: CreateCallLog = {
        comments: values.comments,
        customerId: values.customerId,
        quoteId: typePage === 'quote' ? idDetail : undefined,
        callDate: values.callDate,
        callType: values.callType,
        contactId: contactSelected,
        stage: values.stage,
      };
      if (idDetail) {
        const result = await post('/callLog', callLogRequest, true);
        if (result?.statusCode === 200) {
          setModalIsVisible(false);
          handleRefresh();
        }
      } else {
        add(callLogRequest);
      }
    } else {
      // EDITAR LLAMADA
      values['id'] = id;
      update(values);
    }
  };

  return (
    <Spin spinning={isLoadingCall && type !== 'add'} tip={<p>Cargando...</p>}>
      <Form
        name="add-promo"
        layout="vertical"
        form={formDetail}
        onFinish={addCallLogFunction}
      >
        <Divider />
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="Tipo de seguimiento:" name="callType">
              <Select
                placeholder="Selecciona una opción"
                disabled={type === 'watch'}
                allowClear
                showSearch
                options={callTypeOptions}
                optionFilterProp="children"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Etapa:"
              name="stage"
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione etapa',
                },
              ]}
            >
              <Select
                placeholder="Selecciona una opción"
                disabled={type === 'watch'}
                allowClear
                showSearch
                options={stageOptions}
                optionFilterProp="children"
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} md={5}>
            <Form.Item
              label="Llamada perdida:"
              name="missed"
              valuePropName="checked"
            >
              <Checkbox disabled={type === 'watch'} />
            </Form.Item>
          </Col> */}
          <Col xs={24} md={12}>
            {customers && (
              <Form.Item label="Cliente:" name="customerId">
                <Select
                  placeholder="Selecciona una opción"
                  disabled={typePage !== undefined}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={(e) => setCustomerSelected(e)}
                >
                  {customers.map((e: any) => (
                    <Select.Option key={e.id} value={e.id}>
                      {e.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Fecha de seguimiento:"
              name="callDate"
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione fecha',
                },
              ]}
            >
              <DatePicker
                disabled
                format={'DD/MM/YYYY HH:mm'}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            {contacts && (
              <Form.Item label="Contacto:" name="contactId">
                <Select
                  placeholder="Selecciona una opción"
                  disabled={type === 'watch'}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={(val) => setContactSelected(val)}
                >
                  {contacts.map((e: any) => (
                    <Select.Option key={e.id} value={e.id}>
                      {e.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col xs={24} md={12}>
            {users && (
              <Form.Item label="Dueño:" name="createdByUserId">
                <Select
                  placeholder="Selecciona una opción"
                  disabled
                  allowClear
                  showSearch
                  optionFilterProp="children"
                >
                  {users.map((e: any) => (
                    <Select.Option key={e.id} value={e.id}>
                      {e.firstName + ' ' + e.lastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          {/* <Col xs={24} md={12}>
            <Form.Item
              label="Duración:"
              name="duration"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese duración',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                disabled={type === 'watch'}
                placeholder="Minutos"
                // defaultValue={0}
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col> */}
          <Col xs={24}>
            <Form.Item
              label="Comentarios:"
              name="comments"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese comentario',
                },
              ]}
            >
              <TextArea
                placeholder="Ingresa información"
                disabled={type === 'watch'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Form.Item>
            <Button
              htmlType="submit"
              className="btn-add"
              disabled={type === 'watch' || isLoadingCall}
            >
              Guardar
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  );
};

export default CallLogForm;
