import { DashboardLayout } from '../layouts';
import { Home, Login, PasswordRecovery, ResetPassword } from '../pages';
import Administration from '../pages/administration';
import Catalogs from '../pages/catalogs';
import Users from '../pages/administration/users';
import Profile from '../pages/home/profile';
import { CanShow } from '../components';
import UnAuthorized from '../pages/home/unauthorized';
import Customers from '../pages/administration/customers';
import CallLogs from '../pages/home/call-log';
import OpportunityQuotes from '../pages/home/opportunity-quotes';
import OpportunityQuoteDetail from '../pages/home/opportunity-quotes/header';
import OpportunityQuoteLines from '../pages/home/opportunity-quote-lines';
import OpportunityQuotePdf from '../pages/home/opportunity-quotes/pdf';
import Contacts from '../pages/administration/contacts';
import Mailing from '../pages/administration/marketing-campaign';
import Tasks from '../pages/administration/tasks';
import BlogPosts from '../pages/administration/blog-posts';
import TaskDetail from '../pages/administration/tasks/page-detail';
import Services from '../pages/catalogs/services';
import Taxes from '../pages/catalogs/taxes';
import Clauses from '../pages/catalogs/clauses';
import ErrorBoundary from '../pages/404';
import Notes from '../pages/catalogs/notes';
import Tags from '../pages/catalogs/tags';
import Scores from '../pages/catalogs/scores';
import CustomerDetail from '../pages/administration/customers/page-detail';

type Route = {
  path: string;
  element: React.ReactNode;
  roles: string[];
  children?: Route[];
  errorElement?: React.ReactNode;
};

const routes: Route[] = [
  {
    path: '/login',
    element: <Login />,
    roles: [],
  },
  {
    path: '/forgot-password',
    element: <PasswordRecovery />,
    roles: [],
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    roles: [],
  },

  {
    path: '/',
    element: <DashboardLayout />,
    roles: [],
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <Home />,
        roles: [],
      },
      {
        path: '/administration',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Administration />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/administration/users',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Users />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/administration/customers',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Customers />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/administration/contacts',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Contacts />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/administration/mailing',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Mailing />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/administration/tasks',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Tasks />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/administration/posts',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <BlogPosts />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/task/detail/:id',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT', 'SALES']}
            redirect="/unauthorized"
          >
            <TaskDetail type={'watch'} add={() => {}} update={() => {}} />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/tasks',
        element: (
          <CanShow roles={['SALES']} redirect="/unauthorized">
            <Tasks />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/customers',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT', 'SALES']}
            redirect="/unauthorized"
          >
            <Customers />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/customer/:type/:id',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT', 'SALES']}
            redirect="/unauthorized"
          >
            <CustomerDetail type={'edit'} />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/callLogs',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT', 'SALES']}
            redirect="/unauthorized"
          >
            <CallLogs />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/opportunity-quotes',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT', 'SALES']}
            redirect="/unauthorized"
          >
            <OpportunityQuotes />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/opportunity/:type/:id',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT', 'SALES']}
            redirect="/unauthorized"
          >
            <OpportunityQuoteDetail type={'edit'} update={() => '/quotes'} />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/opportunity-quote/:id/lines',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT', 'SALES']}
            redirect="/unauthorized"
          >
            <OpportunityQuoteLines />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/:quoteType/opportunity-quote/:id/pdf',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT', 'SALES']}
            redirect="/unauthorized"
          >
            <OpportunityQuotePdf />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/catalogs',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Catalogs />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/catalogs/services',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Services />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/catalogs/taxes',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Taxes />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/catalogs/clauses',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Clauses />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/catalogs/notes',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Notes />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/catalogs/tags',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Tags />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/catalogs/scores',
        element: (
          <CanShow
            roles={['ADMIN', 'DIRECTION', 'MANAGEMENT']}
            redirect="/unauthorized"
          >
            <Scores />
          </CanShow>
        ),
        roles: [],
      },
      {
        path: '/profile',
        element: <Profile />,
        roles: [],
      },
      {
        path: '/unauthorized',
        element: <UnAuthorized />,
        roles: [],
      },
    ],
  },
];

export type { Route };
export default routes;
