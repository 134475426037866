import { Row, Col, Typography, Button } from 'antd';
import ServiceIcon from '../../assets/img/png/service-icon.png';
import NotesIcon from '../../assets/img/png/notes-icon.png';
import ClausesIcon from '../../assets/img/png/clauses-icon.png';
import TaxesIcon from '../../assets/img/png/taxes-icon.png';
import TagIcon from '../../assets/img/png/tagIcon.png';
import ScoreIcon from '../../assets/img/png/scoreIcon.png';

import './catalogs.scss';
import { useNavigate } from 'react-router';

export default function CatalogsModules() {
  // const { person } = useUser();
  const catalog = [
    {
      name: 'services',
      isAdmin: true,
    },
    {
      name: 'taxes',
      isAdmin: true,
    },
    {
      name: 'clauses',
      isAdmin: true,
    },
    {
      name: 'notes',
      isAdmin: true,
    },
    {
      name: 'tags',
      isAdmin: true,
    },
    {
      name: 'scores',
      isAdmin: true,
    },
  ];

  return (
    <div className="modules">
      <Row gutter={[0, 15]}>
        {/* {[ROL_ADMIN].some((r) => person?.role.includes(r)) && ( */}
        <>
          {catalog.map((cat, index) => {
            if (cat.isAdmin) {
              return (
                <Col
                  span={12}
                  key={index}
                  className="modules__container animate__animated  animate__bounceInLeft"
                >
                  <ButtonAction type={cat.name} />
                </Col>
              );
            }
          })}
        </>
        {/* )} */}
        {catalog.map((cat, index) => {
          if (!cat.isAdmin) {
            return (
              <Col
                key={index}
                span={12}
                className="modules__container animate__animated  animate__bounceInLeft"
              >
                <ButtonAction type={cat.name} />
              </Col>
            );
          }
        })}
      </Row>
    </div>
  );
}

function ButtonAction(props: { type: string }) {
  const { type } = props;
  let urlImage = '';
  let title = '';
  if (type === 'services') {
    urlImage = ServiceIcon;
    title = 'Parte/Servicios';
  }
  if (type === 'taxes') {
    urlImage = TaxesIcon;
    title = 'Impuestos';
  }
  if (type === 'clauses') {
    urlImage = ClausesIcon;
    title = 'Cláusulas';
  }
  if (type === 'notes') {
    urlImage = NotesIcon;
    title = 'Notas';
  }
  if (type === 'tags') {
    urlImage = TagIcon;
    title = 'Etiquetas';
  }
  if (type === 'scores') {
    urlImage = ScoreIcon;
    title = 'Calificaciones';
  }
  const { Title } = Typography;
  const navigate = useNavigate();

  const handleRedirect = () => {
    // if (type === 'supplier') {
    //   navigate('/administration/suppliers');
    // } else
    if (type === 'taxes') {
      navigate('/catalogs/taxes');
    } else if (type === 'services') {
      navigate('/catalogs/services');
    } else if (type === 'clauses') {
      navigate('/catalogs/clauses');
    } else if (type === 'notes') {
      navigate('/catalogs/notes');
    } else if (type === 'tags') {
      navigate('/catalogs/tags');
    } else if (type === 'scores') {
      navigate('/catalogs/scores');
    }
  };

  return (
    <Button
      className="modules__button"
      style={{ padding: 10 }}
      onClick={() => handleRedirect()}
    >
      <img width={60} src={urlImage} />
      <Title style={{ color: 'white', marginTop: 10 }} level={5}>
        {title}
      </Title>
    </Button>
  );
}
