import { createContext, useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

import { LoginRequest, LoginResponse, post } from '../api';
import { useQueryClient } from '@tanstack/react-query';

type JWT = {
  id: number;
  email: string;
  role: string;
  iat: number; //issued at
  exp: number; //expiration
};

type AuthContextProps = {
  jwt: JWT | null;
  isLoading: boolean;
  login: (values: LoginRequest) => Promise<LoginResponse>;
  logout: () => void;
};

const loginRequest = async (values: LoginRequest) => {
  const result: LoginResponse = await post('/auth/login', values);

  if (result.statusCode === 200) {
    let accessKey = process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY || '';
    if (typeof result.data === 'string') {
      localStorage.setItem(accessKey, result.data);
    }
  }
  return result;
};

const clearStorage = () => {
  localStorage.clear();
};

const defaultProps: AuthContextProps = {
  jwt: null,
  isLoading: true,
  login: loginRequest,
  logout: clearStorage,
};

export const AuthContext = createContext(defaultProps);

type AuthProviderProps = {
  children: React.ReactNode;
};

export default function AuthProvider(props: AuthProviderProps) {
  const { children } = props;

  const [jwt, setJwt] = useState<JWT | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const queryClient = useQueryClient();

  useEffect(() => {
    let accessKey = process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY || '';
    if (accessKey) {
      let jwtRaw = localStorage.getItem(accessKey);
      if (jwtRaw !== null) {
        let jwt: JWT = jwtDecode(jwtRaw);
        setJwt(jwt);
      }
    }
    setIsLoading(false);
  }, []);

  const login = async (values: LoginRequest) => {
    setIsLoading(true);
    let res = await loginRequest(values);
    if (res.statusCode === 200) {
      let jwt: JWT = jwtDecode(res.data);
      setJwt(jwt);
    }
    setIsLoading(false);
    return res;
  };

  const logout = () => {
    setIsLoading(true);
    clearStorage();
    setJwt(null);
    setIsLoading(false);
    queryClient.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        jwt,
        isLoading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
