import { Button, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import { ExportPdfProps } from '../../../../helpers/pdf';
import { CanShow } from '../../../../components';
import { Contact } from '../../../../api/models/contacts';
import ContactForm from '../detail';

const label = 'contacto';
const route = '/contacts';
const titlePdf = 'Reporte de contactos';

type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Contact>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const ContactsList = () => {
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Contact> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Nombre',
        width: 100,
        ellipsis: true,
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
        render: (name) => (
          <>
            {name && (
              <Text>{name.charAt(0).toUpperCase() + name.slice(1)}</Text>
            )}
          </>
        ),
      },
      {
        title: 'Apellido',
        width: 100,
        ellipsis: true,
        dataIndex: 'lastName',
        key: 'lastName',
        ...getColumnSearchProps('lastName'),
        render: (lastName) => (
          <>
            {lastName && (
              <Text>
                {lastName.charAt(0).toUpperCase() + lastName.slice(1)}
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Teléfono',
        width: 100,
        ellipsis: true,
        dataIndex: 'phone',
        key: 'phone',
        ...getColumnSearchProps('phone'),
        render: (phone) => (
          <Text>{phone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}</Text>
        ),
      },
      {
        title: 'Rol',
        width: 100,
        ellipsis: true,
        dataIndex: 'rol',
        key: 'rol',
        ...getColumnSearchProps('rol'),
      },
      {
        title: 'Cliente',
        width: 100,
        ellipsis: true,
        dataIndex: 'customerName',
        key: 'customerName',
        ...getColumnSearchProps('customerName'),
      },
      {
        title: 'Correo electrónico',
        width: 150,
        dataIndex: 'email',
        key: 'email',
        ...getColumnSearchProps('email'),
        render: (email) => <Text>{email.toLowerCase()}</Text>,
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 80,
        align: 'center',
        render: (contact) => (
          <>
            <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="circle"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', contact.id)}
              />
            </Tooltip>
            <Tooltip title="Editar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#537896',
                  borderColor: '#537896',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<EditOutlined />}
                onClick={() => showModal('edit', contact.id)}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#e63241',
                  borderColor: '#cf1322',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(contact.id)}
              />
            </Tooltip>
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Contactos',
    sheetName: 'Contactos',
    tableName: 'Tabla de Contactos',
    columnNames: [
      { label: 'Nombre', key: 'name' },
      { label: 'Apellido', key: 'lastName' },
      { label: 'Teléfono', key: 'phone' },
      { label: 'Rol', key: 'rol' },
      { label: 'Cliente', key: 'customerName' },
      { label: 'Correo Electrónico', key: 'email' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'Nombre', key: 'name', width: 50 },
    { header: 'Apellido', key: 'lastName', width: 50 },
    { header: 'Teléfono', key: 'phone', width: 50 },
    { header: 'Rol', key: 'rol' },
    { header: 'Cliente', key: 'customerName' },
    { header: 'Correo Electrónico', key: 'email', width: 30 },
  ];
  const contactsTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: ContactForm,
    pdfColumns,
    titlePdf,
  };
  const ContactsTable = Table<Contact>(contactsTableProps);

  return ContactsTable;
};

export default ContactsList;
