import React from 'react';
import { Typography, Divider, Row, Col } from 'antd';
import moment from 'moment';
import './greetings-top.scss';
import { useUser } from '../../../hooks';
import 'moment/locale/es';
moment.locale('es');
const nowDate = moment().format('dddd , LL');

const GreetingsTop = () => {
  const { user } = useUser();
  const { Title } = Typography;

  return (
    <>
      <Row className="greetings-top">
        <Col md={12} xs={24} className="greetings-top__left">
          <Title level={3}>¡Hola {user?.firstName}!</Title>
        </Col>
        <Col md={12} xs={24} className="greetings-top__right">
          <p>{nowDate}</p>
        </Col>
      </Row>
      <Divider className="top-devider" />
    </>
  );
};

export default GreetingsTop;
