import moment from 'moment';
import { writeFile, utils } from 'xlsx';

// const defaultExcelFilename = 'archivo_1';
// const defaultSheetName = 'hoja_1';
// const defaultTableName = 'tabla_1';

type ExportExcelProps = {
  fileName: string;
  sheetName: string;
  tableName: string;
  columnNames: { label: string; key: string; format?: (item: any) => string }[];
};

const exportToExcel = <T>(table: T[], props: ExportExcelProps) => {
  const fileName = props.fileName; // ?? defaultExcelFilename;
  const sheetName = props.sheetName; // ?? defaultSheetName;
  const tableName = props.tableName; // ?? defaultTableName;
  const columnNames = props.columnNames; // ?? ['no_column_names'];

  const exportDate = moment().format('LLL');
  const columns = columnNames.map((c) => ({ key: c.key, format: c.format }));
  const data: string[][] = table.map((row) => {
    let toArr: string[] = [];
    let columnKeys = columns.map((c) => c.key);
    let objectKeysInColumnKeys = columns
      .filter((obj) => columnKeys.includes(obj.key))
      .sort(
        (a_obj, b_obj) =>
          columnKeys.indexOf(a_obj.key) - columnKeys.indexOf(b_obj.key)
      );
    objectKeysInColumnKeys.forEach((e) => {
      if (e.format) {
        toArr.push(e.format((row as any)[e.key]));
      } else {
        toArr.push((row as any)[e.key]);
      }
    });
    return toArr;
  });

  const sheetData: string[][] = [
    [tableName, exportDate],
    [],
    columnNames.map((c) => c.label),
    ...data,
  ];

  const workbook = utils.book_new();
  const worksheet = utils.aoa_to_sheet(sheetData);

  workbook.SheetNames.push(sheetName);
  workbook.Sheets[sheetName] = worksheet;
  writeFile(workbook, fileName + '.xlsx', { cellStyles: true });
};

export type { ExportExcelProps };
export { exportToExcel };
