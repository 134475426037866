import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
  Spin,
  Table,
  Modal as ModalAntd,
  Input,
  Space,
  Typography,
} from 'antd';
import {
  FileExcelFilled,
  FilePdfFilled,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import './table.scss';
import Modal from '../modal';
import type { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import type { InputRef } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get, post, put, del } from '../../../api';
import Highlighter from 'react-highlight-words';
import { ExportExcelProps, exportToExcel } from '../../../helpers/excel';
import CanShow from '../../can-show';
import ApiResponse from '../../../api/models/response';
import { ExportPdfProps, exportToPdf } from '../../../helpers/pdf';
import { useUser } from '../../../hooks';
import { useParams } from 'react-router-dom';
import postFormData from '../../../api/requests/postFormData';
import putFormData from '../../../api/requests/putFormData';

type TableParams = {
  label: string;
  route: string;
  detailForm: React.FC<{
    type: string;
    id: number | null;
    add: (v: any) => void;
    update: (v: any) => void;
    setModalIsVisible: (v: any) => void;
  }>;
  generateTableColumns: (props: any) => any[];
  generateExportExcelProps: () => ExportExcelProps;
  pdfColumns: ExportPdfProps[];
  titlePdf: string;
  scrollTable?: number;
  invisibleButton?: boolean;
};

const TableComponent = <T,>(props: TableParams) => {
  const { confirm } = ModalAntd;
  const { user } = useUser();
  const {
    label,
    route,
    detailForm: DetailForm,
    generateTableColumns,
    generateExportExcelProps,
    pdfColumns,
    titlePdf,
    scrollTable,
    invisibleButton,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  let { type: typePage, id: idDetail } = useParams();

  const buildEndpoint = (
    route: string,
    typePage?: string,
    idDetail?: string
  ): string => {
    const endpointMap: Record<string, string> = {
      '/callLog_detail': `/callLog?searchColumns=customerId&searchTerms=${idDetail}&SortOrder=desc`,
      '/callLog_quote': `/callLog?searchColumns=quoteId&searchTerms=${idDetail}&SortOrder=desc`,
      '/tasks_detail': `/customers/${idDetail}/tasks`,
      '/tasks_quote': `/quotes/${idDetail}/tasks`,
    };

    return endpointMap[`${route}_${typePage}`] || route;
  };

  const getAction = React.useCallback(async () => {
    // setIsLoading(true);
    try {
      let endpoint = route;

      if (idDetail && typePage) {
        endpoint = buildEndpoint(route, typePage, idDetail);
      }

      const response = await get<T[]>(endpoint);
      return response.data ?? [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    } finally {
      // setIsLoading(false);
    }
  }, [route, idDetail, typePage]);

  const query = useQuery<T[]>([label], getAction);

  const defaultMutationOpts = {
    onMutate: () => setIsLoading(true),
    onSuccess: async () => {
      await query.refetch();
    },
    onSettled: () => setIsLoading(false),
  };

  const addAction = async (data: any) => {
    try {
      if (route === '/posts') {
        return await postFormData<T>('/posts', data, true);
      } else {
        return await post<T>(`${route}`, data, true);
      }
    } catch (error) {
      console.error('Error al agregar:', error);
    }
  };
  const addMutation = useMutation([label], addAction, defaultMutationOpts);

  const updateAction = async (data: any) => {
    console.log(route, 'route');
    console.log(data, 'data');

    try {
      // let routeArray = route.split('?').join(',').split('=').join(',').split(',');
      let routeArray = route.split(/[?=]/);

      if (
        routeArray[0] === '/callLog' &&
        (routeArray[1] === 'quoteId' || routeArray[1] === 'customerId')
      ) {
        return await put<T>(`/callLog/${data.id}`, data, true);
      }
      return await put<T>(`${route}/${data.id}`, data, true);
    } catch (error) {
      console.error('Error al editar:', error);
    }
  };

  const updateMutation = useMutation(
    [label],
    updateAction,
    defaultMutationOpts
  );

  const delAction = async (id: number) => {
    try {
      // let routeArray = route.split('?').join(',').split('=').join(',').split(',');
      let routeArray = route.split(/[?=]/);

      if (
        routeArray[0] === '/callLog' &&
        (routeArray[1] === 'quoteId' || routeArray[1] === 'customerId')
      ) {
        return await del(`/callLog/${id}`, undefined, true);
      } else {
        return await del(`${route}/${id}`, undefined, true);
      }
    } catch (error) {
      console.error('Error al eliminar:', error);
    }
  };

  const deleteMutation = useMutation([label], delAction, defaultMutationOpts);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [contextModal, setContextModal] = useState<ReactElement | null>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const { Text } = Typography;

  type DataIndex = keyof T;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex as string);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<T> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys as string[], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#7b96ac' : undefined }} />
    ),
    onFilter: (value, record) =>
      (record[dataIndex] + '')
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const onSuccessAction = useCallback(() => {
    setModalIsVisible(() => false);
  }, []);

  const addCallback = useCallback(
    (e: ApiResponse<T>) =>
      addMutation.mutate(e, {
        onSuccess: onSuccessAction,
      }),
    [addMutation, onSuccessAction]
  );

  const updateCallback = useCallback(
    (e: ApiResponse<T>) =>
      updateMutation.mutate(e, {
        onSuccess: onSuccessAction,
      }),
    [updateMutation, onSuccessAction]
  );

  const showModal = (type: string, id: number | null) => {
    setModalIsVisible(true);
    setTitleModal(
      `${type === 'add' ? 'Agregar' : type === 'edit' ? 'Editar' : 'Detalle'}`
    );
    if (type === 'repse') {
      setTitleModal(`Verificar REPSE`);
    }
    setContextModal(() => (
      <DetailForm
        type={type}
        id={id}
        add={addCallback}
        update={updateCallback}
        setModalIsVisible={setModalIsVisible}
      />
    ));
  };
  const handleDelete = (id: number) => {
    confirm({
      title: `Eliminar ${label}`,
      icon: <ExclamationCircleOutlined />,
      content: `¿Estás seguro que deseas borrar el ${label} seleccionado?, no podrá recuperarlo después.`,
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteRow(id);
      },
      onCancel() {},
    });
  };
  const deleteRow = (id: number) => {
    deleteMutation.mutate(id);
  };
  const columns = generateTableColumns({
    getColumnSearchProps,
    showModal,
    handleDelete,
  });
  const onExcelExportClicked = React.useCallback(() => {
    if (query.data !== undefined) {
      const props = generateExportExcelProps();
      exportToExcel<T>(query.data, props);
    }
  }, [query.data, generateExportExcelProps]);

  function mapearArreglo(
    arreglo1: any[],
    arreglo2: any[]
  ): { [key: string]: string | number }[] {
    const resultado: { [key: string]: string | number }[] = [];

    for (let i = 0; i < arreglo1.length; i++) {
      const objeto1 = arreglo1[i];
      const objetoResultado: { [key: string]: string | number } = {};

      for (let j = 0; j < arreglo2.length; j++) {
        const columna = arreglo2[j];
        const { key } = columna;

        if (objeto1.hasOwnProperty(key)) {
          objetoResultado[key] = objeto1[key];
        }
      }

      resultado.push(objetoResultado);
    }

    return resultado;
  }

  const onPdfClicked = React.useCallback(() => {
    if (query.data !== undefined) {
      const resultadoMapeado = mapearArreglo(query.data, pdfColumns);
      exportToPdf(resultadoMapeado, pdfColumns, titlePdf);
    }
  }, [query.data, pdfColumns, titlePdf]);
  const modalMemo = useMemo(
    () => (
      <Modal
        title={titleModal}
        isVisible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    ),
    [contextModal, modalIsVisible, titleModal]
  );

  return (
    <Spin spinning={query.isLoading} tip={<p>Cargando...</p>}>
      {/* Agregar */}
      {/* <CanShow roles={['ADMIN', 'DIRECTION', 'MANAGEMENT', 'SALES']}> */}

      {invisibleButton !== true && (
        <Row justify="end" style={{ marginBottom: '20px' }}>
          <Button
            className="btn-add"
            icon={<PlusCircleOutlined />}
            onClick={() => showModal('add', null)}
          >
            {/* {'Agregar ' + label} */}
            Agregar
          </Button>
        </Row>
      )}

      {/* Tabla */}
      <Spin spinning={isLoading}>
        <>
          {query.error === null || !query.isFetching ? (
            <Table
              columns={columns}
              size={label === 'oportunidad-cliente' ? 'small' : undefined}
              dataSource={query.data as any[]}
              scroll={{ x: scrollTable ? scrollTable : 1500 }}
              pagination={
                label === 'seguimiento' ||
                label === 'oportunidad-cliente' ||
                label === 'tarea'
                  ? { defaultPageSize: 5 }
                  : label === 'linea'
                  ? { defaultPageSize: 3 }
                  : undefined
              }
            />
          ) : null}
        </>
      </Spin>

      {/* Botones de Export */}
      {invisibleButton !== true &&
        label !== 'actividad' &&
        label !== 'linea' && (
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                icon={<FilePdfFilled style={{ fontSize: 16 }} />}
                className="btn-download"
                onClick={onPdfClicked}
                style={{ width: 150 }}
                disabled={
                  query.data?.length === 0 ||
                  query.data === undefined ||
                  query.data === null
                }
              >
                Descargar Pdf
              </Button>
              <Button
                icon={<FileExcelFilled style={{ fontSize: 18 }} />}
                className="btn-download"
                onClick={onExcelExportClicked}
                style={{ width: 160 }}
                disabled={
                  query.data?.length === 0 ||
                  query.data === undefined ||
                  query.data === null
                }
              >
                Descargar Excel
              </Button>
            </Col>
          </Row>
        )}

      {/* Modal de detalle */}
      {modalMemo}
    </Spin>
  );
};

export default TableComponent;
