export function formatMoney(number: number) {
  if (number === undefined || number === null) return '0.00';
  let priceFormat = number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'MXN',
  });

  let priceString = priceFormat.toString();
  priceString = priceString.replace('MX', '');
  return (priceFormat = priceString.replace('$', ''));
}

export function formatPhoneNumber(phoneNumberString: number) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function isMobile() {
  if (
    detectedMobile()?.includes('Windows') ||
    detectedMobile()?.includes('Mac')
  ) {
    return false;
  } else {
    return true;
  }
}

function detectedMobile() {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/Mac/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows/i)
  );
}

export function detectBrowser() {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
}

// export function userLogOut() {
//   notification['error']({
//     message: 'Usuario no autorizado.',
//   });

//   setTimeout(() => {
//     logout();
//     window.location.reload();
//   }, 1200);
// }

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export function messageError() {
  return 'Verifique su conexión a internet, si no se resuelve intente más tarde.';
}
export function messageErrorGeneral() {
  return 'Ocurrió un error, inténtelo  más tarde y si el problema persiste comuníquese con soporte.';
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function nameMonth(noMonth: number) {
  switch (noMonth) {
    case 1:
      return 'Enero';
    case 2:
      return 'Febrero';
    case 3:
      return 'Marzo';
    case 4:
      return 'Abril';
    case 5:
      return 'Mayo';
    case 6:
      return 'Junio';
    case 7:
      return 'Julio';
    case 8:
      return 'Agosto';
    case 9:
      return 'Septiembre';
    case 10:
      return 'Octubre';
    case 11:
      return 'Noviembre';
    case 12:
      return 'Diciembre';
    default:
      return '';
  }
}
