import { Button, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import { ExportPdfProps } from '../../../../helpers/pdf';
import { CanShow } from '../../../../components';
import OpportunityForm from '../detail';
import { Opportunity } from '../../../../api';
import { useParams } from 'react-router-dom';
import { formatMoney } from '../../../../utils/general';
import OpportunityActivityForm from '../detail';
import moment from 'moment';

const label = 'actividad';
const titlePdf = 'Reporte de actividades';
const scrollTable = 500;
type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Opportunity>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const OpportunityQuoteActivitiesList = (props: any) => {
  let { id } = useParams();
  let { idQuote } = props;
  const route = `/quotes/${id ? id : idQuote}/activities`;
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Opportunity> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Descripción',
        width: 150,
        ellipsis: true,
        dataIndex: 'description',
        key: 'description',
        ...getColumnSearchProps('description'),
      },
      {
        title: 'ETA',
        width: 100,
        key: 'eta',
        dataIndex: 'eta',
        ...getColumnSearchProps('eta'),
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 90,
        align: 'center',
        render: (opportunity) => (
          <>
            <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="circle"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', opportunity.id)}
              />
            </Tooltip>
            {/* <CanShow roles={['ADMIN']}>
              <> */}
            <Tooltip title="Editar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#537896',
                  borderColor: '#537896',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<EditOutlined />}
                onClick={() => showModal('edit', opportunity.id)}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#e63241',
                  borderColor: '#cf1322',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(opportunity.id)}
              />
            </Tooltip>
            {/* </>
            </CanShow> */}
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Actividades',
    sheetName: 'Actividades Oportunidades - cotizaciones',
    tableName: 'Tabla de actividades de oportunidades - cotizaciones',
    columnNames: [
      { label: 'Descripción', key: 'description' },
      {
        label: 'ETA',
        key: 'eta',
      },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'Descripción', key: 'description' },
    {
      header: 'ETA',
      key: 'eta',
    },
  ];
  const opportunityLinesTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: OpportunityActivityForm,
    pdfColumns,
    titlePdf,
    scrollTable,
  };
  const OpportunityLinesTable = Table<Opportunity>(opportunityLinesTableProps);

  return OpportunityLinesTable;
};

export default OpportunityQuoteActivitiesList;
