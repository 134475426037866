// @ts-nocheck
import { Col, Form, Input, Row, Select, Spin, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Opportunity, get } from '../../../../api';
import { useParams } from 'react-router-dom';
import OpportunityQuoteActivitiesList from '../../opportunity-quote-activities/list';
import TextEditor from './text-editor';
import { convertFromRaw, EditorState } from 'draft-js';

type OpportunityFormProps = {
  type: string;
  id?: number | null;
  update: (v: Opportunity) => void;
};

const QuotePropsForm: React.FC<OpportunityFormProps> = (props) => {
  const { type, form } = props;
  const { Text } = Typography;
  let { id: idQuote } = useParams();

  const [notesList, setNotesList] = useState([]);
  const [clausesList, setClausesList] = useState([]);
  const [notesIdsSelected, setNotesIdsSelected] = useState([]);
  const [clausesIdsSelected, setClausesIdsSelected] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(EditorState.createEmpty().getCurrentContent())
  );
  //ENDPOINTS
  const { isLoading: isLoadingQuote, data } = useQuery<Opportunity>(
    ['/quotes/' + idQuote],
    () => get<Opportunity>('/quotes/' + idQuote).then((e) => e.data)
  );
  const { isLoading: isLoadingNotes, data: notes } = useQuery<Service[]>(
    ['notes'],
    () => get<Tax[]>('/notes').then((e) => e.data)
  );
  const { isLoading: isLoadingClauses, data: clauses } = useQuery<Service[]>(
    ['clauses'],
    () => get<Tax[]>('/clauses').then((e) => e.data)
  );

  useEffect(() => {
    if (data && !isLoadingQuote) {
      setClausesIdsSelected(data.clauseIds);
      setNotesIdsSelected(data.noteIds);
      form.setFieldsValue({
        noteIds: data.noteIds,
        clauseIds: data.clauseIds,
        ownerAgent: data.ownerAgent,
        customerAgent: data.customerAgent,
      });
      if (data?.serviceDetail) {
        let json = JSON.parse(data.serviceDetail);
        const contentState = convertFromRaw(json);
        let serviceString = EditorState.createWithContent(contentState);
        setEditorState(serviceString);
      }
    }
  }, [form, data, type, isLoadingQuote, setEditorState]);

  useEffect(() => {
    if (notesIdsSelected.length > 0) {
      let selectedNotes = notes?.filter((item) =>
        notesIdsSelected.includes(item.id)
      );
      setNotesList(selectedNotes);
    }
  }, [notesIdsSelected, notes]);

  useEffect(() => {
    if (clausesIdsSelected.length > 0) {
      let selectedClauses = clauses?.filter((item) =>
        clausesIdsSelected.includes(item.id)
      );
      setClausesList(selectedClauses);
    }
  }, [clausesIdsSelected, clauses]);
  const columns = [
    {
      title: 'Nombre',
      width: 300,
      ellipsis: true,
      dataIndex: 'name',
      key: 'name',
      render: (name) => (
        <>
          {name && <Text>{name.charAt(0).toUpperCase() + name.slice(1)}</Text>}
        </>
      ),
    },
    {
      title: 'Descripción',
      //   width: 100,
      ellipsis: true,
      dataIndex: 'description',
      key: 'description',
      render: (description) => (
        <>
          {description && (
            <Text>
              {description.charAt(0).toUpperCase() + description.slice(1)}
            </Text>
          )}
        </>
      ),
    },
  ];
  return (
    <div
      className="catalog__container"
      style={{ borderRadius: '0px 20px 20px 20px' }}
    >
      <Spin spinning={isLoadingQuote} tip={<p>Cargando...</p>}>
        <Col xs={24} md={24}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Representante planB:" name="ownerAgent">
                <Input placeholder="Ingresa información" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Representante cliente:" name="customerAgent">
                <Input placeholder="Ingresa información" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Puesto representante planB:"
                name="ownerAgentPosition"
              >
                <Input placeholder="Ingresa información" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Puesto Representante cliente:"
                name="customerAgentPosition"
              >
                <Input placeholder="Ingresa información" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: 10 }}>
            <Text strong style={{ fontSize: 15, marginBottom: 10 }}>
              Detalle del servicio:
            </Text>
            <Col xs={20} md={24}>
              <TextEditor
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </Col>
          </Row>
          <Text strong style={{ fontSize: 15 }}>
            Actividades:
          </Text>
          {/* <Divider style={{ margin: '10px 0px' }} /> */}
          <OpportunityQuoteActivitiesList idQuote={data?.id} />
          <Text strong style={{ fontSize: 15 }}>
            Notas:
          </Text>
          {notes && (
            <>
              <Col xs={24} md={24}>
                <Form.Item name="noteIds">
                  <Select
                    placeholder="Selecciona una opción"
                    disabled={type === 'watch'}
                    allowClear
                    showSearch
                    mode="multiple"
                    optionFilterProp="children"
                    onChange={(e) => setNotesIdsSelected(e)}
                  >
                    {notes.map((e: any) => (
                      <Select.Option key={e.id} value={e.id}>
                        {e.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {notesList?.length > 0 && (
                <Table columns={columns} dataSource={notesList} />
              )}
            </>
          )}

          <Text strong style={{ fontSize: 15 }}>
            Cláusulas:
          </Text>
          {clauses && (
            <>
              <Col xs={24} md={24}>
                <Form.Item name="clauseIds">
                  <Select
                    placeholder="Selecciona una opción"
                    disabled={type === 'watch'}
                    allowClear
                    showSearch
                    mode="multiple"
                    optionFilterProp="children"
                    onChange={(e) => setClausesIdsSelected(e)}
                  >
                    {clauses.map((e: any) => (
                      <Select.Option key={e.id} value={e.id}>
                        {e.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {clausesList?.length > 0 && (
                <Table columns={columns} dataSource={clausesList} />
              )}
            </>
          )}
        </Col>
      </Spin>
    </div>
  );
};

export default QuotePropsForm;
