// @ts-nocheck
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Row,
  Select,
  Collapse,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Opportunity, User, get } from '../../../../api';
import TextArea from 'antd/es/input/TextArea';
import { useUser } from '../../../../hooks';
type OpportunityFormProps = {
  type: string;
  id?: number | null;
  // add: (v: CreateOpportunity) => void;
  update: (v: Opportunity) => void;
};

const OpportunityAddForm: React.FC<OpportunityFormProps> = (props) => {
  const { type, add } = props;
  const [form] = Form.useForm();
  const [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState('');
  const [statusSelected, setStatusSelected] = useState('');
  const [users, setUsers] = useState([]);
  const { user } = useUser();

  const { RangePicker } = DatePicker;
  const statusOptions = [
    { label: 'Inicio', value: 'Inicio' },
    { label: 'Demo', value: 'Demo' },
    { label: 'Cotización', value: 'Cotización' },
    { label: 'Cerrada ganada', value: 'Cerrada ganada' },
    { label: 'Cerrada perdida', value: 'Cerrada perdida' },
  ];
  //ENDPOINTS
  const { isLoading: isLoadingCustomers, data: customersget } = useQuery<
    Customer[]
  >(['cliente'], () => get<Customer[]>('/customers').then((e) => e.data));

  const { isLoading: isLoadingUsers, data: usersget } = useQuery<User[]>(
    ['usuario'],
    () => get<User[]>('/users').then((e) => e.data)
  );

  useEffect(() => {
    if (customersget) {
      const customersOrdenados = [...customersget];
      customersOrdenados.sort((a, b) => a.name.localeCompare(b.name));
      setCustomers(customersOrdenados);
    }
    if (usersget) {
      const usersOrdenados = [...usersget];
      usersOrdenados.sort((a, b) => a.firstName.localeCompare(b.firstName));
      setUsers(usersOrdenados);
    }
  }, [customersget, usersget]);

  useEffect(() => {
    if (customerSelected !== undefined && customerSelected !== '') {
      get<Customer[]>('/customers/' + customerSelected).then((e) => {
        form.setFieldsValue({
          address: e.data.address,
          state: e.data.state,
          city: e.data.city,
          country: e.data.country,
          zipcode: e.data.postalCode,
          region: e.data.region,
        });
      });
    }
    form.setFieldsValue({
      agentId: user === null ? '' : user.id.toString(),
      exchangeRate: 0,
    });
  }, [form, customerSelected, customersget, user]);

  const addOpportunityQuoteFunction = async (values: any) => {
    // AGREGAR QUOTE
    let opportunityRequest: CreateOpportunity = {
      stage: values.stage,
      endDate: values.endDate?.format('YYYY-MM-DD'),
      customerId: values.customerId,
      scorePercent: values.scorePercent,
      agentId: values.agentId,
      header: values.header,
      currency: values.currency,
      exchangeRate: values.exchangeRate,
      effectiveStartDate: values.date[0].format('YYYY-MM-DD'),
      effectiveEndDate: values.date[1].format('YYYY-MM-DD'),
    };

    add(opportunityRequest);
  };
  return (
    <div>
      <Row>
        <Col
          xs={24}
          md={24}
          // style={{ maxHeight: 450, overflowY: 'scroll', height: 450 }}
        >
          <Form
            name="add-promo"
            layout="vertical"
            form={form}
            onFinish={addOpportunityQuoteFunction}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Nombre de proyecto:"
                  name="header"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese encabezado',
                    },
                  ]}
                >
                  <Input placeholder="Ingresa información" />
                </Form.Item>
              </Col>
              <Form.Item label="ID Oportunidad:" name="id" hidden></Form.Item>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Vigencia:"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione fecha',
                    },
                  ]}
                >
                  <RangePicker
                    disabled={type === 'watch'}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                {customers && (
                  <Form.Item
                    label="Cliente:"
                    name="customerId"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor seleccione cliente',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      disabled={type === 'watch'}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      onChange={(val) => setCustomerSelected(val)}
                    >
                      {customers.map((e: any) => (
                        <Select.Option key={e.id} value={e.id}>
                          {e.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Fecha de cierre:"
                  name="endDate"
                  rules={[
                    {
                      required: statusSelected === 3 || statusSelected === 4,
                      message: 'Por favor seleccione cliente',
                    },
                  ]}
                >
                  <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                {users && (
                  <Form.Item
                    label="Agente:"
                    name="agentId"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor seleccione agente',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      allowClear
                      disabled={user?.role === 'SALES'}
                      showSearch
                      optionFilterProp="children"
                    >
                      {users.map((e: any) => (
                        <Select.Option key={e.id} value={e.id}>
                          {e.firstName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col xs={20} md={12}>
                <Form.Item
                  label="Estatus:"
                  name="stage"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese estatus.',
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecciona una opción"
                    allowClear
                    showSearch
                    options={statusOptions}
                    optionFilterProp="children"
                    onChange={(e) =>
                      setStatusSelected(
                        e === 'Inicio'
                          ? 0
                          : e === 'Demo'
                          ? 1
                          : e === 'Cotización'
                          ? 2
                          : e === 'Cerrada'
                          ? 3
                          : 4
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12} md={12}>
                <Form.Item
                  label="Moneda:"
                  name="currency"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione moneda',
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecciona una opción"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    options={[
                      { value: 'MXN', label: 'MXN' },
                      { value: 'USD', label: 'USD' },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={12}>
                <Tooltip title={'Cambio en MXN'}>
                  <Form.Item
                    label="Tipo de cambio:"
                    name="exchangeRate"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese tipo de cambio',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      disabled={type === 'watch'}
                      placeholder="Ingresa información"
                      // defaultValue={0}
                      min={0}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={20} md={12}>
                <Form.Item label="Probabilidad de cierre:" name="scorePercent">
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Ingresa información"
                    disabled={type === 'watch'}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    min={0}
                    max={100}
                  />
                </Form.Item>
              </Col>
            </Row>

            {statusSelected === 3 && (
              <Row gutter={16}>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Comentarios:"
                    name="comments"
                    rules={[
                      {
                        required: statusSelected === 3,
                        message: 'Por favor ingrese comentario',
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Ingresa información"
                      disabled={type === 'watch'}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Collapse
              style={{ marginBottom: 10 }}
              items={[
                {
                  key: '1',
                  label: 'Información de dirección de cliente',
                  children: (
                    <Row gutter={16}>
                      <Col xs={20} md={12}>
                        <Form.Item label="Dirección:" name="address">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={20} md={12}>
                        <Form.Item label="Estado/provincia:" name="state">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={20} md={12}>
                        <Form.Item label="Colonia:" name="region">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={20} md={12}>
                        <Form.Item label="País:" name="country">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={20} md={12}>
                        <Form.Item label="Ciudad:" name="city">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={20} md={12}>
                        <Form.Item label="Código postal:" name="zipcode">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  ),
                },
              ]}
            />
            <Row justify="end">
              <Form.Item>
                <Button htmlType="submit" className="btn-add">
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default OpportunityAddForm;
