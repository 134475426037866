// @ts-nocheck
import {
  Button,
  Col,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
  Form,
} from 'antd';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { LeftOutlined, SendOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Opportunity, get, post } from '../../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import OpportunityQuotePdf from './quote';
import postFile from '../../../../api/requests/postFile';
import { CanShow } from '../../../../components';
type OpportunityFormProps = {
  type?: string;
  id?: number | null;
  // add: (v: CreateOpportunity) => void;
  update?: (v: Opportunity) => void;
};

const PdfViewer: React.FC<OpportunityFormProps> = (props) => {
  let { id: idQuote, quoteType } = useParams();
  const { Title } = Typography;
  const navigate = useNavigate();
  const [contactsIds, setContactsIds] = useState([]);

  //ENDPOINTS
  const { isLoading: isLoadingQuote, data } = useQuery<Opportunity>(
    ['/quotes/' + idQuote + '/pdf'],
    () =>
      idQuote
        ? get<Opportunity>('/quotes/' + idQuote + '/pdf').then((e) => e.data)
        : ''
  );
  const { isLoading: isLoadingCContacts, data: contacts } = useQuery<Contact[]>(
    ['contacts'],
    () => get<Customer[]>('/contacts').then((e) => e.data)
  );

  const sendPdf = async () => {
    const blob = await pdf(<OpportunityQuotePdf data={data} />).toBlob();
    postFile(`/quotes/${idQuote}/sendEmail`, contactsIds, 'Pdf', blob, true);
  };

  return (
    <>
      <div className="catalog__container">
        <Row className="header-top">
          <Col xs={19} className="header-top__left">
            <Title
              level={3}
              style={{ top: 0, bottom: 0, height: '78%', margin: 'auto' }}
            >
              PDF - cotización {idQuote}
            </Title>
          </Col>
          <Col xs={5}>
            <Row style={{ marginBottom: 30, justifyContent: 'right' }}>
              <Tooltip title="Atrás">
                <Button
                  style={{ color: 'gray', fontSize: 20, height: 0, width: 0 }}
                  type="link"
                  onClick={() =>
                    navigate(
                      quoteType === 'table'
                        ? '/opportunity-quotes'
                        : `/opportunity/quote/${idQuote}`
                    )
                  }
                >
                  <LeftOutlined />
                </Button>
              </Tooltip>
            </Row>
          </Col>
        </Row>
      </div>
      {/* <CanShow roles={['ADMIN']}> */}
      <Row justify={'end'}>
        <Col xs={22} md={12}>
          {contacts && (
            <Form.Item
              label="Contactos adicionales a enviar:"
              name="customerId"
            >
              <Select
                placeholder="Selecciona una opción"
                allowClear
                showSearch
                mode="multiple"
                optionFilterProp="children"
                onChange={(val) => setContactsIds(val)}
              >
                {contacts.map((e: any) => (
                  <Select.Option key={e.id} value={e.id}>
                    {e.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
        <Button
          disabled={isLoadingQuote}
          loading={isLoadingQuote}
          // disabled={!buttonVisible}
          className="btn-add"
          style={{ width: 160, marginRight: 20 }}
          block
          onClick={() => sendPdf()}
          icon={<SendOutlined />}
        >
          {'Enviar cotización'}
        </Button>
      </Row>
      {/* </CanShow> */}
      <div className={'catalog__container'} style={{ height: '85%' }}>
        <Spin spinning={isLoadingQuote} tip="Cargando PDF...">
          {data && (
            <PDFViewer width={'100%'} style={{ height: '60vh' }}>
              <OpportunityQuotePdf data={data} />
            </PDFViewer>
          )}
        </Spin>
      </div>
    </>
  );
};

export default PdfViewer;
