import { Button, Row, Tag, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import { ExportPdfProps } from '../../../../helpers/pdf';
import { Service } from '../../../../api/models/services';
import ScoreForm from '../detail';

const label = 'calificación';
const route = '/customerScores';
const titlePdf = 'Reporte de calificaciones';
const scrollTable = 300;
type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Service>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const ScoresList = () => {
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Service> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Descripción',
        width: 100,
        ellipsis: true,
        dataIndex: 'description',
        key: 'description',
        ...getColumnSearchProps('description'),
        render: (description) => (
          <>
            {description && (
              <Text>
                {description.charAt(0).toUpperCase() + description.slice(1)}
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Color',
        width: 60,
        // sorter: true,
        dataIndex: 'color',
        key: 'color',
        render: (color) => (
          <Row gutter={16} style={{ width: 10 }}>
            <Tag color={color} style={{ marginBottom: 10 }}>
              {color}
            </Tag>
          </Row>
        ),
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 50,
        align: 'center',
        render: (contact) => (
          <>
            <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="circle"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', contact.id)}
              />
            </Tooltip>
            {/* <CanShow roles={['ADMIN', 'MANAGEMENT']}>
              <> */}
            <Tooltip title="Editar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#537896',
                  borderColor: '#537896',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<EditOutlined />}
                onClick={() => showModal('edit', contact.id)}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#e63241',
                  borderColor: '#cf1322',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(contact.id)}
              />
            </Tooltip>
            {/* </>
            </CanShow> */}
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Etiquetas',
    sheetName: 'Etiquetas',
    tableName: 'Tabla de Etiquetas',
    columnNames: [
      { label: 'ID', key: 'id' },
      { label: 'Nombre', key: 'name' },
      { label: 'Descripción', key: 'description' },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'ID', key: 'id' },
    { header: 'Nombre', key: 'name' },
    { header: 'Descripción', key: 'description' },
  ];
  const scoresTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: ScoreForm,
    pdfColumns,
    titlePdf,
    scrollTable,
  };
  const ScoresTable = Table<Service>(scoresTableProps);

  return ScoresTable;
};

export default ScoresList;
