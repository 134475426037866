// @ts-nocheck
import {
  Button,
  Col,
  Form,
  Row,
  Typography,
  Tooltip,
  Tabs,
  FloatButton,
  Modal as ModalAntd,
} from 'antd';
import { convertToRaw } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Opportunity, get, put } from '../../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import {
  LeftOutlined,
  SaveOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import OpportunityForm from '../detail';
import QuotePropsForm from '../quote-props';
import ContactForm from '../../../administration/contacts/detail';
import Modal from '../../../../components/general/modal';
import TaskForm from '../../../administration/tasks/detail';
import CallLogForm from '../../call-log/detail';

type OpportunityFormProps = {
  type: string;
  id?: number | null;
  // add: (v: CreateOpportunity) => void;
  update: (v: Opportunity) => void;
};

const OpportunityHeaderForm: React.FC<OpportunityFormProps> = (props) => {
  const { type } = props;
  const [form] = Form.useForm();
  const { Title } = Typography;
  let { id: idQuote } = useParams();
  const navigate = useNavigate();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [contextModal, setContextModal] = useState<ReactElement | null>(null);
  const [disabledInputs, setDisabledInputs] = useState(false);

  //ENDPOINTS
  const { isLoading: isLoadingQuote, data } = useQuery<Opportunity>(
    ['/quotes/' + idQuote],
    () => get<Opportunity>('/quotes/' + idQuote).then((e) => e.data)
  );

  const showModal = (type: string, id: number | null) => {
    setModalIsVisible(true);
    setTitleModal('Agregar');
    if (type === 'contacto') {
      setContextModal(() => (
        <ContactForm
          type={'add'}
          id={null}
          idCustomer={parseInt(id)}
          setModalIsVisible={setModalIsVisible}
        />
      ));
    } else if (type === 'tarea') {
      setContextModal(() => (
        <TaskForm
          type={'add'}
          id={null}
          setModalIsVisible={setModalIsVisible}
        />
      ));
    } else if (type === 'seguimiento') {
      setContextModal(() => (
        <CallLogForm
          type={'add'}
          id={null}
          setModalIsVisible={setModalIsVisible}
        />
      ));
    }
  };

  const addOpportunityQuoteFunction = async (values: any) => {
    if (values.serviceDetail) {
      const contentState = values.serviceDetail.getCurrentContent();
      const raw = convertToRaw(contentState);
      values['serviceDetail'] = JSON.stringify(raw, null, 2);
    } else {
      values['serviceDetail'] = data.serviceDetail;
    }
    values['id'] = idQuote;
    values['effectiveStartDate'] = values.date[0].format('YYYY-MM-DD');
    values['effectiveEndDate'] = values.date[1].format('YYYY-MM-DD');
    values['endDate'] = values.endDate?.format('YYYY-MM-DD');
    // update(values);
    put(`/quotes/${idQuote}`, values, true);
  };

  useEffect(() => {
    if (data?.stage === 'Cerrada perdida' || data?.stage === 'Cerrada ganada') {
      setDisabledInputs(true);
    }
  }, [data]);
  return (
    <>
      <div className="catalog__container">
        <Row className="header-top">
          <Col xs={19} className="header-top__left">
            <Title
              level={3}
              style={{ top: 0, bottom: 0, height: '78%', margin: 'auto' }}
            >
              Detalle de oportunidad/cotización {idQuote}
            </Title>
          </Col>
          <Col xs={5}>
            <Row style={{ marginBottom: 30, justifyContent: 'right' }}>
              <Tooltip title="Atrás">
                <Button
                  style={{
                    color: 'gray',
                    fontSize: 20,
                    height: 0,
                    width: 0,
                  }}
                  type="link"
                  onClick={() => navigate('/opportunity-quotes')}
                >
                  <LeftOutlined />
                </Button>
              </Tooltip>
            </Row>
          </Col>
        </Row>
      </div>
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        disabled={disabledInputs}
        onFinish={addOpportunityQuoteFunction}
      >
        <Tabs
          defaultActiveKey="1"
          type="card"
          size={'small'}
          style={{ marginBottom: 0 }}
          items={[
            {
              label: 'Detalle de la oportunidad',
              key: '1',
              children: (
                <OpportunityForm
                  type="edit"
                  form={form}
                  showModal={showModal}
                  disabledInputs={disabledInputs}
                />
              ),
            },
            {
              label: 'Detalle PDF',
              key: '2',
              children: <QuotePropsForm type="edit" form={form} />,
            },
          ]}
        />
        <FloatButton.Group shape="circle" style={{ right: 24 }}>
          {disabledInputs === false && (
            <Tooltip title="Guardar cambios">
              <FloatButton
                htmlType="submit"
                disabled={
                  disabledInputs === true || type === 'watch' || isLoadingQuote
                }
                style={{ marginRight: 30, backgroundColor: '#7b96ac' }}
                icon={<SaveOutlined />}
                type="primary"
                onClick={() => form.submit()}
              />
            </Tooltip>
          )}
          <Tooltip title="Ver PDF">
            <FloatButton
              onClick={() =>
                navigate(`/detail/opportunity-quote/${idQuote}/pdf`)
              }
            />
          </Tooltip>
          {/* <Tooltip title="Agregar tarea">
            <FloatButton
              icon={<UnorderedListOutlined />}
              onClick={() => showModal('tarea', idQuote)}
            />
          </Tooltip> */}

          <FloatButton.BackTop visibilityHeight={200} />
        </FloatButton.Group>
      </Form>
      <Modal
        title={titleModal}
        isVisible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
};

export default OpportunityHeaderForm;
