import React, { useEffect, useState } from 'react';
import { Route, Navigate, redirect, Outlet } from 'react-router-dom';
import { Layout, Spin, notification } from 'antd';
import moment from 'moment';
import useAuth from '../hooks/useAuth';
import useSettings from '../hooks/useSettings';
import MenuTop from '../components/menu/menu-top';
import MenuSider from '../components/menu/menu-sider';
import Login from '../pages/login';
import Perfil from '../pages/home/profile';
// import useUser from '../hooks/useUser';
import { useLocation } from 'react-router-dom';
import { isMobile } from '../utils/general';
import { useNavigate } from 'react-router';

import './dashboard-layout.scss';
import type { Route as AppRoute } from '../config/routes';
import LoginForm from '../components/general/login-form';
import { useUser } from '../hooks';

const DashboardLayout = (props: { children?: AppRoute[] }) => {
  const { children } = props;
  const { Header, Content, Footer } = Layout;
  // const { jwt, isLoading } = useAuth();
  const { user, isLoading } = useUser();
  const { menuCollapsed, setMenuCollapsed } = useSettings();
  const actualYear = moment().format('YYYY');
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile() && setMenuCollapsed !== undefined) {
      setMenuCollapsed(true);
    }
  }, [setMenuCollapsed]);

  useEffect(() => {
    if (!isLoading && user === null) {
      navigate('/login');
    }
  }, [isLoading, user, navigate]);

  if (isLoading) {
    return (
      <Spin className="spinner" spinning={true} tip={<p>Cargando...</p>}></Spin>
    );
  }

  if (user !== null) {
    return (
      <Layout>
        <MenuSider menuCollapsed={menuCollapsed} roles={[]} />
        <Layout
          className="layout-admin"
          style={{ marginLeft: menuCollapsed ? '20px' : '200px' }}
        >
          <Header className="layout-admin__header">
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>

          <Content className="layout-admin__content">
            <Outlet />
          </Content>

          <Footer className="layout-admin__footer">
            {`© plan B Consultoría de Sistemas. Monterrey, N.L. México ${actualYear}.`}
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return null;
};

export default DashboardLayout;
