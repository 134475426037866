import { Alert, Layout } from 'antd';
import GreetingsTop from '../../components/menu/greetings-top';
import './home.scss';
import { useUser } from '../../hooks';
import DashboardCustomer from './customer-dashboard';
import GeneralHome from './general-home';

const Home = () => {
  const { user } = useUser();
  return (
    <Layout className="admin">
      <GreetingsTop />
      {user && user.extendedInfo?.rfc === '' && (
        <Alert
          style={{ margin: 20 }}
          message="Debes actualizar contraseña."
          banner
        />
      )}
      <GeneralHome />
      {/* <DashboardCustomer /> */}
      {/* <CardsHome></CardsHome> */}
    </Layout>
  );
};

export default Home;
