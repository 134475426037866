// @ts-nocheck
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Row,
  Select,
  Spin,
  Typography,
  Steps,
  Collapse,
  Tooltip,
  Button,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Opportunity, User, get } from '../../../../api';
import dayjs from 'dayjs';
import OpportunityQuoteLinesList from '../../opportunity-quote-lines/list';
import CallLogList from '../../call-log/list';
import { Contact } from '../../../../api/models/contacts';
import { useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import TasksList from '../../../administration/tasks/list';
import TasksListDetail from '../../../administration/tasks/list-detail';
import CallLogListDetail from '../../call-log/list-detal';
type OpportunityFormProps = {
  type: string;
  id?: number | null;
  update: (v: Opportunity) => void;
};

const OpportunityForm: React.FC<OpportunityFormProps> = (props) => {
  const { type, form, showModal, disabledInputs } = props;
  const [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState('');
  const [statusSelected, setStatusSelected] = useState('');
  const [users, setUsers] = useState([]);
  const { RangePicker } = DatePicker;
  const { Text } = Typography;
  let { id: idQuote } = useParams();
  const statusOptionsBar = [
    { value: 'Inicio', title: 'Inicio' },
    { value: 'Demo', title: 'Demo' },
    { value: 'Cotización', title: 'Cotización' },
    { value: 'Cerrada', title: 'Cerrada' },
  ];
  const statusOptions = [
    { label: 'Inicio', value: 'Inicio' },
    { label: 'Demo', value: 'Demo' },
    { label: 'Cotización', value: 'Cotización' },
    { label: 'Cerrada ganada', value: 'Cerrada ganada' },
    { label: 'Cerrada perdida', value: 'Cerrada perdida' },
  ];
  //ENDPOINTS
  const { isLoading: isLoadingQuote, data } = useQuery<Opportunity>(
    ['/quotes/' + idQuote],
    () =>
      idQuote ? get<Opportunity>('/quotes/' + idQuote).then((e) => e.data) : ''
  );
  const { isLoading: isLoadingCustomers, data: customersget } = useQuery<
    Customer[]
  >(['cliente'], () => get<Customer[]>('/customers').then((e) => e.data));

  const { isLoading: isLoadingCustomerContacts, data: contacts } = useQuery<
    Contact[]
  >([`/customers/${customerSelected}/contacts`], () =>
    customerSelected
      ? get<Contact[]>(`/customers/${customerSelected}/contacts`).then(
          (e) => e.data
        )
      : null
  );

  const { isLoading: isLoadingUsers, data: usersget } = useQuery<User[]>(
    ['usuario'],
    () => get<User[]>('/users').then((e) => e.data)
  );

  useEffect(() => {
    if (customersget) {
      const customersOrdenados = [...customersget];
      customersOrdenados.sort((a, b) => a.name.localeCompare(b.name));
      setCustomers(customersOrdenados);
    }
    if (usersget) {
      const usersOrdenados = [...usersget];
      usersOrdenados.sort((a, b) => a.firstName.localeCompare(b.firstName));
      setUsers(usersOrdenados);
    }
  }, [customersget, usersget]);

  useEffect(() => {
    if (data && !isLoadingQuote) {
      let dateArray = [
        dayjs(data.effectiveStartDate, 'YYYY/MM/DD'),
        dayjs(data.effectiveEndDate, 'YYYY/MM/DD'),
      ];
      setCustomerSelected(data?.customerId);

      setStatusSelected(
        data?.stage === 'Inicio'
          ? 0
          : data?.stage === 'Demo'
          ? 1
          : data?.stage === 'Cotización'
          ? 2
          : data?.stage === 'Cerrada ganada'
          ? 3
          : data?.stage === 'Cerrada perdida'
          ? 4
          : 0
      );
      form.setFieldsValue({
        customerId: data?.customerId,
        agentId: data?.agentId,
        id: data?.id,
        date: dateArray,
        stage: data?.stage,
        header: data?.header,
        comments: data?.comments,
        scorePercent: data?.scorePercent,
        currency: data?.currency,
        exchangeRate: data?.exchangeRate,
      });
      if (data.endDate) {
        form.setFieldsValue({
          endDate: dayjs(data?.endDate, 'YYYY/MM/DD'),
        });
      }
    }
  }, [form, data, isLoadingQuote]);

  useEffect(() => {
    if (customerSelected !== undefined && customerSelected !== '') {
      get<Customer[]>('/customers/' + customerSelected).then((e) => {
        form.setFieldsValue({
          address: e.data.address,
          state: e.data.state,
          city: e.data.city,
          country: e.data.country,
          zipcode: e.data.postalCode,
          region: e.data.region,
        });
      });
    }
  }, [form, customerSelected, customersget]);

  return (
    <div
      className={'catalog__container'}
      style={{ borderRadius: '0px 20px 20px 20px' }}
    >
      <Spin spinning={isLoadingQuote} tip={<p>Cargando...</p>}>
        <Row>
          <Col xs={24}>
            <Form.Item name="statusStage" disabled>
              <Steps
                current={statusSelected === 4 ? 3 : statusSelected}
                status={
                  statusSelected === 3 || statusSelected === 4
                    ? 'finish'
                    : 'process'
                }
                items={statusOptionsBar}
              />
            </Form.Item>
          </Col>
          <Divider style={{ margin: '0px 0px 20px 0px' }} />
          <Col
            xs={24}
            md={8}
            style={{
              borderStyle: 'inset',
              borderWidth: '0px 10px 0px 0px',
              marginRight: 10,
            }}
          >
            <Row
              style={{
                backgroundColor: '#f2f2f2',
                borderRadius: 9,
                margin: 5,
                justifyContent: 'space-between',
                lineHeight: 2,
              }}
            >
              <Col style={{ alignContent: 'center' }}>
                <Text strong style={{ margin: 5 }}>
                  CONTACTOS
                </Text>
              </Col>
              <Col>
                <Button
                  className="btn-add"
                  style={{ width: 35 }}
                  icon={<PlusCircleOutlined />}
                  // size="small"
                  shape="circle"
                  onClick={() => showModal('contacto', customerSelected)}
                />
              </Col>
            </Row>
            <div style={{ overflowY: 'scroll', height: 250 }}>
              {contacts?.map((e, index) => {
                return (
                  <>
                    <Col style={{ marginLeft: 10 }}>
                      <Row>
                        {e.mainContact && (
                          <Text
                            strong
                            style={{
                              color: 'red',
                              marginRight: 3,
                              fontSize: 15,
                              textTransform: 'capitalize',
                            }}
                          >
                            *
                          </Text>
                        )}
                        <Text
                          strong
                          style={{
                            color: '#0D2F41',
                            marginRight: 3,
                            fontSize: 15,
                            textTransform: 'capitalize',
                          }}
                        >
                          {e.name} {e.lastName}
                        </Text>
                      </Row>
                      <Row>
                        <Text strong style={{ marginRight: 3 }}>
                          Correo:{' '}
                        </Text>
                        <Text style={{ color: 'blue' }}> {e.email}</Text>
                      </Row>
                      <Row>
                        <Text strong style={{ marginRight: 3 }}>
                          Teléfono:
                        </Text>
                        <Text>{e.phone}</Text>
                      </Row>
                      <Row>
                        <Text strong style={{ marginRight: 3 }}>
                          Cargo:{' '}
                        </Text>
                        <Text> {e.department}</Text>
                      </Row>
                      <Divider style={{ margin: '10px 0px' }} />
                    </Col>
                  </>
                );
              })}
            </div>
            <Row
              style={{
                backgroundColor: '#f2f2f2',
                borderRadius: 9,
                margin: 5,
                justifyContent: 'space-between',
              }}
            >
              <Col style={{ alignContent: 'center' }}>
                <Text strong style={{ margin: 5 }}>
                  ÚLTIMOS SEGUIMIENTOS
                </Text>
              </Col>
              <Col>
                <Button
                  className="btn-add"
                  style={{ width: 35 }}
                  icon={<PlusCircleOutlined />}
                  // size="small"
                  shape="circle"
                  onClick={() => showModal('seguimiento', null)}
                />
              </Col>
            </Row>
            {/* <CallLogList /> */}
            <Col style={{ marginTop: 20, marginBottom: 20 }}>
              <CallLogListDetail type={'quote'} id={idQuote} />
            </Col>
            <Row
              style={{
                backgroundColor: '#f2f2f2',
                borderRadius: 9,
                margin: 5,
                justifyContent: 'space-between',
              }}
            >
              <Col style={{ alignContent: 'center' }}>
                <Text strong style={{ margin: 5 }}>
                  ÚLTIMAS TAREAS
                </Text>
              </Col>
              <Col>
                <Button
                  className="btn-add"
                  style={{ width: 35 }}
                  icon={<PlusCircleOutlined />}
                  onClick={() => showModal('tarea', idQuote)}
                />
              </Col>
            </Row>
            {/* <TaskList /> */}
            <Col style={{ marginTop: 20, marginBottom: 20 }}>
              <TasksListDetail type={'quote'} id={idQuote} />
            </Col>
          </Col>

          <Col
            xs={24}
            md={15}
            // style={{ maxHeight: 450, overflowY: 'scroll', height: 450 }}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Nombre de proyecto:"
                  name="header"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese encabezado',
                    },
                  ]}
                >
                  <Input placeholder="Ingresa información" />
                </Form.Item>
              </Col>
              <Form.Item label="ID Oportunidad:" name="id" hidden></Form.Item>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Vigencia:"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione fecha',
                    },
                  ]}
                >
                  <RangePicker
                    disabled={type === 'watch'}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                {customers && (
                  <Form.Item
                    label="Cliente:"
                    name="customerId"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor seleccione cliente',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      disabled={type === 'watch'}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      onChange={(val) => setCustomerSelected(val)}
                    >
                      {customers.map((e: any) => (
                        <Select.Option key={e.id} value={e.id}>
                          {e.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Fecha de cierre:"
                  name="endDate"
                  rules={[
                    {
                      required: statusSelected === 3 || statusSelected === 4,
                      message: 'Por favor seleccione cliente',
                    },
                  ]}
                >
                  <DatePicker
                    disabled={type === 'watch'}
                    format={'DD/MM/YYYY'}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                {users && (
                  <Form.Item
                    label="Agente:"
                    name="agentId"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor seleccione agente',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      disabled={type === 'watch'}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                    >
                      {users.map((e: any) => (
                        <Select.Option key={e.id} value={e.id}>
                          {e.firstName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col xs={20} md={12}>
                <Form.Item
                  label="Estatus:"
                  name="stage"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese estatus.',
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecciona una opción"
                    disabled={disabledInputs || type === 'watch'}
                    allowClear
                    showSearch
                    options={statusOptions}
                    onChange={(e) => {
                      let status: number;
                      if (e === 'Inicio') status = 0;
                      if (e === 'Demo') status = 1;
                      if (e === 'Cotización') status = 2;
                      if (e === 'Cerrada ganada') {
                        status = 3;
                      }
                      if (e === 'Cerrada perdida') {
                        status = 3;
                      }
                      setStatusSelected(status);
                    }}
                    optionFilterProp="children"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12} md={12}>
                <Form.Item
                  label="Moneda:"
                  name="currency"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione moneda',
                    },
                  ]}
                >
                  <Select
                    disabled={type === 'watch'}
                    placeholder="Selecciona una opción"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    options={[
                      { value: 'MXN', label: 'MXN' },
                      { value: 'USD', label: 'USD' },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={12}>
                <Tooltip title={'Cambio en MXN'}>
                  <Form.Item
                    label="Tipo de cambio:"
                    name="exchangeRate"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese tipo de cambio',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      disabled={type === 'watch'}
                      placeholder="Ingresa información"
                      initialvalue={0}
                      min={0}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Tooltip>
                {/* <Text style={{ marginLeft: 5 }}>MXN</Text> */}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={20} md={12}>
                <Form.Item label="Probabilidad de cierre:" name="scorePercent">
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Ingresa información"
                    disabled={type === 'watch'}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    min={0}
                    max={100}
                  />
                </Form.Item>
              </Col>
            </Row>
            {(statusSelected === 3 || statusSelected === 4) && (
              <Row gutter={16}>
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Comentarios:"
                    name="comments"
                    rules={[
                      {
                        required: statusSelected === 3 || statusSelected === 4,
                        message:
                          'Por favor ingrese comentario para cerrar la cotización',
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Ingresa información"
                      disabled={type === 'watch'}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Collapse
              items={[
                {
                  key: '1',
                  label: 'Información de dirección de cliente',
                  children: (
                    <Row gutter={16}>
                      <Col xs={20} md={12}>
                        <Form.Item label="Dirección:" name="address">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={20} md={12}>
                        <Form.Item label="Estado/provincia:" name="state">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={20} md={12}>
                        <Form.Item label="Colonia:" name="region">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={20} md={12}>
                        <Form.Item label="País:" name="country">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={20} md={12}>
                        <Form.Item label="Ciudad:" name="city">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={20} md={12}>
                        <Form.Item label="Código postal:" name="zipcode">
                          <Input placeholder="Ingresa información" disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  ),
                },
              ]}
            />
            <Divider />
            <Text strong style={{ fontSize: 15 }}>
              Lineas
            </Text>
            <OpportunityQuoteLinesList idQuote={data?.id} />
          </Col>
        </Row>
      </Spin>
      {/* Modal de detalle */}
      {/* {modalMemo} */}
    </div>
  );
};

export default OpportunityForm;
