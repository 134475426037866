import { Button, Tooltip, Typography } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Table from '../../../../components/general/table';
import { ExportExcelProps } from '../../../../helpers/excel';
import { ExportPdfProps } from '../../../../helpers/pdf';
import { CanShow } from '../../../../components';
import { Service } from '../../../../api/models/services';
import TaxesForm from '../detail';
import { formatMoney } from '../../../../utils/general';

const label = 'impuesto';
const route = '/taxes';
const titlePdf = 'Reporte de impuestos';
const scrollTable = 800;
type GenerateTableColumnProps = {
  getColumnSearchProps: (propName: string) => ColumnType<Service>;
  showModal: (type: string, id: number | null) => void;
  handleDelete: (id: number) => void;
};

const TaxesList = () => {
  const { Text } = Typography;

  const generateTableColumns: (
    props: GenerateTableColumnProps
  ) => ColumnsType<Service> = (props) => {
    const { getColumnSearchProps, showModal, handleDelete } = props;

    return [
      {
        title: 'Descripción',
        width: 100,
        ellipsis: true,
        dataIndex: 'description',
        key: 'description',
        ...getColumnSearchProps('description'),
        render: (description) => (
          <>
            {description && (
              <Text>
                {description.charAt(0).toUpperCase() + description.slice(1)}
              </Text>
            )}
          </>
        ),
      },
      {
        title: 'Porcentaje',
        width: 50,
        ellipsis: true,
        dataIndex: 'percent',
        key: 'percent',
        ...getColumnSearchProps('percent'),
        render: (percent) => <>{<Text>{percent}%</Text>}</>,
      },
      {
        title: 'Acciones',
        key: 'sup',
        fixed: 'right',
        width: 80,
        align: 'center',
        render: (contact) => (
          <>
            <Tooltip title="Ver más información">
              <Button
                type="primary"
                ghost
                shape="circle"
                size="small"
                icon={<EyeOutlined />}
                onClick={() => showModal('watch', contact.id)}
              />
            </Tooltip>
            {/* <CanShow roles={['ADMIN', 'MANAGEMENT']}>
              <> */}
            <Tooltip title="Editar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#537896',
                  borderColor: '#537896',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<EditOutlined />}
                onClick={() => showModal('edit', contact.id)}
              />
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: '#e63241',
                  borderColor: '#cf1322',
                }}
                type="primary"
                shape="circle"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(contact.id)}
              />
            </Tooltip>
            {/* </>
            </CanShow> */}
          </>
        ),
      },
    ];
  };

  const generateExportExcelProps: () => ExportExcelProps = () => ({
    fileName: 'Reporte_Impuestos',
    sheetName: 'Impuestos',
    tableName: 'Tabla de Impuestos',
    columnNames: [
      { label: 'ID', key: 'id' },
      { label: 'Descripción', key: 'description' },
      { label: 'Porcentaje', key: 'percent', format: (val) => `${val}%` },
    ],
  });

  const pdfColumns: ExportPdfProps[] = [
    { header: 'ID', key: 'id' },
    { header: 'Descripción', key: 'description' },
    {
      header: 'Porcentaje',
      key: 'percent',
      format: (val) => `${val}%`,
    },
  ];
  const taxesTableProps = {
    label,
    route,
    generateTableColumns,
    generateExportExcelProps,
    detailForm: TaxesForm,
    pdfColumns,
    titlePdf,
    scrollTable,
  };
  const TaxesTable = Table<Service>(taxesTableProps);

  return TaxesTable;
};

export default TaxesList;
