// @ts-nocheck
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputRef,
  Row,
  Select,
  Space,
  Spin,
} from 'antd';
import React, { Key, useRef, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { del, get, post } from '../../../../api';
import { PlusOutlined } from '@ant-design/icons';
import delWithBody from '../../../../api/requests/delWithBody';
import { queryClient } from '../../../../config';

type TagsFormProps = {
  selectedRowKeys?: Key[];
  setModalIsVisible: Dispatch<SetStateAction<boolean>>;
};

const CustomerTagsForm: React.FC<TagsFormProps> = (props) => {
  const { selectedRowKeys, setModalIsVisible } = props;
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [typeSelected, setTypeSelected] = useState('Agregar');
  const [tagsItems, setTagsItems] = useState([]);
  const inputRef = useRef<InputRef>(null);
  const [tagName, setTagName] = useState('');

  //ENDPOINTS
  const { isLoading: isLoadingTags, data: tags } = useQuery<any>(['tags'], () =>
    get<any>('/tags').then((e) => e.data)
  );

  useEffect(() => {
    if (tags) {
      setTagsItems(tags);
    }
  }, [tags]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };

  const handleRefresh = () => {
    queryClient.invalidateQueries(`/customers`);
  };

  const addItem = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setIsSubmitting(true);
    let resp = await post('/tags', { description: tagName });
    if (resp.statusCode === 200) {
      let resp2 = await get('/tags');
      if (resp2.statusCode === 200) {
        setTagsItems([...resp2.data]);
        setTagName('');
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }
    setIsSubmitting(false);
  };

  const addCustomerTagsFunction = async (values: any) => {
    setIsSubmitting(true);
    let tagsRequest = {
      customerIds: selectedRowKeys,
      tagIds: values?.tagIds,
    };

    if (typeSelected === 'Agregar') {
      post('/customers/tags', tagsRequest, true).then((e) => {
        if (e.statusCode === 200) {
          setModalIsVisible(false);
          handleRefresh();
        }
      });
    } else {
      delWithBody('/customers/tags', tagsRequest, true).then((e) => {
        if (e.statusCode === 200) {
          setModalIsVisible(false);
          handleRefresh();
        }
      });
    }
  };

  return (
    <Spin spinning={isLoadingTags} tip={<p>Cargando...</p>}>
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addCustomerTagsFunction}
      >
        <Divider />
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col xs={20}>
            <Row gutter={16}>
              <Col xs={20} md={12}>
                <Form.Item
                  label="Tipo de etiquetado:"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione tipo.',
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecciona una opción"
                    allowClear
                    onChange={(e) => setTypeSelected(e)}
                    showSearch
                    options={[
                      { label: 'Agregar', value: 'Agregar' },
                      { label: 'Eliminar', value: 'Eliminar' },
                    ]}
                    optionFilterProp="children"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Etiqueta:"
                  name="tagIds"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione etiqueta',
                    },
                  ]}
                >
                  <Select
                    placeholder="Seleccione etiqueta"
                    mode="multiple"
                    showSearch={false}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                          <Input
                            placeholder="Nueva etiqueta"
                            ref={inputRef}
                            value={tagName}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={addItem}
                            disabled={isSubmitting}
                          >
                            Agregar
                          </Button>
                        </Space>
                      </>
                    )}
                  >
                    {tagsItems?.map((e: any) => (
                      <Select.Option key={e.id} value={e.id}>
                        {e.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="btn-add"
                  disabled={isLoadingTags || isSubmitting}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default CustomerTagsForm;
