import React, { useRef, useState } from 'react';
import { Editor, EditorState, RichUtils, DraftHandleValue } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Tooltip, Form } from 'antd';
import {
  UnorderedListOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  OrderedListOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

interface TextEditorProps {
  editorState: any;
  setEditorState: any;
}

const TextEditor: React.FC<TextEditorProps> = (props) => {
  const { editorState, setEditorState } = props;

  const editorRef = useRef<Editor>(null);

  const handleEditorChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    // const contentState = newEditorState.getCurrentContent();
    // const rawText = contentState.getPlainText();
    // setText(rawText);
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  function onTextOptionClicked(e: any, s: string) {
    e.preventDefault(); // Prevent form submission

    const newState = RichUtils.toggleInlineStyle(editorState, s);
    setEditorState(newState);
  }
  function onBlockTypeOptionClicked(e: any, s: string) {
    e.preventDefault(); // Prevent form submission

    const newState = RichUtils.toggleBlockType(editorState, s);
    setEditorState(newState);
  }

  const isStyleActive = (style: string): boolean => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return currentStyle.has(style);
  };

  return (
    <div>
      <Tooltip title="Negrita">
        <Button
          onClick={(e) => onTextOptionClicked(e, 'BOLD')}
          icon={<BoldOutlined />}
          style={{
            fontWeight: 'bold',
            marginRight: 5,
            color: isStyleActive('BOLD') ? 'black' : '#828385',
          }}
        />
      </Tooltip>
      <Tooltip title="Cursiva">
        <Button
          style={{
            fontWeight: 'bold',
            marginRight: 5,
            color: isStyleActive('ITALIC') ? 'black' : '#828385',
          }}
          icon={<ItalicOutlined />}
          onClick={(e) => onTextOptionClicked(e, 'ITALIC')}
        />
      </Tooltip>

      <Tooltip title="Subrayar">
        <Button
          style={{
            fontWeight: 'bold',
            marginRight: 5,
            color: isStyleActive('UNDERLINE') ? 'black' : '#828385',
          }}
          icon={<UnderlineOutlined />}
          onClick={(e) => onTextOptionClicked(e, 'UNDERLINE')}
        />
      </Tooltip>
      {/* Block Types */}
      <Tooltip title="Sangría">
        <Button
          style={{
            fontWeight: 'bold',
            color: '#828385',
            marginRight: 5,
          }}
          icon={<MenuUnfoldOutlined />}
          onClick={(e) => onBlockTypeOptionClicked(e, 'blockquote')}
        />
      </Tooltip>
      <Tooltip title="Lista numerada">
        <Button
          style={{
            fontWeight: 'bold',
            color: '#828385',
            marginRight: 5,
          }}
          icon={<OrderedListOutlined />}
          onClick={(e) => onBlockTypeOptionClicked(e, 'ordered-list-item')}
        />
      </Tooltip>
      <Tooltip title="Lista con viñetas">
        <Button
          style={{
            fontWeight: 'bold',
            color: '#828385',
            marginRight: 5,
          }}
          icon={<UnorderedListOutlined />}
          onClick={(e) => onBlockTypeOptionClicked(e, 'unordered-list-item')}
        />
      </Tooltip>

      <div
        style={{
          borderWidth: '1px',
          borderStyle: 'groove',
          borderRadius: '7px',
          borderColor: '#f1f1f1',
          marginTop: '20px',
          padding: '0px 20px 0px 20px',
          maxHeight: 400,
          overflowY: 'scroll',
          height: 200,
        }}
      >
        <Form.Item name="serviceDetail">
          <Editor
            editorState={editorState}
            onChange={handleEditorChange}
            handleKeyCommand={handleKeyCommand}
            ref={editorRef}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default TextEditor;
