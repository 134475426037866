import { Col, Layout, Row, Typography } from 'antd';
import React from 'react';
import logo from '../../assets/img/png/crm-logo.png';
import logoPlanB from '../../assets/img/png/logo_planb.png';
import LoginForm from '../../components/general/login-form';

import './login.scss';

const Login = () => {
  const { Content } = Layout;

  const { Paragraph, Title } = Typography;

  return (
    <Layout className="login">
      <Content className="login__content">
        <div className="login__content-banner animate__animated  animate__slideInDown">
          <Row className="login-container">
            <Col span={24}>
              <img src={logo} alt="logo" style={{ width: 220 }} />
              <Title level={3} style={{ marginTop: 20 }}>
                Bienvenido
              </Title>
            </Col>
          </Row>
          <LoginForm />
          <Row className="login-container">
            <Col>
              <Paragraph>Desarrollado por:</Paragraph>
              <img src={logoPlanB} alt="logo_PlanB" style={{ width: 100 }} />
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default Login;
