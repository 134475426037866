// @ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  InputRef,
  Space,
  Upload,
} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useQuery } from '@tanstack/react-query';
import { get } from '../../../../api';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { queryClient } from '../../../../config';

const MarketingCampaignForm = (props) => {
  const { add } = props;
  const [form] = Form.useForm();
  const [tagsItems, setTagsItems] = useState([]);
  const [emailContent, setEmailContent] = useState('');
  const inputRef = useRef<InputRef>(null);
  const [tagName, setTagName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [attachments, setAttachments] = useState<UploadFile[]>([]);

  const { isLoading, data: tags } = useQuery<any>(['tags'], () =>
    get<any>('/tags').then((e) => e.data)
  );

  useEffect(() => {
    if (tags) {
      setTagsItems(tags);
    }
  }, [tags]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };

  const addItem = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setIsSubmitting(true);
    let resp = await post('/tags', { description: tagName });
    if (resp.statusCode === 200) {
      let resp2 = await get('/tags');
      if (resp2.statusCode === 200) {
        setTagsItems([...resp2.data]);
        setTagName('');
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }
    setIsSubmitting(false);
  };
  const handleAttachmentsChange = ({ fileList }) => {
    setAttachments(fileList);
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ align: [] }],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'align',
  ];

  const addEmailFunction = async (values) => {
    setIsSubmitting(true);
    let processedEmailContent = emailContent;
    let hiddenAttachments = [];

    const imageTags = processedEmailContent.match(
      /<img[^>]+src="data:image\/[^"]+"[^>]*>/g
    );

    if (imageTags) {
      imageTags.forEach((imgTag, index) => {
        const base64Match = imgTag.match(/src="(data:image\/[^"]+)"/);
        if (base64Match && base64Match[1]) {
          const base64Data = base64Match[1];

          const fileName = `image${index + 1}.png`;

          const mime = base64Data.split(',')[0].split(':')[1].split(';')[0];
          const byteString = atob(base64Data.split(',')[1]);
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const intArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([intArray], { type: mime });
          const file = new File([blob], fileName, { type: mime });

          hiddenAttachments.push(file);

          const newImgTag = imgTag.replace(
            base64Match[0],
            `src="cid:${fileName}"`
          );
          processedEmailContent = processedEmailContent.replace(
            imgTag,
            newImgTag
          );
        }
      });
    }

    let formData = new FormData();

    values.tagIds.forEach((id) => {
      formData.append('TagIds', id.toString());
    });

    formData.append('Body', processedEmailContent);
    formData.append('Subject', values.subject);
    formData.append('FromName', values.fromName);

    attachments.forEach((file) => {
      formData.append('Attachments', file.originFileObj);
    });

    hiddenAttachments.forEach((file) => {
      formData.append('Attachments', file);
    });

    add(formData);
  };

  return (
    <Form form={form} layout="vertical" onFinish={addEmailFunction}>
      <Divider />
      <Row gutter={16} style={{ display: 'flex', justifyContent: 'center' }}>
        <Col xs={24} md={12}>
          <Form.Item
            label="Asunto:"
            name="subject"
            rules={[{ required: true, message: 'Por favor ingrese el asunto' }]}
          >
            <Input placeholder="Ingresa el asunto" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="Etiqueta:"
            name="tagIds"
            rules={[
              {
                required: true,
                message: 'Por favor seleccione etiqueta',
              },
            ]}
          >
            <Select
              placeholder="Seleccione etiqueta"
              mode="multiple"
              showSearch={false}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Input
                      placeholder="Nueva etiqueta"
                      ref={inputRef}
                      value={tagName}
                      onChange={onNameChange}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addItem}
                      disabled={isSubmitting}
                    >
                      Agregar
                    </Button>
                  </Space>
                </>
              )}
            >
              {tagsItems?.map((e: any) => (
                <Select.Option key={e.id} value={e.id}>
                  {e.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item label="Nombre del emisor:" name="fromName">
            <Input placeholder="planB Consultoría" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label="Archivos adjuntos:" name="attachments">
            <Upload
              multiple
              listType="text"
              beforeUpload={() => false}
              onChange={handleAttachmentsChange}
            >
              <Button icon={<UploadOutlined />}>Agregar archivos</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Contenido del Correo:"
            name="content"
            rules={[
              { required: true, message: 'Por favor ingrese el contenido' },
            ]}
          >
            <ReactQuill
              value={emailContent}
              onChange={setEmailContent}
              modules={modules}
              formats={formats}
              placeholder="Escribe el contenido del correo..."
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              Enviar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default MarketingCampaignForm;
