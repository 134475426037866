// @ts-nocheck
import {
  Button,
  Col,
  Form,
  Row,
  Typography,
  Tooltip,
  FloatButton,
  Modal as ModalAntd,
  Input,
  Divider,
  Collapse,
  Select,
  Spin,
  InputRef,
  Space,
} from 'antd';
import {
  PlusCircleOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Customer, get, post, put } from '../../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined, SaveOutlined } from '@ant-design/icons';
// import QuotePropsForm from '../quote-props';
import ContactForm from '../../../administration/contacts/detail';
import Modal from '../../../../components/general/modal';
import CallLogList from '../../../home/call-log/list';
import CustomerQuoteList from '../../../home/opportunity-quotes/customer-list';
import { useUser } from '../../../../hooks';
import TaskForm from '../../tasks/detail';
import TasksListDetail from '../../tasks/list-detail';
import CallLogForm from '../../../home/call-log/detail';
import CallLogListDetail from '../../../home/call-log/list-detal';

type PageDetailFormProps = {
  type: string;
  id?: number | null;
  // add: (v: CreateOpportunity) => void;
  //   update: (v: Opportunity) => void;
};

const CustomerHeaderForm: React.FC<PageDetailFormProps> = (props) => {
  const { type } = props;
  const [form] = Form.useForm();
  const { Title, Text } = Typography;
  const { user } = useUser();
  let { id } = useParams();
  const navigate = useNavigate();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [contextModal, setContextModal] = useState<ReactElement | null>(null);
  const [users, setUsers] = useState([]);
  const [customerTypeSelected, setCustomerTypeSelected] = useState(0);
  const [tagName, setTagName] = useState('');
  const [tagsItems, setTagsItems] = useState([]);
  const inputRef = useRef<InputRef>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //ENDPOINTS
  const { isLoading: isLoadingQuote, data } = useQuery<Customer>(
    ['/customers/' + id],
    () => get<Customer>('/customers/' + id).then((e) => e.data)
  );
  const { isLoading: isloadingtags, data: tags } = useQuery<any>(['tags'], () =>
    get<any>('/tags').then((e) => e.data)
  );
  const { isLoading: isloadingScores, data: customerScores } = useQuery<any>(
    ['customerScores'],
    () => get<any>('/customerScores').then((e) => e.data)
  );
  const { isLoading: isLoadingCustomerContacts, data: contacts } = useQuery<
    Contact[]
  >([`/customers/${id}/contacts`], () =>
    id ? get<Contact[]>(`/customers/${id}/contacts`).then((e) => e.data) : null
  );
  const { isLoading: isloadingtypes, data: customertypes } = useQuery<any>(
    ['types'],
    () => get<any>('/customertypes').then((e) => e.data)
  );
  const { isLoading: isLoadingUsers, data: userssget } = useQuery<User[]>(
    ['usuarios'],
    () => get<User[]>('/users').then((e) => e.data)
  );

  useEffect(() => {
    if (tags) {
      setTagsItems(tags);
    }
  }, [tags]);

  useEffect(() => {
    if (userssget) {
      const usersOrdenados = [...userssget];
      usersOrdenados.sort((a, b) => a.firstName.localeCompare(b.firstName));
      setUsers(usersOrdenados);
    }
  }, [userssget]);

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        id: data?.id,
        address: data?.address,
        city: data?.city,
        email: data?.email,
        name: data?.name,
        phone: data?.phone,
        phoneExtension: data?.phoneExtension,
        customerScoreId: data?.customerScoreId,
        rfc: data?.rfc,
        typeId: data?.typeId,
        state: data?.state,
        postalCode: data?.postalCode,
        website: data?.website,
        country: data?.country,
        territory: data?.territory,
        region: data?.region,
        agentId: data?.agentId,
        sector: data?.sector,
        tagIds: data?.tagIds,
      });
      setCustomerTypeSelected(data?.typeId);
    }
  }, [form, data, id, type]);

  const showModal = (type: string, id: number | null) => {
    setModalIsVisible(true);
    setTitleModal('Agregar');
    if (type === 'contacto') {
      setContextModal(() => (
        <ContactForm
          type={'add'}
          id={null}
          idCustomer={parseInt(id)}
          setModalIsVisible={setModalIsVisible}
        />
      ));
    } else if (type === 'tarea') {
      setContextModal(() => (
        <TaskForm
          type={'add'}
          id={null}
          setModalIsVisible={setModalIsVisible}
        />
      ));
    } else if (type === 'seguimiento') {
      setContextModal(() => (
        <CallLogForm
          type={'add'}
          id={null}
          setModalIsVisible={setModalIsVisible}
        />
      ));
    }
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };
  const addItem = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setIsSubmitting(true);
    let resp = await post('/tags', { description: tagName });
    if (resp.statusCode === 200) {
      let resp2 = await get('/tags');
      if (resp2.statusCode === 200) {
        setTagsItems([...resp2.data]);
        setTagName('');
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }
    setIsSubmitting(false);
  };

  const updateCustomerFunction = async (values: any) => {
    let requestBody: Customer = {
      name: values.name,
      rfc: values.rfc,
      phone: values.phone,
      phoneExtension: values.phoneExtension,
      customerScoreId: values.customerScoreId,
      address: values.address,
      region: values.region,
      city: values.city,
      state: values.state,
      country: values.country,
      postalCode: values.postalCode,
      territory: values.territory,
      sector: values.sector ? values.sector : '',
      email: values.email,
      website: values.website,
      typeId: values.typeId,
      agentId: values.agentId,
      tagIds: values?.tagIds,
      id: id,
    };

    put(`/customers/${id}`, requestBody, true);
  };

  return (
    <>
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={updateCustomerFunction}
      >
        <div className="catalog__container">
          <Row className="header-top">
            <Col xs={19} className="header-top__left">
              <Title
                level={3}
                style={{ top: 0, bottom: 0, height: '78%', margin: 'auto' }}
              >
                Detalle de cliente: {data?.name}
              </Title>
            </Col>
            <Col xs={5}>
              <Row style={{ marginBottom: 30, justifyContent: 'right' }}>
                <Tooltip title="Atrás">
                  <Button
                    style={{
                      color: 'gray',
                      fontSize: 20,
                      height: 0,
                      width: 0,
                    }}
                    type="link"
                    onClick={() => navigate('/customers')}
                  >
                    <LeftOutlined />
                  </Button>
                </Tooltip>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className={'catalog__container'}
          style={{ borderRadius: '0px 20px 20px 20px' }}
        >
          <Spin spinning={isLoadingQuote} tip={<p>Cargando...</p>}>
            <Row>
              <Divider style={{ margin: '0px 0px 20px 0px' }} />
              <Col
                xs={24}
                md={8}
                style={{
                  borderStyle: 'inset',
                  borderWidth: '0px 10px 0px 0px',
                  marginRight: 10,
                }}
              >
                <Row
                  style={{
                    backgroundColor: '#f2f2f2',
                    borderRadius: 9,
                    margin: 5,
                    justifyContent: 'space-between',
                    lineHeight: 2,
                  }}
                >
                  <Col style={{ alignContent: 'center' }}>
                    <Text strong style={{ margin: 5 }}>
                      CONTACTOS
                    </Text>
                  </Col>
                  <Col>
                    <Button
                      className="btn-add"
                      style={{ width: 35 }}
                      icon={<PlusCircleOutlined />}
                      // size="small"
                      shape="circle"
                      onClick={() => showModal('contacto', id)}
                    />
                  </Col>
                </Row>
                <div style={{ overflowY: 'scroll', height: 250 }}>
                  {contacts?.map((e, index) => {
                    return (
                      <>
                        <Col style={{ marginLeft: 10 }}>
                          <Row>
                            {e.mainContact && (
                              <Text
                                strong
                                style={{
                                  color: 'red',
                                  marginRight: 3,
                                  fontSize: 15,
                                  textTransform: 'capitalize',
                                }}
                              >
                                *
                              </Text>
                            )}
                            <Text
                              strong
                              style={{
                                color: '#0D2F41',
                                marginRight: 3,
                                fontSize: 15,
                                textTransform: 'capitalize',
                              }}
                            >
                              {e.name} {e.lastName}
                            </Text>
                          </Row>
                          <Row>
                            <Text strong style={{ marginRight: 3 }}>
                              Correo:{' '}
                            </Text>
                            <Text style={{ color: 'blue' }}> {e.email}</Text>
                          </Row>
                          <Row>
                            <Text strong style={{ marginRight: 3 }}>
                              Teléfono:
                            </Text>
                            <Text>{e.phone}</Text>
                          </Row>
                          <Row>
                            <Text strong style={{ marginRight: 3 }}>
                              Cargo:{' '}
                            </Text>
                            <Text> {e.department}</Text>
                          </Row>
                          <Divider style={{ margin: '10px 0px' }} />
                        </Col>
                      </>
                    );
                  })}
                </div>

                <Row
                  style={{
                    backgroundColor: '#f2f2f2',
                    borderRadius: 9,
                    margin: 5,
                    justifyContent: 'space-between',
                  }}
                >
                  <Col style={{ alignContent: 'center' }}>
                    <Text strong style={{ margin: 5 }}>
                      ÚLTIMOS SEGUIMIENTOS
                    </Text>
                  </Col>
                  <Col>
                    <Button
                      className="btn-add"
                      style={{ width: 35 }}
                      icon={<PlusCircleOutlined />}
                      // size="small"
                      shape="circle"
                      onClick={() => showModal('seguimiento', null)}
                    />
                  </Col>
                </Row>
                {/* <CallLogList /> */}
                <Col style={{ marginTop: 20, marginBottom: 20 }}>
                  <CallLogListDetail type={'customer'} id={id} />
                </Col>
                <Row
                  style={{
                    backgroundColor: '#f2f2f2',
                    borderRadius: 9,
                    margin: 5,
                    justifyContent: 'space-between',
                  }}
                >
                  <Col style={{ alignContent: 'center' }}>
                    <Text strong style={{ margin: 5 }}>
                      ÚLTIMAS TAREAS
                    </Text>
                  </Col>
                  <Col>
                    <Button
                      className="btn-add"
                      style={{ width: 35 }}
                      icon={<PlusCircleOutlined />}
                      onClick={() => showModal('tarea', null)}
                    />
                  </Col>
                </Row>
                {/* <TaskList /> */}
                <Col style={{ marginTop: 20, marginBottom: 20 }}>
                  <TasksListDetail type={'customer'} id={id} />
                </Col>
              </Col>
              <Col
                xs={24}
                md={15}
                // style={{ maxHeight: 450, overflowY: 'scroll', height: 450 }}
              >
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item label="ID Cliente:" name="id">
                      <Input disabled placeholder="Ingresa información" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="Territorio:" name="territory">
                      <Input
                        placeholder="Ingresa tu información"
                        disabled={type === 'watch'}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item label="Nombre de cliente:" name="name">
                      <Input
                        placeholder="Ingresa tu información"
                        disabled={type !== 'add'}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="Sector:" name="sector">
                      <Input
                        placeholder="Ingresa tu información"
                        // disabled={type !== 'add'}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Tipo:"
                      name="typeId"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese tipo',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Selecciona una opción"
                        disabled={type === 'watch'}
                        onChange={(e) => setCustomerTypeSelected(e)}
                      >
                        {customertypes?.map((e: any) => (
                          <Select.Option
                            key={e.id}
                            value={e.id}
                            disabled={
                              user.role === 'SALES' &&
                              e.description === 'Cliente'
                            }
                          >
                            {e.description}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="Agente:" name="agentId">
                      <Select
                        disabled={type === 'watch'}
                        style={{ width: '100%' }}
                        placeholder="Selecciona una opción"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                      >
                        {users?.map((s: any) => (
                          <Select.Option key={s.id} value={s.id}>
                            {s.firstName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="Etiqueta:" name="tagIds">
                      <Select
                        placeholder="Seleccione etiqueta"
                        mode="multiple"
                        showSearch={false}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '0 8px 4px' }}>
                              <Input
                                placeholder="Nueva etiqueta"
                                ref={inputRef}
                                value={tagName}
                                onChange={onNameChange}
                                onKeyDown={(e) => e.stopPropagation()}
                              />
                              <Button
                                type="text"
                                icon={<PlusOutlined />}
                                onClick={addItem}
                                disabled={isSubmitting}
                              >
                                Agregar
                              </Button>
                            </Space>
                          </>
                        )}
                      >
                        {tagsItems?.map((e: any) => (
                          <Select.Option
                            key={e.id}
                            value={e.id}
                            disabled={
                              user.role === 'SALES' &&
                              e.description === 'Cliente'
                            }
                          >
                            {e.description}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="Calificación:" name="customerScoreId">
                      <Select
                        placeholder="Selecciona una opción"
                        disabled={type === 'watch'}
                      >
                        {customerScores?.map((e: any) => (
                          <Select.Option
                            key={e.id}
                            value={e.id}
                            disabled={
                              user.role === 'SALES' &&
                              e.description === 'Cliente'
                            }
                          >
                            {e.description}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Collapse
                  style={{ marginBottom: 20 }}
                  items={[
                    {
                      key: '1',
                      forceRender: true,
                      label: 'Información de dirección de cliente',
                      children: (
                        <Row gutter={16}>
                          <Col xs={20} md={12}>
                            <Form.Item
                              label="Dirección:"
                              name="address"
                              rules={[
                                {
                                  required: customerTypeSelected !== 1,
                                  message: 'Por favor ingrese Dirección',
                                },
                              ]}
                            >
                              <Input placeholder="Ingresa información" />
                            </Form.Item>
                          </Col>
                          <Col xs={20} md={12}>
                            <Form.Item
                              label="Estado/provincia:"
                              name="state"
                              rules={[
                                {
                                  required: customerTypeSelected !== 1,
                                  message: 'Por favor ingrese Estado',
                                },
                              ]}
                            >
                              <Input placeholder="Ingresa información" />
                            </Form.Item>
                          </Col>
                          <Col xs={20} md={12}>
                            <Form.Item
                              label="Colonia:"
                              name="region"
                              // rules={[
                              //   {
                              //     required: customerTypeSelected !== 1,
                              //     message: 'Por favor ingrese Colonia',
                              //   },
                              // ]}
                            >
                              <Input placeholder="Ingresa información" />
                            </Form.Item>
                          </Col>
                          <Col xs={20} md={12}>
                            <Form.Item
                              label="País:"
                              name="country"
                              rules={[
                                {
                                  required: customerTypeSelected !== 1,
                                  message: 'Por favor ingrese País',
                                },
                              ]}
                            >
                              <Input placeholder="Ingresa información" />
                            </Form.Item>
                          </Col>
                          <Col xs={20} md={12}>
                            <Form.Item
                              label="Ciudad:"
                              name="city"
                              rules={[
                                {
                                  required: customerTypeSelected !== 1,
                                  message: 'Por favor ingrese Ciudad',
                                },
                              ]}
                            >
                              <Input placeholder="Ingresa información" />
                            </Form.Item>
                          </Col>
                          <Col xs={20} md={12}>
                            <Form.Item
                              label="Código postal:"
                              name="postalCode"
                              rules={[
                                {
                                  required: customerTypeSelected !== 1,
                                  message: 'Por favor ingrese Código postal',
                                },
                              ]}
                            >
                              <Input placeholder="Ingresa información" />
                            </Form.Item>
                          </Col>
                        </Row>
                      ),
                    },
                  ]}
                />
                <Collapse
                  style={{ marginBottom: 20 }}
                  items={[
                    {
                      key: '2',
                      label: 'Información adicional',
                      forceRender: true,
                      children: (
                        <Row gutter={16}>
                          <Col xs={12} md={8}>
                            <Form.Item
                              label={
                                <span>
                                  Teléfono:&nbsp;
                                  <Tooltip title="El número telefónico debe contener 10 dígitos">
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </span>
                              }
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Por favor ingresa número telefónico',
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      !value ||
                                      value.toString().length === 10
                                    ) {
                                      return Promise.resolve();
                                    }

                                    return Promise.reject(
                                      'Por favor ingresa número telefónico a 10 dígitos'
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Input
                                placeholder="Ingresa tu información"
                                disabled={type === 'watch'}
                                // maxLength={10}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Item label="Extensión:" name="phoneExtension">
                              <Input
                                placeholder="Ingresa tu información"
                                disabled={type === 'watch'}
                                // maxLength={10}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item label="Correo electrónico:" name="email">
                              <Input
                                type={'email'}
                                placeholder="Ingresa tu información"
                                disabled={type !== 'add'}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item label="Sitio web:" name="website">
                              <Input
                                placeholder="Ingresa tu información"
                                disabled={type === 'watch'}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ),
                    },
                  ]}
                />
                <Collapse
                  style={{ marginBottom: 20 }}
                  defaultActiveKey={3}
                  items={[
                    {
                      key: '3',
                      label: 'Historial de oportunidades',
                      children: <CustomerQuoteList type={'client'} id={id} />,
                    },
                  ]}
                />
              </Col>
            </Row>
          </Spin>
        </div>
        <FloatButton.Group shape="circle" style={{ right: 24 }}>
          <Tooltip title="Guardar cambios">
            <FloatButton
              htmlType="submit"
              disabled={type === 'watch' || isLoadingQuote}
              style={{ marginRight: 30, backgroundColor: '#7b96ac' }}
              icon={<SaveOutlined />}
              type="primary"
              onClick={() => form.submit()}
            />
          </Tooltip>

          {/* <Tooltip title="Agregar tarea">
            <FloatButton
              icon={<UnorderedListOutlined />}
              onClick={() => showModal('tarea', id)}
            />
          </Tooltip> */}
          <FloatButton.BackTop visibilityHeight={200} />
        </FloatButton.Group>
      </Form>
      <Modal
        title={titleModal}
        isVisible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
};

export default CustomerHeaderForm;
