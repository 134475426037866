import React, { useState, createContext, useEffect } from 'react';
import { User, get } from '../api';
import { useAuth } from '../hooks';

type UserContextProps = {
  user: User | null;
  isLoading: boolean;
  refresh: () => Promise<void>;
};
const defaultProps: UserContextProps = {
  user: null,
  isLoading: true,
  refresh: async () => {},
};

export const UserContext = createContext(defaultProps);

type UserProviderProps = {
  children: React.ReactNode;
};

export default function UserProvider(props: UserProviderProps) {
  const { children } = props;
  const { jwt, isLoading: isAuthLoading } = useAuth();
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const navigate = useNavigate();

  useEffect(() => {
    const loadUser = async (userId: number) => {
      try {
        setIsLoading(true);
        let userResponse = await get<User>('/users/' + userId);
        if (userResponse.statusCode === 200) {
          userResponse.data.role = userResponse.data.roles[0];
          setUser(userResponse.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (!isAuthLoading && jwt !== null) {
      loadUser(jwt.id);
    } else if (!isAuthLoading && jwt === null) {
      setUser(null);
      setIsLoading(false);
    }
  }, [jwt, isAuthLoading]);

  const refresh = async () => {
    try {
      if (!user) return;
      setIsLoading(true);
      let userResponse = await get<User>('/users/' + user?.id);
      if (userResponse.statusCode === 200) {
        userResponse.data.role = userResponse.data.roles[0];
        setUser(userResponse.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        isLoading,
        refresh,
        // setUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
